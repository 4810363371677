import {
    Directive, ElementRef, HostListener, Input, NgZone, OnChanges, OnDestroy, OnInit, SimpleChanges,
    ViewChild,
} from '@angular/core';
import { ClusterIconStyle, GoogleMap } from '@angular/google-maps';
import { ActivatedRoute, Router } from '@angular/router';
import { EditHomeService } from '@app/core/services/edit-home/edit-home.service';
import { FilterChangeSource, FiltersService } from '@app/core/services/filters/filters.service';
import { Logger } from '@app/core/services/logger/logger';
import { UserLocationService } from '@app/core/services/user-location/user-location.service';
import { Filters, PartialFilters } from '@app/shared/models/filters.model';
import { Focus } from '@app/shared/models/geo-center.model';
import { GeoPoint } from '@app/shared/models/geo-point.model';
import { calculateBoundsZ } from '@app/shared/utils/geo/calculateBoundsZ';
import { calculateLongitudeOffset } from '@app/shared/utils/geo/calculateLongitudeOffset';
import { calculateRadiusZ } from '@app/shared/utils/geo/calculateRadiusZ';
import { calculateZoomLevelR } from '@app/shared/utils/geo/calculateZoomLevelR';
import { MAX_ZOOM_LEVEL } from '@app/shared/utils/geo/constants';
import { sameLatLng } from '@app/shared/utils/geo/sameLatLng';
import { pauseWhile } from '@app/shared/utils/rxjs/pauseWhile';
import { Platform, ViewDidEnter, ViewWillLeave } from '@ionic/angular';
import {
    BehaviorSubject, combineLatest, from, merge, MonoTypeOperatorFunction, Observable,
    ReplaySubject, Subject,
} from 'rxjs';
import {
    debounceTime, distinctUntilChanged, filter, map, partition, scan, shareReplay, takeUntil, tap,
} from 'rxjs/operators';

export const HOME_ZOOM_LEVEL_MOBILE = 11;

export const HOME_ZOOM_LEVEL_DESKTOP = 15;

export const DETAIL_ZOOM_LEVEL = 17;

export const HOME_MARKER_OPTIONS: google.maps.MarkerOptions = {
    icon: {
        url: 'assets/maps/home.svg',
        labelOrigin: new google.maps.Point(60, 13),
        scaledSize: new google.maps.Size(24, 24),
    },
    clickable: false,
    draggable: false,
    label: {
        text: 'Zuhause',
        color: '#666666',
        fontSize: '16px',
        fontWeight: '500',
        fontFamily: 'Roboto, Helvetica Neue, sans-serif',
    },
};

export const DRAGGABLE_HOME_MARKER_OPTIONS: google.maps.MarkerOptions = {
    ...HOME_MARKER_OPTIONS,
    draggable: true,
    clickable: true,
};

export const MAP_OPTIONS: google.maps.MapOptions  = {
    maxZoom: MAX_ZOOM_LEVEL,
    clickableIcons: false,
    styles: [{ featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'off' }] }],
};

export enum MapChangeSource {
    NAVIGATION,
    MAP_INTERACTION,
    FILTER_FOCUS_CHANGE,
    FILTER_RADIUS_CHANGE
}

export enum RadiusChangeSource {
    MAP_UPDATE,
    FILTER_CHANGE
}

export interface Change<E> {
    source: E;
}

export interface PartialMapChange extends Change<MapChangeSource> {
    center?: google.maps.LatLngLiteral;
    focus?: Focus;
    zoom?: number;
    source: MapChangeSource;
}

export interface MapChange extends PartialMapChange {
    center: google.maps.LatLngLiteral;
    focus: Focus;
    zoom: number;
    source: MapChangeSource;
}

export interface RadiusChange extends Change<RadiusChangeSource> {
    radius: number;
    source: RadiusChangeSource;
}

function onlyFromSource<C extends Change<E>, E>(...sources: E[]): MonoTypeOperatorFunction<C> {
    return filter((change: C) => -1 !== sources.indexOf(change.source));
}

function exceptFromSource<C extends Change<E>, E>(...sources: E[]): MonoTypeOperatorFunction<C> {
    return filter((change: C) => -1 === sources.indexOf(change.source));
}

function debounceChangesOfSource<T extends Change<E>, E>(changeSource: E) {
    return (observable: Observable<T>) => {
        const isMapInteraction = (change: T) => changeSource === change.source;
        const [mapInteractions, rest] = partition(isMapInteraction)(observable);

        return merge(
            // We get potentially a lot of map move events
            mapInteractions.pipe(debounceTime(500)),
            // Navigation changes etc. don't need to be debounced
            rest,
        );
    };
}

function mergeMapChangesWithPreviousChanges() {
    return (observable: Observable<PartialMapChange>) => new Observable<MapChange>((subscriber) => {
        let previousChange: PartialMapChange;
        let emitted = false;

        const subscription = observable.subscribe(
            (nextChange) => {

                if (emitted) {
                    nextChange = { ...previousChange, ...nextChange };
                }

                // Reset the focus during map interactions if the center/zoom
                // changes. If not, we could be dealing with a ping back to a
                // NAVIGATION event (which triggers a subsequent MAP_INTERACTION
                // with the same center and zoom)
                if (MapChangeSource.MAP_INTERACTION === nextChange.source
                    && nextChange.center
                    && (
                        !emitted
                        || !sameLatLng(previousChange.center, nextChange.center)
                        || previousChange.zoom !== nextChange.zoom
                    )) {
                    nextChange.focus = null;
                }

                previousChange = nextChange;
                emitted = true;

                subscriber.next(nextChange as MapChange);
            },
            (error) => subscriber.error(error),
            () => subscriber.complete(),
        );

        return () => subscription.unsubscribe();
    });
}

@Directive()
export abstract class AbstractMapComponent<P extends PartialFilters, F extends Filters> implements OnInit, OnChanges, OnDestroy, ViewDidEnter, ViewWillLeave {

    @Input() foreground: boolean;

    @ViewChild(GoogleMap, { static: true }) map: GoogleMap;

    @ViewChild(GoogleMap, { read: ElementRef, static: true }) mapElementRef: ElementRef;

    filters: F;

    loading = true;

    mobile = this.platform.is('mobile');

    home: google.maps.LatLngLiteral;

    editingHome = false;

    userLocation: google.maps.LatLngLiteral;

    protected readonly mapOptions = MAP_OPTIONS;

    protected get homeMarkerOptions(): google.maps.MarkerOptions {
        return this.editingHome
            ? DRAGGABLE_HOME_MARKER_OPTIONS
            : HOME_MARKER_OPTIONS;
    }

    protected readonly userLocationMarkerOptions: google.maps.MarkerOptions = {
        icon: {
            url: 'assets/maps/location.svg',
            scaledSize: new google.maps.Size(16, 16),
        },
        clickable: false,
    };

    protected readonly geoPointMarkerOptions: google.maps.MarkerOptions = {
        icon: {
            url: 'assets/maps/marker.svg',
            scaledSize: new google.maps.Size(24, 24),
        },
    };

    protected readonly selectedGeoPointMarkerOptions: google.maps.MarkerOptions = {
        icon: {
            ...this.geoPointMarkerOptions,
            url: 'assets/maps/marker-selected.svg',
        },
    };

    protected readonly markerClusterStyles: ClusterIconStyle[];

    geoPoints: GeoPoint[] = [];

    // On Desktop, the map is always in foreground. It was nice if we could
    // use the same logic as on mobile, but thanks to sitting in a nested
    // router outlet, Ionic does not trigger the lifecycle hooks
    // ionViewDidEnter()/ionViewWillLeave() on the map on Desktop
    protected foregroundSource = new BehaviorSubject<boolean>(!this.mobile);

    protected pageVisibleSource = new ReplaySubject<boolean>(1);

    protected updatesPaused = combineLatest([this.foregroundSource, this.pageVisibleSource, this.editHomeService.isEditingHome()]).pipe(
        debounceTime(20),
        map(([foreground, pageVisible, editingHome]) => !foreground || !pageVisible || editingHome),
        distinctUntilChanged(),
        shareReplay(1),
    );

    protected ngUnsubscribe = new Subject<void>();

    protected boundsChangeSource = new Subject<void>();

    protected mapChangeSource = new Subject<PartialMapChange>();

    protected mapChange = from(this.mapChangeSource)
        .pipe(
            // Reduce the amount of processing during frequent map updates
            debounceChangesOfSource(MapChangeSource.MAP_INTERACTION),
            // Combine partial changes with previous changes
            mergeMapChangesWithPreviousChanges(),
            // If only the source differs, don't propagate a change
            distinctUntilChanged((x: MapChange, y: MapChange) => sameLatLng(x.center, y.center)
                && x.focus === y.focus
                && x.zoom === y.zoom),
            tap((mapChange) => this.logger.log('MAP CHANGE', mapChange)),
            shareReplay(1),
        );

    protected radiusChangeSource = new Subject<RadiusChange>();

    protected radiusChange = from(this.radiusChangeSource)
        .pipe(
            // If only the source differs, don't propagate a change
            distinctUntilChanged((x, y) => x.radius === y.radius),
            tap((radiusChange) => this.logger.log('RADIUS CHANGE', radiusChange)),
            shareReplay(1),
        );

    private initialQueryParamsSet = false;

    protected constructor(protected editHomeService: EditHomeService,
                          protected userLocationService: UserLocationService,
                          protected filtersService: FiltersService<P, F>,
                          protected platform: Platform,
                          protected router: Router,
                          protected activatedRoute: ActivatedRoute,
                          protected logger: Logger,
                          protected zone: NgZone) {
        const primaryContrastColor = getComputedStyle(document.documentElement)
            .getPropertyValue('--ion-color-primary-contrast');

        this.markerClusterStyles = [
            {
                url: 'assets/maps/cluster.svg',
                width: 24,
                height: 24,
                textColor: primaryContrastColor,
                fontWeight: '600',
                anchorText: [6, 0],
            },
        ];
    }

    ngOnInit() {
        this.pageVisibleSource.next('visible' === document.visibilityState);

        this.trackHome();
        this.trackEditingHome();
        this.trackUserLocation();

        // Track map updates
        this.changeCenterAndZoomWhenBoundsChange();

        // Update the map
        this.moveMapWhenCenterOrZoomChanges();

        // Track route changes
        this.changeCenterAndZoomWhenQueryParamsChange();

        // Update the route
        this.navigateWhenCenterOrZoomChanges();

        // Track filter changes
        this.changeCenterAndZoomWhenFilterFocusChanges();

        // Update the filters
        this.changeRadiusWhenZoomChanges();
        this.changeFiltersWhenRadiusChanges();
        this.changeFiltersWhenCenterChanges();

        if (this.mobile) {
            // Disabled - don't update the zoom level from the radius
            // On mobile, we can update the radius in the filters
            // this.changeRadiusWhenFiltersChange();
            // this.changeZoomWhenRadiusChanges();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        // Support setting foreground to true when map is used as nested
        // component rather than a top level component of a route
        if ('foreground' in changes) {
            this.foregroundSource.next(this.foreground);
        }
    }

    ionViewDidEnter(): void {
        if (this.mobile) {
            // We only track foreground state on mobile, because ionViewDidEnter()
            // is not called on Desktop thanks to sitting in a nested router
            // outlet... thanks Ionic.
            this.foregroundSource.next(true);
        }
    }

    ionViewWillLeave(): void {
        if (this.mobile) {
            this.foregroundSource.next(false);
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onBoundsChange(): void {
        this.boundsChangeSource.next();
    }

    onDragStart(): void {
        // Make sure we don't get position updates while dragging, which would
        // reposition the map on the marker
        this.userLocationService.stopFollowingUserLocation();
    }

    onDragEnd(event: google.maps.MapMouseEvent): void {
        this.editHomeService.moveHome(event.latLng.toJSON());
    }

    @HostListener('document:visibilitychange')
    onPageVisibilityChange(): void {
        this.pageVisibleSource.next('visible' === document.visibilityState);
    }

    trackByFn(index: number, geoPoint: GeoPoint): string {
        // Prevent flimmering of the points during updates
        return geoPoint.id;
    }

    protected trackEditingHome() {
        this.editHomeService.isEditingHome()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((editingHome) => this.editingHome = editingHome);
    }

    protected trackHome() {
        this.editHomeService.getHome()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((home) => this.home = home);
    }

    protected trackUserLocation() {
        this.userLocationService.userLocation
            .pipe(
                takeUntil(this.ngUnsubscribe),
                filter((position) => !!position.coords),
            )
            .subscribe((position) => this.userLocation = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            });
    }

    protected changeCenterAndZoomWhenQueryParamsChange(): void {
        combineLatest([this.activatedRoute.queryParams, this.editHomeService.getHome()])
            .pipe(
                debounceTime(100),
                pauseWhile(this.updatesPaused),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe(([queryParams, home]) => {
                const ll = queryParams.ll ? queryParams.ll.split(',') : [];

                // Set initial coordinates
                if (!+ll[0] || !+ll[1] || !+queryParams.z) {
                    this.router.navigate([], {
                        relativeTo: this.activatedRoute,
                        queryParams: {
                            ll: home.lat + ',' + home.lng,
                            z: this.mobile
                                ? HOME_ZOOM_LEVEL_MOBILE
                                : HOME_ZOOM_LEVEL_DESKTOP,
                            f: 'home',
                        },
                        replaceUrl: true,
                    });

                    return;
                }

                this.logger.log('QUERY PARAMS', queryParams);

                this.mapChangeSource.next({
                    center: { lat: +ll[0], lng: +ll[1] },
                    focus: 'home' === queryParams.f
                        ? Focus.HOME
                        : 'location' === queryParams.f
                        ? Focus.USER_LOCATION
                        : null,
                    zoom: +queryParams.z,
                    source: MapChangeSource.NAVIGATION,
                });

                this.initialQueryParamsSet = true;
            });
    }

    protected changeRadiusWhenFiltersChange(): void {
        this.filtersService.getFilterChanges()
            .pipe(
                pauseWhile(this.updatesPaused),
                // Break the cycle
                onlyFromSource(FilterChangeSource.FILTER_CHANGE),
                map((filterChange) => filterChange.filters.mapRadius),
                distinctUntilChanged(),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe((radius) => {
                this.logger.log('FILTER RADIUS CHANGED', radius);

                this.radiusChangeSource.next({
                    radius,
                    source: RadiusChangeSource.FILTER_CHANGE,
                });
            });
    }

    protected changeRadiusWhenZoomChanges(): void {
        this.mapChange
            .pipe(
                // Break the cycle
                exceptFromSource(MapChangeSource.FILTER_RADIUS_CHANGE),
                tap((zoomChange) => this.logger.log('START ZOOM RADIUS CHANGED', zoomChange)),
                // Only update the radius when the zoom actually changes
                distinctUntilChanged((x, y) => x.zoom === y.zoom),
                pauseWhile(this.updatesPaused),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe((mapChange) => {
                const dimensions = this.mapElementRef.nativeElement.getBoundingClientRect();

                const radius = calculateRadiusZ(
                    mapChange.center,
                    mapChange.zoom,
                    dimensions.height,
                    dimensions.width,
                );

                this.logger.log('ZOOM RADIUS CHANGED', mapChange.center, mapChange.zoom, radius);

                this.radiusChangeSource.next({
                    radius,
                    source: RadiusChangeSource.MAP_UPDATE,
                });
            });
    }

    protected changeCenterAndZoomWhenBoundsChange() {
        this.boundsChangeSource
            .pipe(
                debounceTime(500),
                pauseWhile(this.updatesPaused),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe(() => {
                const dimensions = this.mapElementRef.nativeElement.getBoundingClientRect();
                const center = this.map.getCenter().toJSON();
                const zoom = this.map.getZoom();
                const longitudeOffset = this.calculateLongitudeOffset(
                    calculateBoundsZ(
                        center,
                        zoom,
                        dimensions.height,
                        dimensions.width,
                    ),
                    dimensions.width,
                );

                this.logger.log('USER MAP CHANGE', center, zoom, longitudeOffset);

                this.mapChangeSource.next({
                    center: {
                        lat: center.lat,
                        lng: center.lng + longitudeOffset,
                    },
                    zoom,
                    source: MapChangeSource.MAP_INTERACTION,
                });
            });
    }

    protected moveMapWhenCenterOrZoomChanges() {
        this.mapChange
            .pipe(
                pauseWhile(this.updatesPaused),
                // Break the cycle
                exceptFromSource(MapChangeSource.MAP_INTERACTION),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe((mapChange) => {
                const dimensions = this.mapElementRef.nativeElement.getBoundingClientRect();
                const longitudeOffset = this.calculateLongitudeOffset(
                    calculateBoundsZ(
                        mapChange.center,
                        mapChange.zoom,
                        dimensions.height,
                        dimensions.width,
                    ),
                    dimensions.width,
                );

                this.logger.log('POSITION MAP', mapChange, longitudeOffset);

                this.map.googleMap.setZoom(mapChange.zoom);
                this.map.panTo({
                    lat: mapChange.center.lat,
                    lng: mapChange.center.lng - longitudeOffset,
                });
            });
    }

    protected navigateWhenCenterOrZoomChanges() {
        this.mapChange
            .pipe(
                exceptFromSource(MapChangeSource.NAVIGATION),
                // No long debouncing here! If we debounce and move away before
                // the debounce is finished, the debounce will be resumed after
                // moving back to this page (due to pauseWhile(updatesPaused))
                // and make the page jump
                debounceTime(100),
                pauseWhile(this.updatesPaused),
                scan(
                    ([previousMapChange]: [MapChange], mapChange: MapChange) => [mapChange, previousMapChange],
                    [null, null],
                ),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe(([mapChange, previousMapChange]) => {
                const queryParams: any = {};

                queryParams.ll = mapChange.center.lat + ',' + mapChange.center.lng;
                queryParams.f = Focus.HOME === mapChange.focus
                    ? 'home'
                    : Focus.USER_LOCATION === mapChange.focus
                    ? 'location'
                    : null;
                queryParams.z = mapChange.zoom;

                this.logger.log('NAVIGATE');

                this.router.navigate([], {
                    relativeTo: this.activatedRoute,
                    queryParams,
                    queryParamsHandling: 'merge',
                    // Don't add new entries to the navigation stack when the
                    // location of the user changes while being focused
                    replaceUrl: this.mobile || (
                        null !== previousMapChange
                            && Focus.USER_LOCATION === mapChange.focus
                            && Focus.USER_LOCATION === previousMapChange.focus
                    ),
                });
            });
    }

    protected changeCenterAndZoomWhenFilterFocusChanges(): void {
        this.filtersService.getFilterChanges()
            .pipe(
                filter(() => this.initialQueryParamsSet),
                pauseWhile(this.updatesPaused),
                tap((filterChange) => this.logger.log('START FILTER FOCUS', FilterChangeSource[filterChange.source], Focus[filterChange.filters.focus])),
                onlyFromSource(FilterChangeSource.FILTER_CHANGE, FilterChangeSource.LOCATION_CHANGE),
                filter((filterChange) => null !== filterChange.filters.focus),
                map((filterChange) => [
                    {
                        lat: filterChange.filters.latitude,
                        lng: filterChange.filters.longitude,
                    },
                    filterChange.filters.focus,
                    filterChange.source,
                ]),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe(([center, focus, source]: [google.maps.LatLngLiteral, Focus, FilterChangeSource]) => {
                this.logger.log('FILTER FOCUS', center, Focus[focus]);

                const mapChange: PartialMapChange = {
                    center,
                    focus,
                    source: MapChangeSource.FILTER_FOCUS_CHANGE,
                };

                // Don't change zoom for location changes
                if (FilterChangeSource.FILTER_CHANGE === source) {
                    mapChange.zoom = !this.mobile || Focus.USER_LOCATION === focus
                        ? DETAIL_ZOOM_LEVEL
                        : this.mobile
                        ? HOME_ZOOM_LEVEL_MOBILE
                        : HOME_ZOOM_LEVEL_DESKTOP;
                }

                this.mapChangeSource.next(mapChange);
            });
    }

    protected changeZoomWhenRadiusChanges(): void {
        this.radiusChange
            .pipe(
                onlyFromSource(RadiusChangeSource.FILTER_CHANGE),
                pauseWhile(this.updatesPaused),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe((radiusChange) => {
                const dimensions = this.mapElementRef.nativeElement.getBoundingClientRect();

                this.mapChangeSource.next({
                    zoom: calculateZoomLevelR(
                        this.map.getCenter().toJSON(),
                        radiusChange.radius,
                        dimensions.height,
                        dimensions.width,
                    ),
                    source: MapChangeSource.FILTER_RADIUS_CHANGE,
                });
            });
    }

    protected changeFiltersWhenCenterChanges(): void {
        this.mapChange
            .pipe(
                pauseWhile(this.updatesPaused),
                exceptFromSource(MapChangeSource.FILTER_FOCUS_CHANGE, MapChangeSource.FILTER_RADIUS_CHANGE),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe((centerChange) => {
                this.filtersService.changeFilters({
                    filters: {
                        latitude: centerChange.center.lat,
                        longitude: centerChange.center.lng,
                        focus: centerChange.focus,
                    } as any as P,
                    source: FilterChangeSource.MAP_UPDATE,
                });
            });
    }

    protected changeFiltersWhenRadiusChanges(): void {
        this.radiusChange
            .pipe(
                pauseWhile(this.updatesPaused),
                exceptFromSource(RadiusChangeSource.FILTER_CHANGE),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe((radiusChange) => {
                const filters: PartialFilters = {
                    mapRadius: radiusChange.radius,
                };

                if (!this.mobile) {
                    filters.listRadius = radiusChange.radius;
                }

                this.filtersService.changeFilters({
                    filters: filters as P,
                    source: FilterChangeSource.MAP_UPDATE,
                });
            });
    }

    private calculateLongitudeOffset(bounds: google.maps.LatLngBoundsLiteral, mapWidth: number): number {
        return this.mobile
            // On mobile, we don't have a sidebar, hence we don't need
            // to offset the center
            ? 0
            : calculateLongitudeOffset(bounds, mapWidth);
    }
}
