import { Injectable } from '@angular/core';
import { AuthenticationService } from '@app/core/services/authentication/authentication.service';
import {
    ACTIVITY_FILTERS_STORAGE_KEY, FilterStorageService,
} from '@app/core/services/filters/filter-storage.service';
import { FiltersService } from '@app/core/services/filters/filters.service';
import { Logger } from '@app/core/services/logger/logger';
import { ProfileService } from '@app/core/services/profile/profile.service';
import { environment } from '@app/environments/environment';
import { ActivityFilters, PartialActivityFilters } from '@app/shared/models/activity-filters.model';
import { Platform } from '@ionic/angular';
import { Observable, of } from 'rxjs';

export const INITIAL_ACTIVITY_FILTERS: ActivityFilters = {
    period: null,
    inNeedOfVolunteers: false,
    region: null,
    postalCode: null,
    entityId: environment.entityId || null,
    activityTypeId: null,
    longitude: environment.defaultLongitude,
    latitude: environment.defaultLatitude,
    listRadius: environment.defaultListRadius,
    mapRadius: environment.defaultMapRadius,
    focus: null,
    onlyRegistered: false,
};

@Injectable({
    providedIn: 'root',
})
export class ActivityFiltersService extends FiltersService<PartialActivityFilters, ActivityFilters> {

    constructor(
        authenticationService: AuthenticationService,
        profileService: ProfileService,
        logger: Logger,
        protected filterStorageService: FilterStorageService,
        private platform: Platform,
    ) {
        super(
            authenticationService,
            profileService,
            logger,
            filterStorageService,
            ACTIVITY_FILTERS_STORAGE_KEY,
        );
    }

    getNumberOfActiveFilters(filters: ActivityFilters): number {
        let result = 0;

        if (null !== filters.period) {
            result++;
        }

        if (filters.inNeedOfVolunteers) {
            result++;
        }

        if (null !== filters.region) {
            result++;
        }

        if (null !== filters.postalCode) {
            result++;
        }

        // preset entityId should be excluded from result
        if (null === environment.entityId && null !== filters.entityId) {
            result++;
        }

        if (null !== filters.activityTypeId) {
            result++;
        }

        if (filters.listRadius !== environment.defaultListRadius && this.platform.is('mobile')) {
            result++;
        }

        if (filters.onlyRegistered) {
            result++;
        }

        return result;
    }

    createInitialFilters(): Observable<ActivityFilters> {
        return of(INITIAL_ACTIVITY_FILTERS);
    }
}
