import { Component, Input } from '@angular/core';
import { Platform } from '@ionic/angular';
import { menu as appMenu } from './menu';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {

    @Input() mode: 'sliding' | 'static' = 'sliding';

    readonly menu = appMenu;

    readonly infoLink = {
        title: 'Info',
        url: '/info',
        icon: 'information-circle',
    };

    constructor(private platform: Platform) {
    }

    shouldHideEntry(hideOnMobile: boolean | null): boolean {
        return true === hideOnMobile && this.platform.is('mobile');
    }

    isInternalLink(url: string): boolean {
        return url.startsWith('/');
    }
}
