import { Injectable } from '@angular/core';
import { Logger } from '@app/core/services/logger/logger';
import { Geolocation } from '@app/core/services/user-location/geolocation/geolocation';
import { Geolocation as GeolocationImpl } from '@awesome-cordova-plugins/geolocation/ngx';
import { Observable, ReplaySubject, Subscriber } from 'rxjs';
import { filter, multicast, refCount } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class CordovaGeolocation implements Geolocation {

    // Tried to use this with shareReplay(), but couldn't get the teardown
    // logic to be executed properly
    // Should be fine
    readonly userLocation =
        Observable
            .create((subscriber: Subscriber<GeolocationPosition>) => {
                this.logger.log('WATCH POSITION CORDOVA');

                const subscription = this.geolocation.watchPosition().subscribe(subscriber);

                return () => {
                    this.logger.log('CLEAR WATCH CORDOVA');

                    subscription.unsubscribe();
                };
            })
            .pipe(
                filter((position) => !!position),
                // Neither with publicReplay() nor with shareReplay() the
                // cleanup logic gets executed... huh?
                // This works.
                // see https://github.com/ReactiveX/rxjs/issues/3336
                multicast(() => new ReplaySubject(1)),
                refCount(),
            );

    constructor(private geolocation: GeolocationImpl, private logger: Logger) {
    }

    watchPosition(): Observable<GeolocationPosition> {
        return this.userLocation;
    }

}
