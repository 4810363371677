import { Component, HostBinding, Input } from '@angular/core';
import { coerceBooleanProperty } from '@app/shared/utils/view/coerceBooleanProperty';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {

    @Input() label: string;

    @Input()
    @HostBinding('class.centered')
    get centered(): boolean {
        return this._centered;
    }

    set centered(value: boolean) {
        this._centered = coerceBooleanProperty(value);
    }

    private _centered = false;

}
