import { from, Subject } from 'rxjs';
import { first } from 'rxjs/operators';

export class Refresher {

    private refreshSource = new Subject<void>();

    refreshes = from(this.refreshSource);

    private refreshCompleteSource = new Subject<void>();

    refresh(onComplete = () => {}): void {
        this.refreshCompleteSource
            .pipe(first())
            .subscribe(() => onComplete());

        // Let the observable handle the refreshes so that running requests
        // are properly canceled
        this.refreshSource.next();
    }

    markComplete(): void {
        this.refreshCompleteSource.next();
    }

}
