import { AfterViewChecked, Directive, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ViewWillEnter } from '@ionic/angular';
import { merge, Subject } from 'rxjs';
import {
    delay, distinctUntilChanged, filter, first, map, startWith, switchMap, takeUntil,
} from 'rxjs/operators';

export interface FormEvent<T> {

    data: T;

    valid: boolean;

    pending: boolean;

}

export interface FormOutput<T> {

    // "change" is a reserved internal event
    formChange: EventEmitter<FormEvent<T>>;

    // "submit" is a reserved internal event
    formSubmit: EventEmitter<FormEvent<T>>;

    onFormChange(event: FormEvent<T>): void;

    onFormSubmit(): void;

}

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractFormComponent<T> implements FormOutput<T>, OnInit, OnDestroy, AfterViewChecked, ViewWillEnter {

    @Input() data: T;

    @Input() buttonsDisabled = false;

    @Output() formChange = new EventEmitter<FormEvent<T>>();

    @Output() formSubmit = new EventEmitter<FormEvent<T>>();

    @ViewChild(NgForm) form: NgForm;

    previousForm: NgForm;

    protected ngUnsubscribe = new Subject<void>();

    private formSource = new Subject<NgForm>();

    ngOnInit(): void {
        // Warning: this is not working see https://app.asana.com/0/1202105060478151/1202209598883977
        this.formSource
            .pipe(
                takeUntil(this.ngUnsubscribe),
                switchMap((form) => merge(form.valueChanges, form.statusChanges)),
                // Avoid ExpressionChangedAfterItHasBeenChecked
                // Additionally, ensure this.data has been updated already
                delay(0),
                map(() => ({
                    data: this.data,
                    valid: this.form.valid,
                    pending: this.form.pending,
                })),
                // Initial change event
                startWith({
                    data: this.data,
                    valid: this.form.valid,
                    pending: this.form.pending,
                }),
                map((props) => ({ ...props, hash: JSON.stringify(props) })),
                distinctUntilChanged((a, b) => a.hash === b.hash),
                // Remove hash
                map(({ data, valid, pending }) => ({ data, valid, pending })),
            )
            .subscribe((event) => this.onFormChange(event));

    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngAfterViewChecked(): void {
        if (this.form === this.previousForm || !this.form) {
            return;
        }

        this.previousForm = this.form;

        this.formSource.next(this.form);
    }

    ionViewWillEnter(): void {
        this.buttonsDisabled = false;
    }

    onFormChange(event: FormEvent<T>): void {
        this.formChange.emit(event);
    }

    onFormSubmit(): void {
        for (const control of Object.values(this.form.controls)) {
            let value = control.value;
            if ('string' === typeof value) {
                value = control.value.trim();
            }

            // Trick to trigger Ionic's update of the ion- classes
            // Required even if we think that the control is touched, because
            // Ionic not always thinks so
            control.setValue(value);

            if (!control.touched) {
                control.markAsTouched();
                control.updateValueAndValidity();
            }
        }

        this.formSubmit.emit({
            data: this.data,
            valid: this.form.valid,
            pending: this.form.pending,
        });

        this.buttonsDisabled = this.form.valid;

        if (this.form.pending) {
            this.buttonsDisabled = true;

            this.form.statusChanges
                .pipe(
                    filter((status) => 'PENDING' !== status),
                    first(),
                )
                .subscribe(() => {
                    this.formSubmit.emit({
                        data: this.data,
                        valid: this.form.valid,
                        pending: this.form.pending,
                    });

                    this.buttonsDisabled = this.form.valid;
                });
        }
    }
}
