import { Inject, Injectable } from '@angular/core';
import { AppApiHttpClient } from '@app/core/core.module';
import { SerializingHttpClient } from '@app/core/http/serializing-http-client';
import { ActivityType } from '@app/shared/models/activity-type.model';
import { Cache, FetchMode } from '@app/shared/utils/cache';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ActivityTypeService {

    private cache = new Cache<ActivityType>((activityType) => activityType.id);

    constructor(@Inject(AppApiHttpClient) private http: SerializingHttpClient) {
    }

    getActivityTypes(fetchMode: FetchMode = 'cache-first'): Observable<ActivityType[]> {
        return this.cache.getAll(
            fetchMode,
            () => this.http.get(ActivityType, '/activity-types?activeOnly=1'),
        );
    }

    getActivityType(id: string, fetchMode: FetchMode = 'cache-first'): Observable<ActivityType> {
        return this.cache.get(
            id,
            fetchMode,
            () => this.http.get(ActivityType, `/activity-types/${id}`),
        );
    }

}
