import { Pipe, PipeTransform } from '@angular/core';
import linkifyHtml from 'linkify-html';
import { Options } from 'linkifyjs';

function removeProtocolFromString(value: string) {
    return value.replace('https://', '').replace('http://', '');
}

type OptionalOptions = Partial<Options>;

const DEFAULT_OPTIONS: OptionalOptions = {
    defaultProtocol: 'https',
    rel: 'noopener',
    target: {
        url: '_blank',
        email: null,
    },
    format: removeProtocolFromString,
};

@Pipe({ name: 'autolink' })
export class AutolinkPipe implements PipeTransform {

    transform(value: string, options?: OptionalOptions): string {
        return linkifyHtml(value.toString(), { ...DEFAULT_OPTIONS, ...options });
    }
}
