<ion-card *ngIf="event"
          [ngClass]="useMargin ? '' : 'ion-no-margin'"
          (click)="onSelect(event)">
    <img *ngIf="event.featured" src="{{event.image[imageRatio]}}"/>
    <ion-card-header>
        <ion-card-subtitle *ngIf="event.startTime && event.endTime">
            {{event.startTime | periodUntil:event.endTime}}</ion-card-subtitle>
        <ion-card-title>{{event.title}}</ion-card-title>
    </ion-card-header>
    <ion-card-content>
        <p>{{event.place.name}},
            <span class="location">{{event.place.address.postalCode}} {{event.place.address.city}}</span></p>
    </ion-card-content>
    <ion-toolbar>
        <!-- mode ios to disable the ripple effect on click -->
        <ion-chip mode="ios" [color]="null !== event.registration ? 'success' : 'medium'" *ngIf="event.registrationOpen">
            <ion-icon [name]="null !== event.registration ? 'checkmark' : 'man'"></ion-icon>
            <ion-label>{{event.numberOfAttendees}}<ng-container *ngIf="event.maximumNumberOfAttendees">/{{event.maximumNumberOfAttendees}}</ng-container></ion-label>
        </ion-chip>
        <ion-chip mode="ios" color="medium" class="limited-width">
            <ion-label>{{event.eventType.name}}</ion-label>
        </ion-chip>
        <ion-buttons slot="end">
            <ion-button (click)="onShareViaPopover(event, $event)" *platform="['desktop', 'mobileweb']">
                <ion-icon slot="icon-only" name="share-social-outline"></ion-icon>
            </ion-button>
            <ion-button (click)="onShareViaNativeDialog(event, $event)" *platform="'capacitor'">
                <ion-icon slot="icon-only" name="share-social-outline"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-card>
