export enum Region {
    WIEN = 'bundesland-wien',
    BURGENLAND = 'bundesland-burgenland',
    NIEDEROESTERREICH = 'bundesland-niederoesterreich',
    OBEROESTERREICH = 'bundesland-oberoesterreich',
    SALZBURG = 'bundesland-salzburg',
    STEIERMARK = 'bundesland-steiermark',
    KAERNTEN = 'bundesland-kaernten',
    TIROL = 'bundesland-tirol',
    VORARLBERG = 'bundesland-vorarlberg',
}

export const REGION_LABELS = {
    [Region.WIEN]: 'Wien',
    [Region.BURGENLAND]: 'Burgenland',
    [Region.NIEDEROESTERREICH]: 'Niederösterreich',
    [Region.OBEROESTERREICH]: 'Oberösterreich',
    [Region.SALZBURG]: 'Salzburg',
    [Region.STEIERMARK]: 'Steiermark',
    [Region.KAERNTEN]: 'Kärnten',
    [Region.TIROL]: 'Tirol',
    [Region.VORARLBERG]: 'Vorarlberg',
};
