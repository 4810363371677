import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AddLinkTrackingPipe } from '@app/shared/pipes/add-link-tracking/add-link-tracking.pipe';
import { NotificationPopoverComponent } from '@app/notifications/notification-popover/notification-popover.component';
import { FaqAnswerLongComponent } from '@app/shared/components/faq-card-component/faq-answer-long/faq-answer-long.component';
import { FaqCardComponent } from '@app/shared/components/faq-card-component/faq-card/faq-card.component';
import { FaqListComponent } from '@app/shared/components/faq-card-component/faq-list/faq-list.component';
import { FaqQuestionComponent } from '@app/shared/components/faq-card-component/faq-question/faq-question.component';
import { FooterComponent } from '@app/shared/components/footer/footer.component';
import { FormErrorComponent } from '@app/shared/components/form-error/form-error.component';
import { FormHintComponent } from '@app/shared/components/form-hint/form-hint.component';
import { NewsItemComponent } from '@app/shared/components/news-item/news-item.component';
import { NotificationButtonComponent } from '@app/shared/components/notification-button/notification-button.component';
import { ShareOptionsComponent } from '@app/shared/components/share-options/share-options.component';
import { UserMenuButtonComponent } from '@app/shared/components/user-menu-button/user-menu-button.component';
import { UserMenuOptionsComponent } from '@app/shared/components/user-menu-button/user-menu-options/user-menu-options.component';
import { InvalidDirective } from '@app/shared/directives/validation/invalid.directive';
import { MaxFileSizeDirective } from '@app/shared/directives/validation/max-file-size.directive';
import { PasswordStrengthDirective } from '@app/shared/directives/validation/password-strength.directive';
import { SameAsDirective } from '@app/shared/directives/validation/same-as.directive';
import { ValidDestinationNameDirective } from '@app/shared/directives/validation/valid-destination-name.directive';
import { ValidPostalCodeDirective } from '@app/shared/directives/validation/valid-postal-code.directive';
import { AutolinkPipe } from '@app/shared/pipes/autolink/autolink.pipe';
import { TimeFromNowPipe } from '@app/shared/pipes/time-from-now.pipe';
import { SanitizePipe } from '@app/shared/pipes/sanitize/sanitize.pipe';
import { TranslatedPipe } from '@app/shared/pipes/translated/translated.pipe';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { EscapeHtmlPipe } from "@app/shared/pipes/escape-html/escape-html.pipe";
import { TagAsSafeHtmlPipe } from "@app/shared/pipes/tag-as-safe-html/tag-as-safe-html.pipe";
import { ActivityCardComponent } from './components/activity/activity-card/activity-card.component';
import { ButtonBarComponent } from './components/button-bar/button-bar.component';
import { ButtonsComponent } from './components/buttons/buttons.component';
import { EventCardComponent } from './components/event/event-card/event-card.component';
import { GlobalMenuLayoutComponent } from './components/global-menu-layout/global-menu-layout.component';
import { HeadlineComponent } from './components/headline/headline.component';
import { ImageInputComponent } from './components/image-input/image-input.component';
import { LegalBarComponent } from './components/legal-bar/legal-bar.component';
import { MenuComponent } from './components/menu/menu.component';
import { NewsItemCardComponent } from './components/news-item/news-item-card/news-item-card.component';
import { ReauthenticateFormComponent } from './components/reauthenticate-form/reauthenticate-form.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SplitPaneLayoutComponent } from './components/split-pane-layout/split-pane-layout.component';
import { WebviewComponent } from './components/webview/webview.component';
import { NgModelIonicDirective } from './directives/forms/ng-model-ionic/ng-model-ionic.directive';
import { BackLinkDirective } from './directives/navigation/back-link.directive';
import { CorrectPasswordDirective } from './directives/validation/correct-password.directive';
import { MaxLengthDirective } from './directives/validation/max-length.directive';
import { MaxDirective } from './directives/validation/max.directive';
import { MinDirective } from './directives/validation/min.directive';
import { PhoneNumberDirective } from './directives/validation/phone-number.directive';
import { RequiredCheckboxDirective } from './directives/validation/required-checkbox.directive';
import { DelayedAutoFocusDirective } from './directives/view/delayed-auto-focus.directive';
import { PlatformDirective } from './directives/view/platform.directive';
import { EncodeQueryParamPipe } from './pipes/encode-query-param/encode-query-param.pipe';
import { PeriodUntilPipe } from './pipes/period-until/period-until.pipe';

const modules = [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
];

const directives = [
    BackLinkDirective,
    CorrectPasswordDirective,
    DelayedAutoFocusDirective,
    InvalidDirective,
    MaxDirective,
    MaxFileSizeDirective,
    MaxLengthDirective,
    MinDirective,
    NgModelIonicDirective,
    PasswordStrengthDirective,
    PhoneNumberDirective,
    PlatformDirective,
    RequiredCheckboxDirective,
    SameAsDirective,
    ValidDestinationNameDirective,
    ValidPostalCodeDirective,
];

const components = [
    ActivityCardComponent,
    ButtonBarComponent,
    ButtonsComponent,
    EventCardComponent,
    FaqAnswerLongComponent,
    FaqCardComponent,
    FaqListComponent,
    FaqQuestionComponent,
    FooterComponent,
    FormErrorComponent,
    FormHintComponent,
    GlobalMenuLayoutComponent,
    HeadlineComponent,
    ImageInputComponent,
    LegalBarComponent,
    MenuComponent,
    NewsItemCardComponent,
    NewsItemComponent,
    NotificationButtonComponent,
    NotificationPopoverComponent,
    ReauthenticateFormComponent,
    ShareOptionsComponent,
    SpinnerComponent,
    SplitPaneLayoutComponent,
    UserMenuButtonComponent,
    UserMenuOptionsComponent,
    WebviewComponent,
];

const pipes = [
    AddLinkTrackingPipe,
    AutolinkPipe,
    EncodeQueryParamPipe,
    PeriodUntilPipe,
    SanitizePipe,
    EscapeHtmlPipe,
    TagAsSafeHtmlPipe,
    TimeFromNowPipe,
    TranslatedPipe,
];

@NgModule({
    imports: [
        ...modules,
    ],
    declarations: [
        ...directives,
        ...components,
        ...pipes,
    ],
    exports: [
        ...modules,
        ...directives,
        ...components,
        ...pipes,
    ],
})
export class SharedModule {
}
