import { Component, ErrorHandler, Input, OnInit } from '@angular/core';
import { FaqService } from '@app/core/services/faq/faq.service';
import { FaqViewItemModel } from '@app/shared/models/faqviewitem.model';
import { map } from 'rxjs/operators';
import { faqError } from './faq-error';

export enum FilterChangeSource {
    MAP_UPDATE,
    FILTER_CHANGE,
    LOCATION_CHANGE
}

@Component({
    selector: 'app-faq-list',
    templateUrl: './faq-list.component.html',
    styleUrls: ['./faq-list.component.scss'],
})
export class FaqListComponent implements OnInit {

    activeSearch = false;

    query: string;

    totalItems: FaqViewItemModel[] = [];

    fetchedItems: FaqViewItemModel[] = [];

    items: FaqViewItemModel[] = [];

    pageNumber: number;

    itemsPerPage: number;

    hasNext: boolean;

    hasFetchError: boolean;

    loading = true;

    @Input() faqUrl: string;

    constructor(
        private faqService: FaqService,
        private errorHandler: ErrorHandler,
    ) {
        this.pageNumber = 1;
        this.itemsPerPage = 10;
    }

    ngOnInit() {
        this.faqService.getFaqData(this.faqUrl).pipe(
            map((items) => items.map(
                (item, index, allItems) => new FaqViewItemModel(
                    item,
                    0 === index || item.section !== allItems[index - 1].section,
                ),
            )),
        ).subscribe((data) => {
                this.fetchedItems = data;
                this.totalItems = data;
                this.hasNext = true;
                this.loading = false;
                this.hasFetchError = false;

                // display data based on pageNumber and itemsPerPage
                this.chunkDataItems();
            },
            (error) => {
                this.errorHandler.handleError(faqError(error));

                this.loading = false;
                this.hasFetchError = true;
            });
    }

    trackByFn(index) {
        return index;
    }

    onFilterList(event) {
        this.activeSearch = this.query.trim().length > 2;

        if (this.activeSearch) {

            // filter data based on question and answer
            this.totalItems = this.fetchedItems
                .filter((item) => {
                        const searchTerms = this.query.trim().split(/[ ]+/);

                        return searchTerms.every((term) => {
                            // answer can include html tags, so we need to strap all thml tags
                            return item.item.question.toLowerCase().indexOf(term.toLowerCase()) !== -1 ||
                                item.item.answer.replace(/(<([^>]+)>)/ig, ' ').toLowerCase().indexOf(term.toLowerCase()) !== -1;
                        });
                    });

            // reset data to chunk filtered data
            this.items = [];
            this.hasNext = true;
            this.pageNumber = 1;

            this.chunkDataItems();
        } else {
            // reset data to show full list
            this.totalItems = this.fetchedItems;
            this.pageNumber = 1;
            this.items = [];
            this.hasNext = true;

            this.chunkDataItems();
        }
    }

    onFetchMore(event) {
        this.pageNumber++;
        this.chunkDataItems();

        event.target.complete();
    }

    private chunkDataItems() {

        // we do not fetch the results from the search we need
        // to update the field display section first
        this.totalItems.forEach((item, index) =>
            item.displaySection = (0 === index || item.item.section !== this.totalItems[index - 1].item.section));

        let start = 0;

        // start position in array
        if (this.pageNumber > 1) {
            start = (this.pageNumber - 1) * this.itemsPerPage;
        }

        // add items
        this.items = [...this.items, ...this.totalItems.slice(start, start + this.itemsPerPage)];

        // disable the fetch, if data all data is displayed
        if (this.items.length > this.totalItems.length) {
            this.hasNext = false;
        }
    }
}
