import { Component, Input } from '@angular/core';
import { NewsItemImageSize } from '@app/shared/models/news-item-image.model';
import { NewsItem } from '@app/shared/models/news-item.model';

@Component({
    selector: 'app-news-card',
    templateUrl: './news-item-card.component.html',
    styleUrls: ['./news-item-card.component.scss'],
})
export class NewsItemCardComponent {

    imageRatio: NewsItemImageSize = window.devicePixelRatio > 1 ? '1400x700' : '700x350';

    @Input('newsItem') newsItem: NewsItem;
}
