import { Directive, forwardRef } from '@angular/core';
import { CheckboxRequiredValidator, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: 'ion-checkbox[required]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => RequiredCheckboxDirective),
            multi: true,
        },
    ],
})
export class RequiredCheckboxDirective extends CheckboxRequiredValidator {
}
