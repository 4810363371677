import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationService } from '@app/core/services/notification/notification.service';
import { environment } from '@app/environments/environment';
import { NotificationPopoverComponent } from '@app/notifications/notification-popover/notification-popover.component';
import { NavController, Platform, PopoverController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-notification-button',
    templateUrl: './notification-button.component.html',
    styleUrls: ['./notification-button.component.scss'],
})
export class NotificationButtonComponent implements OnInit, OnDestroy {

    unreadCount: number;

    notificationsEnabled = environment.notificationsEnabled;

    private ngUnsubscribe = new Subject<void>();

    constructor(private notificationService: NotificationService,
                private popoverController: PopoverController,
                private navController: NavController,
                private platform: Platform) {
    }

    ngOnInit(): void {
        if (!this.notificationsEnabled) {
            return;
        }

        this.notificationService.getUnreadCount()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (unreadCount) => {
                    this.unreadCount = unreadCount;
                },
            );
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    async onClick(event) {
        if (!this.notificationsEnabled) {
            return;
        }

        if (this.platform.is('desktop')) {
            const popover = await this.popoverController.create({
                component: NotificationPopoverComponent,
                event,
                translucent: true,
                cssClass: 'notification-popover',
            });

            popover.present();
        } else {
            this.navController.navigateForward('/notifications');
        }
    }
}
