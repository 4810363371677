import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Platforms } from '@ionic/core';

@Injectable({
    providedIn: 'root',
})
export class CustomPlatformService extends Platform {

    private readonly MIN_DEVICE_WIDTH_FOR_DESKTOP = 1024;

    /**
     * We are using our own mobile check as ionic handles all touch devices as
     * mobile devices. So laptops with touch screen would be recognized as
     * mobile.
     * Apple devices are all known as iOS but apple does not have mac's with
     * touchscreen.
     * Windows devices are recognized as android devices.
     * That's why we check the screen size.
     * There is a known issue in github for that:
     * https://github.com/ionic-team/ionic-framework/issues/19942
     */
    get isMobile(): boolean {
        return super.width() < this.MIN_DEVICE_WIDTH_FOR_DESKTOP;
    }

    is(platformName: Platforms): boolean {
        switch (platformName) {
            case 'mobile':
                return this.isMobile;
            case 'desktop':
                return !this.isMobile;
            default:
                return super.is(platformName);
        }
    }

}
