import { Address } from '@app/shared/models/address.model';
import { Type } from 'class-transformer';

export class Place {

    id: string;

    name: string;

    latitude: number;

    longitude: number;

    @Type(() => Address)
    address: Address;

}
