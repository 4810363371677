import { Component, Input, OnChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ProfileService } from '@app/core/services/profile/profile.service';
import { Profile } from '@app/shared/models/profile.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-webview',
    templateUrl: './webview.component.html',
    styleUrls: ['./webview.component.scss'],
})
export class WebviewComponent implements OnChanges {

    @Input() src: string;

    @Input() contactIdParam: string | null = null;

    @Input() contactNameParam: string | null = null;

    trustedSrc: SafeResourceUrl;

    profile?: Profile;

    private ngUnsubscribe = new Subject<void>();

    constructor(private sanitizer: DomSanitizer,
                private profileService: ProfileService) {
    }

    ngOnChanges(): void {
        this.profileService.getProfile('cache-only')
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((profile) => {
                this.profile = profile;
                // Add a timestamp in order to prevent caching issues
                const now = Date.now();
                const url = new URL(this.src);

                if (null !== this.contactIdParam) {
                    url.searchParams.set(this.contactIdParam, this.profile.contactId);
                }

                if (null !== this.contactNameParam) {
                    url.searchParams.set(this.contactNameParam, this.profile.fullName);
                }

                url.searchParams.set('t', now.toString());

                this.trustedSrc = this.sanitizer.bypassSecurityTrustResourceUrl(url.toString());
            });
    }
}
