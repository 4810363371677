import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot, CanActivate, CanLoad, Route, RouterStateSnapshot, UrlSegment,
} from '@angular/router';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication/authentication.service';

@Injectable({
    providedIn: 'root',
})
export class IsAuthenticatedGuard implements CanActivate, CanLoad {

    constructor(private authenticationService: AuthenticationService,
                private navController: NavController) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.isAuthenticated(state.url);
    }

    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
        return this.isAuthenticated(segments.map((segment) => segment.path));
    }

    private isAuthenticated(url: string | any[]): Observable<boolean> {
        return this.authenticationService.isAuthenticated().pipe(
            tap((authenticated) => {
                // Ugly programming with side effects
                // Seems to be the recommended way to solve this
                if (!authenticated) {
                    this.authenticationService.setRedirectUrlAfterSignin(url);
                    this.navController.navigateRoot(['login']);
                }
            }),
            // Guards ignore anything but the first emitted value
            // and require the observable to complete
            first(),
        );
    }
}
