import { MAX_ZOOM_LEVEL, WORLD_HEIGHT, WORLD_WIDTH } from '@app/shared/utils/geo/constants';
import { lat2Rad } from '@app/shared/utils/geo/lat2Rad';

function getZoomLevel(mapPx: number, worldPx: number, fraction: number): number {
    return Math.round(Math.log(mapPx / (fraction * worldPx)) / Math.LN2);
}

export function calculateZoomLevelB(bounds: google.maps.LatLngBoundsLiteral, mapHeight: number, mapWidth: number) {
    const latFraction = (lat2Rad(bounds.north) - lat2Rad(bounds.south)) / Math.PI;

    const lngDiff = bounds.east - bounds.west;
    const lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

    const latZoom = getZoomLevel(mapHeight, WORLD_HEIGHT, latFraction);
    const lngZoom = getZoomLevel(mapWidth, WORLD_WIDTH, lngFraction);

    return Math.min(Math.max(latZoom, lngZoom), MAX_ZOOM_LEVEL);
}
