import { AfterViewInit, Directive, Input, Optional } from '@angular/core';
import { IonInput, IonTextarea } from '@ionic/angular';

@Directive({
    selector: 'ion-input[delayedAutoFocus], ion-textarea[delayedAutoFocus]',
})
export class DelayedAutoFocusDirective implements AfterViewInit {

    private static DEFAULT_DELAY = 300;

    @Input()
    set delayedAutoFocus(delay: any) {
        this.delay = Number(String(delay)) || DelayedAutoFocusDirective.DEFAULT_DELAY;
    }

    private delay = DelayedAutoFocusDirective.DEFAULT_DELAY;

    constructor(
        @Optional() public input?: IonInput,
        @Optional() public inputTextarea?: IonTextarea,
    ) {
    }

    ngAfterViewInit(): void {
        // Ionic's autofocus directive very often doesn't work, supposedly
        // due to the page animations
        // Hence keep a certain delay before activating the focus
        setTimeout(() => {
            this.input?.setFocus();
            this.inputTextarea?.setFocus();
        }, this.delay);
    }

}
