import { ErrorHandler, Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

const DEFAULT_TOAST_DURATION = 3000;

@Injectable({
    providedIn: 'root',
})
export class InfoService {

    constructor(private toastController: ToastController,
                private errorHandler: ErrorHandler) {
    }

    success(message: string) {
        this.presentToast(message);
    }

    error(message: string, error?: unknown) {
        this.presentToast(message);

        if (error) {
            this.errorHandler.handleError(error);
        }
    }

    private async presentToast(message: string) {
        const toast = await this.toastController.create({
            message,
            color: 'dark',
            position: 'bottom',
            duration: DEFAULT_TOAST_DURATION,
        });

        await toast.present();
    }

}
