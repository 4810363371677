// This file is generated from clients/[client]/app/menu.ts

export const menu = [
    {
        title: 'Mein Briefing',
        url: '/my-briefing',
        icon: 'checkmark-circle',
    },
    {
        title: 'Aktionen',
        url: '/activities',
        icon: 'rocket',
    },
    {
        title: 'Veranstaltungen',
        url: '/events',
        icon: 'calendar',
    },
    {
        title: 'FAQ',
        url: '/faq',
        icon: 'bulb',
    },
    {
        title: 'Feedback',
        // Needs to be kept in sync with feedback_email in config.yml
        url: 'mailto:kontakt@junos.at',
        target: '_self',
        icon: 'mail',
        hideOnMobile: true,
    },
];
