import { WORLD_HEIGHT, WORLD_WIDTH } from '@app/shared/utils/geo/constants';
import { lat2Rad } from '@app/shared/utils/geo/lat2Rad';
import { rad2Lat } from '@app/shared/utils/geo/rad2Lat';
import { wrapLng180 } from '@app/shared/utils/geo/wrapLng180';

function getFraction(mapPx: number, worldPx: number, zoomLevel: number): number {
    return mapPx / (Math.pow(Math.E, zoomLevel * Math.LN2) * worldPx);
}

export function calculateBoundsZ(center: google.maps.LatLngLiteral, zoomLevel: number, mapHeight: number, mapWidth: number): google.maps.LatLngBoundsLiteral {
    const latFraction = getFraction(mapHeight, WORLD_HEIGHT, zoomLevel);
    const lngFraction = getFraction(mapWidth, WORLD_WIDTH, zoomLevel);
    const latDiff = latFraction * Math.PI;
    const lngDiff = lngFraction * 360;
    const latCRad = lat2Rad(center.lat);

    return {
        south: Math.max(rad2Lat(latCRad - latDiff / 2), -90),
        west: wrapLng180(center.lng - lngDiff / 2),
        north: Math.min(rad2Lat(latDiff / 2 + latCRad), 90),
        east: wrapLng180(lngDiff / 2 + center.lng),
    };
}
