<app-spinner *ngIf="loading; else loaded" [label]="slogan" centered></app-spinner>

<ng-template #loaded>
    <ion-header *platform="'desktop'">
        <ion-toolbar color="primary">
            <ion-buttons slot="start">
                <ion-menu-button></ion-menu-button>
            </ion-buttons>
            <ion-title>{{title}}</ion-title>
            <ion-buttons slot="end">
                <app-notification-button></app-notification-button>
                <app-user-menu-button></app-user-menu-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>

    <ion-router-outlet [environmentInjector]="environmentInjector"></ion-router-outlet>

    <app-footer *platform="'desktop'"></app-footer>
</ng-template>
