import { Directive, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
    AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn,
} from '@angular/forms';
import maxFileSizeValidator, {
    DEFAULT_MAX_FILE_SIZE,
} from '@app/shared/validators/max-file-size.validator';

@Directive({
    selector: '[maxFileSize]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => MaxFileSizeDirective),
            multi: true,
        },
    ],
})
export class MaxFileSizeDirective implements Validator, OnChanges {

    @Input() maxSize = DEFAULT_MAX_FILE_SIZE;

    private validator: ValidatorFn = maxFileSizeValidator(this.maxSize);

    validate(control: AbstractControl): ValidationErrors | null {
        return this.validator(control);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('maxSize' in changes) {
            this.validator = maxFileSizeValidator(this.maxSize);
        }
    }
}
