interface Identifiable {
    id: string;
}

export class IdentityMap<T extends Identifiable> {

    private map: { [id: string]: T } = {};

    get(id: string): T {
        return this.map[id];
    }

    getAll(): T[] {
        return Object.values(this.map);
    }

    contains(id: string) {
        return id in this.map;
    }

    merge(item: T, fields?: string[]): T {
        if (item.id in this.map) {
            // If only specific fields were fetched from the server, override
            // only these fields in the identity map
            if (fields) {
                for (const field of fields) {
                    this.map[item.id][field] = item[field];
                }
            } else {
                Object.assign(this.map[item.id], item);
            }
        } else {
            this.map[item.id] = item;
        }

        return this.map[item.id];
    }

    forEach(fn: (object: T, index: number, array: T[]) => void): void {
        return this.getAll().forEach(fn);
    }

}
