import { Component, HostBinding } from '@angular/core';

@Component({
    selector: 'app-faq-answer-long',
    templateUrl: './faq-answer-long.component.html',
    styleUrls: ['./faq-answer-long.component.scss'],
})
export class FaqAnswerLongComponent {

    @HostBinding('class.collapsed') collapsed = true;

    setCollapsed(collapsed: boolean) {
        this.collapsed = collapsed;
    }

}
