<app-spinner *ngIf="loading; else loaded"></app-spinner>

<ng-template #loaded>
    <ion-list>
        <ng-container *ngFor="let notification of notifications">
            <ng-container [ngTemplateOutlet]="notification.url ? notificationItemWithLink : notificationItem"
                          [ngTemplateOutletContext]="{ $implicit: notification }">
            </ng-container>
        </ng-container>
        <ion-item *ngIf="0 === notifications.length" lines="none">
            <ion-label>Keine Benachrichtigungen vorhanden</ion-label>
        </ion-item>
        <ion-item *ngIf="0 < notifications.length" button (click)="onShowAll()" lines="none">
            <ion-label>Alle anzeigen</ion-label>
        </ion-item>
    </ion-list>
</ng-template>

<ng-template #notificationItemWithLink let-notification>
    <a href="{{ notification.url | addLinkTracking }}" target="_blank">
        <ion-item button (click)="onDismiss()" [class.unread]="!notification.readAt">
            <ng-container [ngTemplateOutlet]="notificationItemLabel"
                          [ngTemplateOutletContext]="{ $implicit: notification }"></ng-container>
            <ion-icon name="open" slot="end"></ion-icon>
        </ion-item>
    </a>
</ng-template>

<ng-template #notificationItem let-notification>
    <ion-item [class.unread]="!notification.readAt">
        <ng-container [ngTemplateOutlet]="notificationItemLabel"
                      [ngTemplateOutletContext]="{ $implicit: notification }"></ng-container>
    </ion-item>
</ng-template>

<ng-template #notificationItemLabel let-notification>
    <ion-label>
        <div [innerHTML]="notification.message"></div>
        <p>{{notification.sentAt | date:'longDate'}} um {{notification.sentAt | date:'shortTime'}}</p>
    </ion-label>
</ng-template>
