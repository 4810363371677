<ion-content>
    <app-spinner *ngIf="loading; else loaded"></app-spinner>

    <ng-template #loaded>
        <ion-refresher slot="fixed" (ionRefresh)="onRefresh($event)" *platform="'mobile'">
            <ion-refresher-content refreshingSpinner="crescent"></ion-refresher-content>
        </ion-refresher>

        <ion-card class="title">
            <ion-card-header>
                <ion-card-title>
                    {{totalCount || 'Keine'}} {{1 === totalCount ? 'Veranstaltung' : 'Veranstaltungen'}}
                    gefunden
                </ion-card-title>
                <!-- Must not be first child, otherwise display issues -->
                <ion-spinner *ngIf="refreshing"></ion-spinner>
            </ion-card-header>
            <ion-card-content>
                <!-- @formatter:off -->
                <!-- Weird indentation and line breaks in order to not introduce spaces before the commas -->
                {{filters.listRadius}} km von {{filters.focus === geoCenter.HOME
                    ? 'zuhause'
                    : (filters.focus === geoCenter.USER_LOCATION
                        ? 'meiner Position'
                        : round(filters.latitude) + ', ' + round(filters.longitude))
                }}<ng-container *ngIf="filters.region">, Bundesland:
                {{regionLabels[filters.region]}}</ng-container><ng-container *ngIf="filters.postalCode">, PLZ:
                {{filters.postalCode}}</ng-container><ng-container *ngIf="filters.onlyRegistered">, Meine
                Veranstaltungen</ng-container><ng-container *ngIf="null !== filters.period">,
                {{filterPeriodLabels[filters.period]}}</ng-container><ng-container *ngIf="filterEntity">,
                Organisiert von:
                {{filterEntity.name}}</ng-container><ng-container *ngIf="filterEventType">,
                Format: {{filterEventType.name}}</ng-container>
                <!-- Weird indentation end -->
                <!-- @formatter:on -->
            </ion-card-content>
        </ion-card>

        <app-event-card *ngFor="let event of events; trackBy: trackByFn"
                        [event]="event"
                        [profile]=""></app-event-card>

        <ion-infinite-scroll threshold="10px" [disabled]="!hasNext" (ionInfinite)="onFetchMore($event)">
            <ion-infinite-scroll-content loadingText="Weitere Veranstaltungen werden geladen…"></ion-infinite-scroll-content>
        </ion-infinite-scroll>
    </ng-template>
</ion-content>
