import { Injectable } from '@angular/core';
import { StorageService } from '@app/core/services/storage/storage.service';
import { Filters } from '@app/shared/models/filters.model';
import { from, Observable } from 'rxjs';

export const ACTIVITY_FILTERS_STORAGE_KEY = 'filters-activities';
export const EVENT_FILTERS_STORAGE_KEY = 'filters-events';

@Injectable({
    providedIn: 'root',
})
export class FilterStorageService {

    constructor(
        private storageService: StorageService,
    ) {
    }

    getFilters(filterKey: string): Observable<Filters> {
        return from(this.storageService.get(filterKey));
    }

    saveFilters(filterKey: string, filters: Filters): Observable<Filters> {
        return from(this.storageService.set(filterKey, filters));
    }

    removeFilters(filterKey: string): Observable<any> {
        return from(
            this.storageService.remove(filterKey),
        );
    }
}
