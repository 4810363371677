import { Inject, Injectable } from '@angular/core';
import { AppApiHttpClient } from '@app/core/core.module';
import { SerializingHttpClient } from '@app/core/http/serializing-http-client';
import { IdentityMap } from '@app/core/services/identity-map';
import { NewsItem } from '@app/shared/models/news-item.model';
import { Page } from '@app/shared/models/page.model';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class NewsItemService {

    private identityMap = new IdentityMap<NewsItem>();

    constructor(@Inject(AppApiHttpClient) private http: SerializingHttpClient) {
    }

    getNewsItems(page = 1, pageSize = 20, source = 'internal', order: 'asc'|'desc' = 'desc'): Observable<Page<NewsItem>> {
        return this.http
            .get(Page, `/news-items?page=${page}&pageSize=${pageSize}&source=${source}&sort=publishedAt:${order}`, {
                headers: {
                    Accept: 'application/ld+json',
                },
            })
            .pipe(
                tap((result: Page<NewsItem>) => result.items = result.items.map(
                    (newsItem) => this.identityMap.merge(newsItem),
                )),
            );
    }

    getNextNewsItem(source = 'internal'): Observable<NewsItem> {
        return this.http
            .get(Page, `/news-items?page=1&pageSize=1&source=${source}`, {
                headers: {
                    Accept: 'application/ld+json',
                },
            })
            .pipe(
                tap((result: Page<NewsItem>) => result.items = result.items.map(
                    (newsItem) => this.identityMap.merge(newsItem),
                )),
                map((result: Page<NewsItem>) => result.items[0] || null),
            );
    }

    getNewsItem(id: string): Observable<NewsItem> {
        return this.http.get(NewsItem, `/news-items/${id}`)
            .pipe(map((newsItem: NewsItem) => this.identityMap.merge(newsItem)));
    }

}
