import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { AppApiHttpClient } from '@app/core/core.module';
import { SerializingHttpClient } from '@app/core/http/serializing-http-client';
import { AppLifecycleService } from '@app/core/services/app-lifecycle/app-lifecycle.service';
import { AuthenticationService } from '@app/core/services/authentication/authentication.service';
import { IdentityMap } from '@app/core/services/identity-map';
import { MessagingService } from '@app/core/services/messaging/messaging.service';
import { environment } from '@app/environments/environment';
import { NotificationChannel } from '@app/shared/models/notification-channel.model';
import { Notification } from '@app/shared/models/notification.model';
import { Page } from '@app/shared/models/page.model';
import { catchAndReportError } from '@app/shared/utils/rxjs/catchAndReportError';
import { repeatWhen } from '@app/shared/utils/rxjs/repeatWhen';
import { retryAfter } from '@app/shared/utils/rxjs/retryAfter';
import { from, interval, merge, Observable, ReplaySubject, Subject } from 'rxjs';
import { delay, filter, map, switchMap, tap } from 'rxjs/operators';

const EVERY_FIVE_MINUTES = 5 * 60000;

@Injectable({
    providedIn: 'root',
})
export class NotificationService {

    private identityMap = new IdentityMap<Notification>();

    private unreadCountRefresh = new Subject<void>();

    private unreadCount = new ReplaySubject<number>(1);

    constructor(
        @Inject(AppApiHttpClient) private http: SerializingHttpClient,
        private messagingService: MessagingService,
        authenticationService: AuthenticationService,
        errorHandler: ErrorHandler,
        appLifecycleService: AppLifecycleService,
    ) {
        if (!environment.notificationsEnabled) {
            return;
        }

        authenticationService.isAuthenticatedAndEmailVerified()
            .pipe(
                repeatWhen(merge(
                    this.unreadCountRefresh,
                    interval(EVERY_FIVE_MINUTES),
                )),
                filter((authenticated) => authenticated),
                switchMap(() => {
                    return this.http.get(null, `/viewer/notifications/unreadcount`)
                        .pipe(
                            // Retry 10 times after 3 seconds each and the
                            // report the error without breaking the outer
                            // observable
                            retryAfter(3000, 10),
                            catchAndReportError(errorHandler),
                        );
                }),
            )
            .subscribe(this.unreadCount);

        this.messagingService.getCurrentMessage()
            .pipe(
                filter((currentMessage) => !!currentMessage),
                // Delay to avoid race conditions
                // The backend pushes the notification and creates the unread count afterwards.
                // Therefore it could possibly be still 0. That's why we delay the next call here.
                // It won't affect the user as only the unread count will update one second later
                // which is negligible.
                delay(1000),
            )
            .subscribe(() => this.refreshUnreadCount());

        appLifecycleService.resume
            .subscribe(() => this.refreshUnreadCount());
    }

    getNotifications(page = 1, pageSize = 20): Observable<Page<Notification>> {
        return this.http
            .get(Page, `/viewer/notifications?page=${page}&pageSize=${pageSize}`, {
                headers: {
                    Accept: 'application/ld+json',
                },
            })
            .pipe(
                tap((result: Page<Notification>) => {
                    result.items = result.items.map(
                        (notification) => this.identityMap.merge(notification),
                    );
                }),
            );
    }

    getNotification(id: string): Observable<Notification> {
        return this.http.get(Notification, `/viewer/notifications/${id}`)
            .pipe(map((notification: Notification) => this.identityMap.merge(notification)));
    }

    getUnreadCount(): Observable<number> {
        return from(this.unreadCount);
    }

    refreshUnreadCount(): void {
        this.unreadCountRefresh.next();
    }

    markReadUntil(id: string): Observable<string> {
        const result = new Subject<string>();

        this.http.post(null, `/viewer/notifications/mark/read/until/${id}`, null)
            .pipe(
                tap(() => this.refreshUnreadCount()),
            )
            .subscribe(result);

        if (this.identityMap.contains(id)) {
            const reference = this.identityMap.get(id);

            this.identityMap.forEach((notification) => {
                if (notification.sentAt <= reference.sentAt) {
                    notification.readAt = new Date();
                }
            });
        }

        return from(result);
    }

    getNotificationChannels(): Observable<NotificationChannel[]> {
        return this.http.get(NotificationChannel, '/notification-channels');
    }
}
