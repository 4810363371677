import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProfileService } from '@app/core/services/profile/profile.service';
import { UserMenuOptionsComponent } from '@app/shared/components/user-menu-button/user-menu-options/user-menu-options.component';
import { Avatar, AvatarSize } from '@app/shared/models/avatar.model';
import { PopoverController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-user-menu-button',
    templateUrl: './user-menu-button.component.html',
    styleUrls: ['./user-menu-button.component.scss'],
})
export class UserMenuButtonComponent implements OnInit, OnDestroy {

    loading = true;

    name: string;

    avatar: Avatar;

    private ngUnsubscribe = new Subject<void>();

    constructor(private profileService: ProfileService,
                private popoverController: PopoverController) {
    }

    ngOnInit(): void {
        this.profileService.getProfile('cache-only')
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (profile) => {
                    this.name = profile.fullName;
                    this.avatar = profile.avatar;
                    this.loading = false;
                },
            );
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    getAvatar(avatar: Avatar): string {
        return avatar.get(AvatarSize.MEDIUM, AvatarSize.SMALL);
    }

    async onClick(event) {
        const popover = await this.popoverController.create({
            component: UserMenuOptionsComponent,
            event,
            translucent: true,
        });

        popover.present();
    }

}
