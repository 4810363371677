import { Injectable } from '@angular/core';
import { ProfileService } from '@app/core/services/profile/profile.service';
import { Profile } from '@app/shared/models/profile.model';
import { sameLatLng } from '@app/shared/utils/geo/sameLatLng';
import { BehaviorSubject, EMPTY, from, Observable, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class EditHomeService {

    private homeSource = new ReplaySubject<google.maps.LatLngLiteral>(1);

    private home = from(this.homeSource)
        .pipe(distinctUntilChanged((x, y) => sameLatLng(x, y)));

    private editingHome = new BehaviorSubject(false);

    private currentHome: google.maps.LatLngLiteral;

    private previousHome: google.maps.LatLngLiteral = null;

    private profile: Profile;

    constructor(private profileService: ProfileService) {
        this.profileService.getProfile('cache-only')
            .subscribe((profile) => {
                this.profile = profile;
                this.homeSource.next({
                    lat: profile.latitude,
                    lng: profile.longitude,
                });
            });

        this.home.subscribe((home) => this.currentHome = home);
    }

    getHome(): Observable<google.maps.LatLngLiteral> {
        return this.home;
    }

    isEditingHome(): Observable<boolean> {
        return from(this.editingHome);
    }

    startEditingHome(): void {
        if (this.editingHome.getValue()) {
            return;
        }

        this.editingHome.next(true);

        this.previousHome = this.currentHome;
    }

    moveHome(home: google.maps.LatLngLiteral): void {
        if (!this.editingHome.getValue()) {
            return;
        }

        this.homeSource.next(home);
    }

    saveNewHome(): Observable<google.maps.LatLngLiteral> {
        if (!this.editingHome.getValue()) {
            return EMPTY;
        }

        this.profile.latitude = this.currentHome.lat;
        this.profile.longitude = this.currentHome.lng;
        this.profile.customGeolocationSet = true;

        const result = new Subject<google.maps.LatLngLiteral>();

        this.profileService.createOrUpdateProfile(this.profile)
            .pipe(
                tap(() => {
                    this.editingHome.next(false);
                    this.previousHome = null;
                }),
                map(() => this.currentHome),
            )
            .subscribe(result);

        return from(result);
    }

    cancelEditingHome(): void {
        if (!this.editingHome.getValue()) {
            return;
        }

        this.editingHome.next(false);
        this.homeSource.next(this.previousHome);

        this.previousHome = null;
    }
}
