import { merge, MonoTypeOperatorFunction, Observable, of } from 'rxjs';
import { mapTo, switchMap } from 'rxjs/operators';

/**
 * Emits the source value initially and reemits it whenever the condition observable emits
 */
export function repeatWhen<T>(condition: Observable<unknown>): MonoTypeOperatorFunction<T> {
    return switchMap(
        (value) => merge(
            of(value),
            condition.pipe(mapTo(value)),
        ),
    );
}
