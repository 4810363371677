<ion-list lines="none">
    <ion-item button
              routerLink="/profile"
              routerDirection="forward"
              (click)="onDismiss()">
        <ion-label>Mein Profil</ion-label>
    </ion-item>
    <ion-item *ngIf="isMembershipPageEnabled"
              button
              routerLink="/membership"
              routerDirection="forward"
              (click)="onDismiss()">
        <ion-label>Meine Mitgliedschaft</ion-label>
    </ion-item>
    <ion-item *ngIf="notificationsEnabled"
              button
              routerLink="/settings"
              routerDirection="forward"
              (click)="onDismiss()">
        <ion-label>Einstellungen</ion-label>
    </ion-item>
    <ion-item button
              routerLink="/change-email"
              routerDirection="forward"
              (click)="onDismiss()">
        <ion-label>E-Mail ändern</ion-label>
    </ion-item>
    <ion-item button
              routerLink="/change-password"
              routerDirection="forward"
              (click)="onDismiss()">
        <ion-label>Passwort ändern</ion-label>
    </ion-item>
    <ion-item button (click)="onLogout()">
        <ion-label>Abmelden</ion-label>
    </ion-item>
</ion-list>
