<a [href]="newsItem.url | addLinkTracking" target="_blank" *ngIf="newsItem">
    <ion-card [class.ion-activatable]="!!newsItem.url">
        <ion-ripple-effect></ion-ripple-effect>
        <img *ngIf="newsItem.image" [src]="newsItem.image[imageRatio]" alt="Titelbild des Beitrags"/>
        <ion-card-header>
            <ion-card-subtitle>
                <ng-container *ngIf="newsItem.author">
                    {{newsItem.author}},
                </ng-container>
                {{newsItem.publishedAt | date:'longDate'}}
            </ion-card-subtitle>
            <ion-card-title>{{newsItem.title}}</ion-card-title>
        </ion-card-header>
        <ion-card-content *ngIf="!!newsItem.text">
            <div [innerHTML]="newsItem.text"></div>

            <div class="continue" *ngIf="!!newsItem.url">Weiterlesen &raquo;</div>
        </ion-card-content>
    </ion-card>
</a>
