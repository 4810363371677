import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'periodUntil',
})
export class PeriodUntilPipe implements PipeTransform {

    transform(value: Date, until: Date): any {
        const dateFormat: Intl.DateTimeFormatOptions = {
            weekday: 'short',
            day: 'numeric',
            month: 'long',
        };
        const timeFormat: Intl.DateTimeFormatOptions = {
            hour: '2-digit',
            minute: '2-digit',
        };

        const startDate = value.toLocaleDateString(undefined, dateFormat);
        const endDate = until.toLocaleDateString(undefined, dateFormat);
        const startTime = value.toLocaleTimeString(undefined, timeFormat);
        const endTime = until.toLocaleTimeString(undefined, timeFormat);
        const startDateTime = `${startDate} ${startTime}`;
        const endDateTime = startDate === endDate ? endTime : `${endDate} ${endTime}`;

        return startDate === endDate && startTime === endTime
            ? startDateTime
            : `${startDateTime} – ${endDateTime}`;
    }

}
