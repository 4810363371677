import { calculateBoundsZ } from '@app/shared/utils/geo/calculateBoundsZ';
import { calculateRadiusB } from '@app/shared/utils/geo/calculateRadiusB';

export function calculateRadiusZ(center: google.maps.LatLngLiteral, zoomLevel: number, mapHeight: number, mapWidth: number): number {
    const bounds = calculateBoundsZ(
        center,
        zoomLevel,
        mapHeight,
        mapWidth,
    );

    return calculateRadiusB(bounds);
}
