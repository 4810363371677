import { Injectable } from '@angular/core';
import { MenuController, Platform } from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class MenuService {

    private mobile: boolean;

    constructor(private menuController: MenuController,
                platform: Platform) {
        this.mobile = platform.is('mobile');
    }

    enableMenu(): void {
        this.menuController.enable(true);
    }

    disableMenu(disableOnMobile = false): void {
        this.menuController.enable(this.mobile && !disableOnMobile);
    }

}
