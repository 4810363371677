import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot, CanActivate, CanLoad, Route, RouterStateSnapshot,
    UrlSegment,
} from '@angular/router';
import { AuthenticationService } from '@app/core/services/authentication/authentication.service';
import { ProfileService } from '@app/core/services/profile/profile.service';
import { NavController } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class IsAuthenticatedAndVerifiedGuard implements CanActivate, CanLoad {

    constructor(private authenticationService: AuthenticationService,
                private profileService: ProfileService,
                private navController: NavController) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.isAuthenticatedAndVerified(state.url);
    }

    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
        return this.isAuthenticatedAndVerified(segments.map((segment) => segment.path));
    }

    private isAuthenticatedAndVerified(url: string | any[]): Observable<boolean> {
        return this.authenticationService.getViewer().pipe(
            switchMap((viewer) => {
                // Ugly programming with side effects
                // Seems to be the recommended way to solve this
                if (!viewer) {
                    this.authenticationService.setRedirectUrlAfterSignin(url);
                    this.navController.navigateRoot(['login']);

                    return of(false);
                }

                return this.profileService.getProfile('cache-only').pipe(
                    map((profile) => {
                        // Even if the viewer has a verified email, we only check the
                        // profile. Should the viewer be verified, then the profile
                        // will be synchronized on the verify-email page
                        if (!profile.emailVerified) {
                            this.navController.navigateRoot(['verify-email']);

                            return false;
                        }

                        return true;
                    }),
                );
            }),
            // Guards ignore anything but the first emitted value
            // and require the observable to complete
            first(),
        );
    }

}
