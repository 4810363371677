import { Pipe, PipeTransform } from '@angular/core';
import { CustomPlatformService } from '@app/core/services/custom-platform.service';
import { EnvironmentService } from '@app/core/services/environment-service';

export interface AppLinkTracking {
    [key: string]: {
        mobile: Record<string, string>;
        desktop: Record<string, string>;
    };
}

function escapeRegExp(string: string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function appendTrackingParameters(currentUrl: string, parameters: URLSearchParams): string {
    const url = new URL(currentUrl);

    parameters.forEach((param, key) => {
        url.searchParams.set(key, param);
    });

    return url.toString();
}

function isUrlMatchingDomain(url: string, domain: string): boolean {
    const matchingDomainRegex = new RegExp('^https?:\/\/([\\w\\d\\.]+\\.)?(' + escapeRegExp(domain) + ')(\/.*)?$', 'i');

    return matchingDomainRegex.test(url);
}

function findDomainForUrl(domains: string[], url: string): string | undefined {
    return domains.find((domain) => isUrlMatchingDomain(url, domain));
}

function chooseParameters(
    url: string,
    appLinkTracking: AppLinkTracking,
    isMobile: boolean,
): URLSearchParams | null {
    const domains = Object.keys(appLinkTracking);
    const domain = findDomainForUrl(domains, url);

    if (!domain) {
        return null;
    }

    const trackingParams = appLinkTracking[domain];
    const params = isMobile
        ? trackingParams['mobile']
        : trackingParams['desktop'];

    return new URLSearchParams(params);
}

@Pipe({ name: 'addLinkTracking' })
export class AddLinkTrackingPipe implements PipeTransform {

    constructor(
        private environment: EnvironmentService,
        private platform: CustomPlatformService,
    ) {
    }

    transform(url: string): string {
        const appLinkTracking: AppLinkTracking | null = this.environment.environment.appLinkTracking;

        if (!appLinkTracking) {
            return url;
        }

        const isMobile = this.platform.isMobile;
        const parameters = chooseParameters(url, appLinkTracking, isMobile);

        if (!parameters) {
            return url;
        }

        return appendTrackingParameters(url, parameters);
    }
}
