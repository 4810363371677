import { TransformationType } from 'class-transformer';
import { TransformFnParams } from 'class-transformer/types/interfaces/index';

export function dateToString({ value, type }: TransformFnParams) {
    if (null === value || undefined === value) {
        return value;
    }

    if (TransformationType.PLAIN_TO_CLASS === type) {
        const date = new Date(value as string);

        if (isNaN(date.getTime())) {
            throw new Error(`The string "${value}" is not a valid date`);
        }

        return date;
    }

    return (value as Date).toISOString();
}
