import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export type MaybeArray<S> = S | S[] | void;

type Constructor<T> = new () => T;

interface Options {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
        [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
    serialize?: boolean;
    deserialize?: boolean;
}

function serialize(data: any | null, options: Options) {
    return data && false !== options.serialize
        ? instanceToPlain(data)
        : data;
}

function deserialize<T extends MaybeArray<S>, S>(data: any, clazz: Constructor<S>, options: Options): T {
    if (!data || !clazz || false === options.deserialize) {
        return data;
    }

    return plainToInstance(clazz, data) as unknown as T;
}

function createUrl(path: string, apiUrl: string): string {
    const isAbsolutePath = path.startsWith(apiUrl);

    if (isAbsolutePath) {
        return path;
    }

    const { origin, pathname: relativeApiUrl } = new URL(apiUrl);

    return path.startsWith(relativeApiUrl)
        ? origin + path
        : apiUrl + path;
}

export class SerializingHttpClient {

    private pathToUrl: (string) => string;

    constructor(private http: HttpClient, apiUrl: string) {
        this.pathToUrl = (path: string) => createUrl(path, apiUrl);
    }

    get<T extends MaybeArray<S>, S>(clazz: Constructor<S>, path: string, options?: Options): Observable<T> {
        options = options || {};
        options.headers = options.headers || {};

        if (!!clazz && false !== options.deserialize && !options.headers['Accept']) {
            options.headers['Accept'] = 'application/json';
        }

        return this.http
            .get(this.pathToUrl(path), options)
            .pipe(map((data) => deserialize(data, clazz, options)));
    }

    put<T extends MaybeArray<S>, S>(clazz: Constructor<S>, path: string, body: any | null, options?: Options): Observable<T> {
        options = options || {};
        options.headers = options.headers || {};

        if (false !== options.serialize && !options.headers['Content-Type']) {
            options.headers['Content-Type'] = 'application/json';
        }

        if (!!clazz && false !== options.deserialize && !options.headers['Accept']) {
            options.headers['Accept'] = 'application/json';
        }

        const json = serialize(body, options);

        return this.http
            .put(this.pathToUrl(path), json, options)
            .pipe(map((data) => deserialize(data, clazz, options)));
    }

    post<T extends MaybeArray<S>, S>(clazz: Constructor<S>, path: string, body: any | null, options?: Options): Observable<T> {
        options = options || {};
        options.headers = options.headers || {};

        if (false !== options.serialize && !options.headers['Content-Type']) {
            options.headers['Content-Type'] = 'application/json';
        }

        if (!!clazz && false !== options.deserialize && !options.headers['Accept']) {
            options.headers['Accept'] = 'application/json';
        }

        const json = serialize(body, options);

        return this.http
            .post(this.pathToUrl(path), json, options)
            .pipe(map((data) => deserialize(data, clazz, options)));
    }

    delete<T extends MaybeArray<S>, S>(clazz: Constructor<S>, path: string, options?: Options): Observable<T> {
        options = options || {};
        options.headers = options.headers || {};

        if (!!clazz && false !== options.deserialize && !options.headers['Accept']) {
            options.headers['Accept'] = 'application/json';
        }

        return this.http
            .delete(this.pathToUrl(path),  options)
            .pipe(map((data) => deserialize(data, clazz, options)));
    }

}
