import {
    AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostBinding,
    OnDestroy, OnInit, ViewChild,
} from '@angular/core';
import { EntityService } from '@app/core/services/entity/entity.service';
import { EventFiltersService } from '@app/core/services/event-filters/event-filters.service';
import { EventTypeService } from '@app/core/services/event-type/event-type.service';
import { EventService } from '@app/core/services/event/event.service';
import { MenuService } from '@app/core/services/menu/menu.service';
import { TitleService } from '@app/core/services/title/title.service';
import { Entity } from '@app/shared/models/entity.model';
import { EventFilters } from '@app/shared/models/event-filters.model';
import { EventType } from '@app/shared/models/event-type.model';
import { Event as EventModel } from '@app/shared/models/event.model';
import { FILTER_PERIOD_LABELS } from '@app/shared/models/filter-period.model';
import { Focus } from '@app/shared/models/geo-center.model';
import { REGION_LABELS } from '@app/shared/models/region.model';
import { queryShadowChild } from '@app/shared/utils/view/queryShadowChild';
import { IonContent, ViewDidEnter } from '@ionic/angular';
import { Observable, of, Subject, zip } from 'rxjs';
import { distinctUntilChanged, first, switchMap, takeUntil, tap } from 'rxjs/operators';

export const SHARE_EVENT_MESSAGE = 'Mir gefällt diese Veranstaltung!';

@Component({
    selector: 'app-event-list',
    templateUrl: './event-list.page.html',
    styleUrls: ['./event-list.page.scss'],
})
export class EventListPage implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked, ViewDidEnter {

    @ViewChild(IonContent, { read: ElementRef, static: true }) ionContent: ElementRef;

    @HostBinding('class.scrollable') scrollable = false;

    loading = true;

    refreshing = false;

    events: EventModel[] = [];

    totalCount: number;

    pageNumber: number;

    hasNext: boolean;

    filters: EventFilters;

    geoCenter = Focus;

    filterPeriodLabels = FILTER_PERIOD_LABELS;

    regionLabels = REGION_LABELS;

    filterEntity: Entity;

    filterEventType: EventType;

    private ngUnsubscribe = new Subject<void>();

    private refreshSource = new Subject<void>();

    private refreshCompleteSource = new Subject<void>();

    private innerScroll: HTMLDivElement;

    constructor(private eventService: EventService,
                private eventFiltersService: EventFiltersService,
                private entityService: EntityService,
                private eventTypeService: EventTypeService,
                private titleService: TitleService,
                private menuService: MenuService,
                private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.refreshSource
            .pipe(
                takeUntil(this.ngUnsubscribe),
                switchMap(() => this.eventService.getEvents(this.filters, 'listRadius', 1)),
            )
            .subscribe((page) => {
                this.events = page.items;
                this.pageNumber = page.number;
                this.totalCount = page.totalCount;
                this.hasNext = page.hasNext;
                this.loading = false;
                this.refreshing = false;

                this.refreshCompleteSource.next();
            });

        this.eventFiltersService.getFilters()
            .pipe(
                takeUntil(this.ngUnsubscribe),
                distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
                tap(() => this.refreshing = true),
                tap((filters: EventFilters) => this.filters = filters),
                tap(() => this.refreshSource.next()),
                switchMap((filters: EventFilters) => {
                    const observables = [];

                    observables.push(filters.entityId
                        ? this.entityService.getEntity(filters.entityId)
                        : of(null));

                    observables.push(filters.eventTypeId
                        ? this.eventTypeService.getEventType(filters.eventTypeId)
                        : of(null));

                    return zip(...observables) as Observable<[Entity, EventType]>;
                }),
            )
            .subscribe(
                ([entity, eventType]) => {
                    this.filterEntity = entity;
                    this.filterEventType = eventType;
                },
            );
    }

    ionViewDidEnter(): void {
        this.titleService.setGlobalMenuTitle('Veranstaltungen in meiner Nähe');
        this.titleService.setHtmlTitle('Veranstaltungen');
        this.menuService.enableMenu();
    }

    ngAfterViewInit(): void {
        setTimeout(
            () => queryShadowChild(this.ionContent.nativeElement.shadowRoot, '.inner-scroll')
                .then((child: HTMLDivElement) => {
                    this.innerScroll = child;

                    this.updateScrollable();
                }),
            0,
        );
    }

    ngAfterViewChecked(): void {
        this.updateScrollable();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onRefresh(event) {
        this.refreshCompleteSource
            .pipe(first())
            .subscribe(() => event.target.complete());

        // Let the observable handle the refreshes so that running requests
        // are properly canceled
        this.refreshSource.next();
    }

    onFetchMore(event) {
        this.eventService.getEvents(this.filters, 'listRadius', this.pageNumber + 1).subscribe(
            (page) => {
                this.events = [ ...this.events, ...page.items ];
                this.pageNumber = page.number;
                this.totalCount = page.totalCount;
                this.hasNext = page.hasNext;

                event.target.complete();
            },
        );
    }

    trackByFn(index: number, event: EventModel): string {
        // Prevent flimmering of the cards during updates
        return event.id;
    }

    round(number: number): number {
        return Math.round(number * 1e6) / 1e6;
    }

    private updateScrollable() {
        // This code is called by ngAfterViewChecked(), so make sure it is
        // *very* efficient
        if (!this.innerScroll) {
            return;
        }

        const scrollable = this.innerScroll.scrollHeight > this.innerScroll.offsetHeight;

        if (scrollable === this.scrollable) {
            return;
        }

        setTimeout(() => {
            this.scrollable = scrollable;

            if (!this.changeDetectorRef['destroyed']) {
                this.changeDetectorRef.detectChanges();
            }
        }, 0);
    }

}
