import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'escapeHtml',
})
export class EscapeHtmlPipe implements PipeTransform {
    transform(unsafeHtml: string): string {
        return unsafeHtml
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#39;');
    }

}
