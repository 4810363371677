import { Routes } from '@angular/router';
import { IsAuthenticatedAndVerifiedGuard } from '@app/core/guards/is-authenticated-and-verified.guard';
import { IsAuthenticatedGuard } from '@app/core/guards/is-authenticated.guard';
import { IsNotAuthenticatedGuard } from '@app/core/guards/is-not-authenticated.guard';
import { GlobalMenuLayoutComponent } from '@app/shared/components/global-menu-layout/global-menu-layout.component';
import { SplitPaneLayoutComponent } from '@app/shared/components/split-pane-layout/split-pane-layout.component';

// This file is generated from clients/[client]/app/routes.ts

export const routes: Routes = [
    {
        path: 'terms-of-use',
        loadChildren: () => import('./profile/terms-of-use/terms-of-use.module').then((m) => m.TermsOfUsePageModule),
    },
    {
        path: 'imprint',
        loadChildren: () => import('./profile/imprint/imprint.module').then((m) => m.ImprintPageModule),
    },
    {
        path: 'about',
        loadChildren: () => import('./profile/about/about.module').then((m) => m.AboutPageModule),
    },
    {
        path: 'info',
        loadChildren: () => import('./profile/info/info.module').then((m) => m.InfoPageModule),
    },
    {
        path: 'licenses',
        loadChildren: () => import('./profile/licenses/licenses.module').then((m) => m.LicensesPageModule),
    },
    {
        path: 'login',
        loadChildren: () => import('./profile/login-step1/login-step1.module').then((m) => m.LoginStep1PageModule),
        canLoad: [IsNotAuthenticatedGuard],
    },
    {
        path: 'password',
        loadChildren: () => import('./profile/login-step2/login-step2.module').then((m) => m.LoginStep2PageModule),
        canLoad: [IsNotAuthenticatedGuard],
    },
    {
        path: 'reset-password',
        loadChildren: () => import('./profile/reset-password/reset-password.module').then((m) => m.ResetPasswordPageModule),
        canLoad: [IsNotAuthenticatedGuard],
    },
    {
        path: 'registration',
        loadChildren: () => import('./profile/registration/registration.module').then((m) => m.RegistrationPageModule),
        canLoad: [IsNotAuthenticatedGuard],
    },
    {
        path: 'verify-email',
        loadChildren: () => import('./profile/verify-email/verify-email.module').then((m) => m.VerifyEmailPageModule),
        canLoad: [IsAuthenticatedGuard],
    },
    {
        path: 'logout',
        loadChildren: () => import('./profile/logout/logout.module').then((m) => m.LogoutPageModule),
        canLoad: [IsAuthenticatedGuard],
    },
    {
        path: 'highlighted-features',
        loadChildren: () => import('./highlighted-features/highlighted-features.module').then((m) => m.HighlightedFeaturesModule),
    },
    {
        path: '',
        component: GlobalMenuLayoutComponent,
        canActivate: [IsAuthenticatedAndVerifiedGuard],
        children: [
            {
                path: 'change-email',
                loadChildren: () => import('./profile/change-email/change-email.module').then((m) => m.ChangeEmailPageModule),
            },
            {
                path: 'change-password',
                loadChildren: () => import('./profile/change-password/change-password.module').then((m) => m.ChangePasswordPageModule),
            },
            {
                path: 'profile',
                loadChildren: () => import('./profile/profile/profile.module').then((m) => m.ProfilePageModule),
            },
            {
                path: 'edit-home',
                loadChildren: () => import('./edit-home/edit-home.module').then((m) => m.EditHomePageModule),
            },
            {
                path: 'activities',
                loadChildren: () => import('./activities/activities.module').then((m) => m.ActivitiesPageModule),
            },
            {
                path: 'settings',
                loadChildren: () => import('./profile/settings/settings.module').then((m) => m.SettingsPageModule),
            },
            {
                path: 'events',
                loadChildren: () => import('./events/events.module').then((m) => m.EventsPageModule),
            },

            {
                path: '',
                component: SplitPaneLayoutComponent,
                children: [
                    {
                        path: 'blog',
                        loadChildren: () => import('./clients/junos/news/blog-posts/blog-posts.module').then((m) => m.BlogPostsPageModule),
                    },
                    {
                        path: 'faq',
                        loadChildren: () => import('./faq/faq.module').then((m) => m.FaqPageModule),
                    },
                    {
                        path: 'my-briefing',
                        loadChildren: () => import('./clients/junos/my-briefing/my-briefing.module').then((m) => m.MyBriefingPageModule),
                    },
                    {
                        path: 'press-releases',
                        loadChildren: () => import('./clients/junos/news/press-releases/press-releases.module').then((m) => m.PressReleasesPageModule),
                    },
                    {
                        path: 'walls',
                        loadChildren: () => import('./wall/wall.module').then((m) => m.WallPageModule),
                    },
                    {
                        path: '',
                        redirectTo: 'my-briefing',
                        pathMatch: 'full',
                    },
                ],
            },
            {
                path: 'notifications',
                loadChildren: () => import('./notifications/notification-list/notification-list.module').then((m) => m.NotificationListPageModule),
            },
        ],
    },
    {
        path: '**',
        redirectTo: '/',
    },
];
