import { Directive, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
    selector: '[sameAs]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => SameAsDirective),
            multi: true,
        },
    ],
})
export class SameAsDirective implements OnChanges, Validator {

    @Input('sameAs') other;

    private onValidatorChange = () => {};

    ngOnChanges(changes: SimpleChanges): void {
        this.onValidatorChange();
    }

    registerOnValidatorChange(fn: () => void): void {
        this.onValidatorChange = fn;
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (control.value !== this.other) {
            return { sameAs: true };
        }

        return null;
    }

}
