export class AssertionError extends Error {
    constructor(public readonly message: string) {
        super(message);
    }
}

export function assert(condition: any, message?: string): asserts condition {
    if (!condition) {
        throw new AssertionError(message || 'The condition failed.');
    }
}

export function createUnexpectedValueTypeError(value: any, expectedType: string): Error {
    const valueType = null === value
        ? 'null'
        : typeof value;

    return new Error(`Expected "${expectedType}". Got "${valueType}".`);
}

export function isString(value: any): value is string {
    return 'string' === typeof value;
}

export function assertNotNullOrUndefined<T>(value: T): asserts value is NonNullable<T> {
    assert(value !== undefined && value !== null, 'Must not be a nullable value');
}

export function assertIsString(value: any): asserts value is string {
    if (isString(value)) {
        return;
    }

    throw createUnexpectedValueTypeError(value, 'string');
}

export function assertIsNonEmptyString(value: any): asserts value is string {
    assertIsString(value);

    assert('' !== value.trim(), 'Expected a non empty string.');
}

export function isObject(value: unknown): value is Record<string, unknown> {
    return value !== null && 'object' === typeof value;
}
