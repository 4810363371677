import * as moment from 'moment';

export enum FilterPeriod {
    ALL = -1,
    TODAY,
    THIS_WEEK,
    NEXT_WEEK,
    THIS_MONTH,
    NEXT_MONTH
}

export const FILTER_PERIOD_LABELS: Record<FilterPeriod, string> = {
    [FilterPeriod.ALL]: 'Alle',
    [FilterPeriod.TODAY]: 'Heute',
    [FilterPeriod.THIS_WEEK]: 'Diese Woche',
    [FilterPeriod.NEXT_WEEK]: 'Nächste Woche',
    [FilterPeriod.THIS_MONTH]: 'Dieser Monat',
    [FilterPeriod.NEXT_MONTH]: 'Nächster Monat',
};

export function getPeriodStart(period: FilterPeriod): Date {
    const startDate = moment();

    if (FilterPeriod.NEXT_WEEK === period) {
        startDate
            .add(1, 'week')
            .startOf('week');
    } else if (FilterPeriod.NEXT_MONTH === period) {
        startDate
            .add(1, 'month')
            .startOf('month');
    }

    return startDate.toDate();
}

export function getPeriodEnd(period: FilterPeriod): Date {
    const endDate = moment();

    if (FilterPeriod.THIS_WEEK === period) {
        endDate.endOf('week');
    } else if (FilterPeriod.NEXT_WEEK === period) {
        endDate
            .add(1, 'week')
            .endOf('week');
    } else if (FilterPeriod.THIS_MONTH === period) {
        endDate.endOf('month');
    } else if (FilterPeriod.NEXT_MONTH === period) {
        endDate
            .add(1, 'month')
            .endOf('month');
    }

    return endDate.toDate();
}
