<footer>
    <section *platform="'desktop'" class="info">
        <div class="copyright">© {{currentYear}} Webmozarts GmbH</div>
        <p class="version">Version: {{ version }}</p>
    </section>

    <section class="webmozarts">
        <a href="https://webmozarts.com" target="_blank" rel="noopener">
            Entwickelt von
            <img class="logo" src="assets/img/webmozarts-logo.svg" alt="Webmozarts Logo"/>
            <img class="text" src="assets/img/webmozarts-text.svg" alt="Webmozarts"/>
        </a>
    </section>

    <section *platform="'desktop'" class="links">
        <a routerLink="/about" routerDirection="forward"> Über </a>
        <a [href]="privacyTermsUrl | addLinkTracking" rel="noopener" target="_blank">
            Datenschutz </a>
        <a routerLink="/terms-of-use" routerDirection="forward">
            Nutzungsbedingungen </a>
        <a routerLink="/imprint" routerDirection="forward"> Impressum </a>
    </section>
</footer>
