import { ActivityImage } from '@app/shared/models/activity-image.model';
import { ActivityType } from '@app/shared/models/activity-type.model';
import { Entity } from '@app/shared/models/entity.model';
import { Place } from '@app/shared/models/place.model';
import { User } from '@app/shared/models/user.model';
import { dateToString } from '@app/shared/transformers/dateToString';
import { Transform, Type } from 'class-transformer';

export class Activity {

    id: string;

    title: string;

    text: string;

    @Type(() => ActivityImage)
    image: ActivityImage;

    @Type(() => Place)
    place: Place;

    @Type(() => ActivityType)
    activityType: ActivityType;

    numberOfVolunteers: number;

    minimumNumberOfVolunteers: number;

    get hasMinimumNumberOfVolunteers(): boolean {
        return this.minimumNumberOfVolunteers > 0.001;
    }

    @Type(() => Entity)
    entity: Entity;

    canceled: boolean;

    featured: boolean;

    @Type(() => User)
    personInCharge: User;

    @Transform(dateToString)
    startTime: Date;

    @Transform(dateToString)
    endTime: Date;

    distanceInKm: number;

    numberOfRegistrations: number;

}
