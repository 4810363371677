import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-buttons',
    templateUrl: './buttons.component.html',
    styleUrls: ['./buttons.component.scss'],
})
export class ButtonsComponent implements OnInit {

    @HostBinding('class.dark-background') @Input() darkBackground = false;

    constructor() {
    }

    ngOnInit() {
    }

}
