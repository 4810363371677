export class ActivityType {

    id: string;

    name: string;

    image: string;

    numberOfActivities: number;

}
