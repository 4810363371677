import { Observable, Subscriber } from 'rxjs';

export function pauseWhile<T>(condition: Observable<boolean>) {
    return (input: Observable<T>) => pauseWhileImpl(input, condition);
}

function pauseWhileImpl<T>(source: Observable<T>, condition: Observable<boolean>): Observable<T> {
    return new Observable<T>((subscriber: Subscriber<T>) => {
        let currentlyPaused = true;
        let publishOnResume = false;
        let valueToPublish: T | undefined;

        const sourceSubscription = source.subscribe(
            (value) => {
                if (currentlyPaused) {
                    publishOnResume = true;
                    valueToPublish = value;
                } else {
                    subscriber.next(value);
                }
            },
            (error) => subscriber.error(error),
            () => subscriber.complete(),
        );

        const conditionSubscription = condition.subscribe(
            (nextPaused) => {
                if (currentlyPaused && !nextPaused && publishOnResume) {
                    subscriber.next(valueToPublish);
                    publishOnResume = false;
                }

                currentlyPaused = nextPaused;
            },
            (error) => subscriber.error(error),
            () => subscriber.complete(),
        );

        return () => {
            sourceSubscription.unsubscribe();
            conditionSubscription.unsubscribe();
        };
    });
}
