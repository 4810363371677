import { Injectable } from '@angular/core';
import { AuthenticationService } from '@app/core/services/authentication/authentication.service';
import {
    LegacyDeviceNotificationsEnabledSettingService,
} from '@app/core/services/settings/legacy-device-notifications-enabled-setting.service';
import { User } from 'firebase/auth';
import { from, MonoTypeOperatorFunction, of } from 'rxjs';
import { mapTo, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class SettingsService {

    constructor(
        private legacyDeviceNotificationsEnabledSettingService: LegacyDeviceNotificationsEnabledSettingService,
        private authenticationService: AuthenticationService,
    ) {
    }

    initialize(): void {
        this.authenticationService.getViewer()
            .pipe(
                this.migrateLegacyNotificationSettings(),
            )
            .subscribe();
    }

    private migrateLegacyNotificationSettings(): MonoTypeOperatorFunction<User | null> {
        return switchMap((viewer) => null !== viewer
            ? from(this.legacyDeviceNotificationsEnabledSettingService.migrateViewerSettings(viewer.uid)).pipe(mapTo(viewer))
            : of(viewer));
    }
}
