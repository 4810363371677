import { Component } from '@angular/core';

@Component({
    selector: 'app-split-pane-layout',
    templateUrl: './split-pane-layout.component.html',
    styleUrls: ['./split-pane-layout.component.scss'],
})
export class SplitPaneLayoutComponent {

}
