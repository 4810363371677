import { Directive, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
    selector: '[invalid]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => InvalidDirective),
            multi: true,
        },
    ],
})
export class InvalidDirective implements OnChanges, Validator {

    @Input() invalid: boolean;

    private onValidatorChange = () => {};

    ngOnChanges(changes: SimpleChanges): void {
        this.onValidatorChange();
    }

    registerOnValidatorChange(fn: () => void): void {
        this.onValidatorChange = fn;
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (this.invalid) {
            return { invalid: true };
        }

        return null;
    }

}
