import { InjectionToken } from '@angular/core';
import { UserRole } from '@app/shared/models/user-role.model';

export type Hierarchy = { [key in UserRole]?: UserRole[] };

// To keep in sync with crm/config/packages/security.yaml#security.role_hierarchy
export const HIERARCHY: Hierarchy = {
    [UserRole.SUPER_ADMIN]: [UserRole.ADMIN],
    [UserRole.ADMIN]: [
        UserRole.VIP_ADMIN,
        UserRole.ORGANIZATION_ADMIN,
        UserRole.CONTACT_MANAGER,
        UserRole.EVENT_FRONTEND,
        UserRole.FIELD_MANAGER,
        UserRole.COMMUNICATION_MANAGER,
        UserRole.COMMUNITY_MANAGER,
    ],
    [UserRole.CAMBUILDR]: [UserRole.CONTACT_MANAGER],
    [UserRole.CAMPAIGN_MONITOR]: [UserRole.CONTACT_MANAGER],
    [UserRole.HUBSPOT]: [UserRole.CONTACT_MANAGER],
    [UserRole.VIP_ADMIN]: [UserRole.CONTACT_MANAGER],
    [UserRole.ORGANIZATION_ADMIN]: [UserRole.CONTACT_MANAGER],
    [UserRole.CONTACT_MANAGER]: [UserRole.COLLECTOR],
    [UserRole.COLLECTOR]: [UserRole.USER],
};

export const HIERARCHY_TOKEN = new InjectionToken('HIERARCHY_TOKEN');
