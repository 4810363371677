import { ErrorHandler } from '@angular/core';
import { EMPTY, MonoTypeOperatorFunction, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

export function catchAndReportError<T>(errorHandler: ErrorHandler): MonoTypeOperatorFunction<T> {
    return (input: Observable<T>) => input.pipe(
        catchError((error) => {
            errorHandler.handleError(error);

            return EMPTY;
        }),
    );
}
