<ng-container *platform="'mobile'">
    <ion-header>
        <ion-toolbar>
            <ion-buttons slot="start">
                <ion-button (click)="onCancel()"
                            [disabled]="buttonsDisabled">
                    <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
                </ion-button>
            </ion-buttons>
            <ion-title>Passwort erforderlich</ion-title>
            <ion-buttons slot="end">
                <ion-button (click)="form.onSubmit(null)"
                            [disabled]="buttonsDisabled">
                    <ion-icon slot="icon-only" icon="checkmark"></ion-icon>
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>

    <ion-content>
        <ng-container [ngTemplateOutlet]="formView"></ng-container>
    </ion-content>
</ng-container>

<div class="inner-content" *platform="'desktop'">
    <ng-container [ngTemplateOutlet]="formView"></ng-container>
</div>

<ng-template #formView>
    <form ngForm [ngFormOptions]="{ updateOn: 'submit' }" (ngSubmit)="onFormSubmit()">
        <ion-toolbar *platform="'desktop'">
            <ion-title>Passwort erforderlich</ion-title>
        </ion-toolbar>
        <div class="ion-padding">
            <p>Aus Sicherheitsgründen bitten wir dich, dein Passwort erneut einzugeben.</p>

            <ion-item>
                <ion-label position="floating">Passwort</ion-label>

                <ion-input #passwordNgModel="ngModel"
                           [ngModel]="password"
                           (ngModelChange)="onChangePassword($event)"
                           name="password"
                           type="password"
                           clearOnEdit="false"
                           delayedAutoFocus
                           required
                           correctPassword></ion-input>
            </ion-item>

            <app-form-error *ngIf="passwordNgModel.touched && passwordNgModel.errors?.required">
                Bitte gib ein Passwort ein.
            </app-form-error>

            <app-form-error *ngIf="passwordNgModel.touched && passwordNgModel.errors?.invalid">
                Das Passwort ist nicht korrekt.
            </app-form-error>
        </div>
        <app-button-bar *platform="'desktop'">
            <app-buttons slot="end">
                <ion-button fill="clear"
                            color="dark"
                            (click)="onCancel()"
                            [disabled]="buttonsDisabled">Abbrechen</ion-button>

                <ion-button type="submit"
                            fill="outline"
                            color="primary"
                            [disabled]="buttonsDisabled">Bestätigen</ion-button>
            </app-buttons>
        </app-button-bar>
    </form>
</ng-template>
