import { Inject, Injectable } from '@angular/core';
import { AppApiHttpClient } from '@app/core/core.module';
import { SerializingHttpClient } from '@app/core/http/serializing-http-client';
import { AddressDestination } from '@app/shared/models/address-destination.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class ExistsResponse {
    exists = false;
}

@Injectable({
    providedIn: 'root',
})
export class AddressDestinationService {

    constructor(@Inject(AppApiHttpClient) private http: SerializingHttpClient) {
    }

    get(id: string): Observable<AddressDestination> {
        return this.http.get(AddressDestination, `/address-destinations/${id}`);
    }

    findByCountryCodeAndPostalCode(
        countryCode: string,
        postalCode: string,
    ): Observable<AddressDestination[]> {
        return this.http.get(
            AddressDestination,
            `/address-destinations/by/country-code/${countryCode}/postal-code/${postalCode}`,
        );
    }

    existsByCountryCodeAndPostalCode(
        countryCode: string,
        postalCode: string,
    ): Observable<boolean> {
        return this.http.get(
            ExistsResponse,
            `/address-destinations/exists/country-code/${countryCode}/postal-code/${postalCode}`,
        ).pipe(
            map(({ exists }: ExistsResponse) => exists),
        );
    }
}
