<ion-card *ngIf="activity && profile"
          [ngClass]="useMargin ? '' : 'ion-no-margin'"
          (click)="onSelect(activity)">
    <ion-img *ngIf="showImage" src="{{activity.image[imageRatio]}}" [alt]="activity.title"></ion-img>
    <ion-card-header>
        <ion-card-subtitle *ngIf="activity.startTime && activity.endTime">
            {{activity.startTime | periodUntil:activity.endTime}}</ion-card-subtitle>
        <ion-card-title [class.person-in-charge-title]="profile.inChargeOf(activity)">{{activity.title}}</ion-card-title>

        <ion-avatar class="person-in-charge-highlight" *ngIf="profile.inChargeOf(activity)">
            <img [src]="getAvatar(profile.avatar)" [alt]="profile.fullName"/>
        </ion-avatar>
    </ion-card-header>
    <ion-card-content>
        <p>{{activity.place.name}},
            <span class="location">{{activity.place.address.postalCode}} {{activity.place.address.city}}</span></p>
    </ion-card-content>
    <ion-toolbar>
        <!-- mode ios to disable the ripple effect on click -->
        <ion-chip mode="ios" [color]="activity.numberOfRegistrations > 0 ? 'success' : (activity.numberOfVolunteers < activity.minimumNumberOfVolunteers ? 'primary' : 'medium')">
            <ion-icon [name]="activity.numberOfRegistrations > 0 ? 'checkmark' : 'man'"></ion-icon>
            <ion-label>
                <ng-container *ngIf="activity.hasMinimumNumberOfVolunteers; else numberOfVolunteersOnly">
                    {{activity.numberOfVolunteers}}/{{activity.minimumNumberOfVolunteers}}
                </ng-container>

                <ng-template #numberOfVolunteersOnly>
                    {{activity.numberOfVolunteers}}
                </ng-template>
            </ion-label>
        </ion-chip>
        <ion-chip mode="ios" color="medium" class="limited-width">
            <ion-label>{{activity.activityType.name}}</ion-label>
        </ion-chip>
        <ion-buttons slot="end">
            <ion-button (click)="onShareViaPopover(activity, $event)" *platform="['desktop', 'mobileweb']">
                <ion-icon slot="icon-only" name="share-social-outline"></ion-icon>
            </ion-button>
            <ion-button (click)="onShareViaNativeDialog(activity, $event)" *platform="'capacitor'">
                <ion-icon slot="icon-only" name="share-social-outline"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-card>
