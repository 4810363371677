import { NewsItemImage } from '@app/shared/models/news-item-image.model';
import { dateToString } from '@app/shared/transformers/dateToString';
import { Transform } from 'class-transformer';

export class NewsItem {

    id: string;

    title: string;

    author: string | null;

    text: string | null;

    @Transform(dateToString)
    publishedAt: Date;

    image: NewsItemImage;

    url: string;

}
