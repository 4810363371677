<ng-container *ngIf="fileSelected || serverUrl; else add">
    <ion-avatar class="ion-activatable" (click)="onBrowse()">
        <ion-ripple-effect></ion-ripple-effect>
        <img src="{{ fileSelected ? previewUrl : serverUrl}}"/>
        <div class="overlay">
            <ion-icon name="camera"></ion-icon>
        </div>
    </ion-avatar>
</ng-container>

<ng-template #add>
    <ion-avatar>
        <div style="background: grey; color: white; width: 100%; height: 100%;" (click)="onBrowse()">
            <!--<button type="button"-->
                    <!--class="image-input__add"-->
                    <!--[disabled]="disabled"-->
                    <!--(click)="onBrowse()">-->
                <!--<app-icon [icon]="{icon: 'plus', style: 'light'}"></app-icon>-->
            <!--</button>-->
        </div>
    </ion-avatar>
</ng-template>

<input #fileInput
       type="file"
       name="file"
       accept="image/png,image/jpeg"
       style="display: none"
       (focus)="onTouch()"
       (change)="onChange($event)">
