<ion-toolbar>
    <ion-buttons slot="end">
        <ion-button (click)="onShareOnFacebook()" *ngIf="isFacebookIntegrationEnabled">
            <ion-icon name="logo-facebook" slot="icon-only"></ion-icon>
        </ion-button>
        <ion-button (click)="onShareOnTwitter()" *ngIf="isTwitterIntegrationEnabled">
            <ion-icon name="logo-twitter" slot="icon-only"></ion-icon>
        </ion-button>
        <ion-button (click)="onCopyUrlToClipboard()">
            <ion-icon name="link" slot="icon-only"></ion-icon>
        </ion-button>
    </ion-buttons>
</ion-toolbar>
