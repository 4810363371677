<app-spinner *ngIf="loading" class="ion-text-center"></app-spinner>

<ng-container *ngIf="!loading">
    <ng-container *ngTemplateOutlet="hasFetchError ? showReloadOption : showResult">
    </ng-container>
</ng-container>

<ng-template #showReloadOption>
    <p class="ion-text-center ion-padding-top">Beim Laden der FAQ ist ein Fehler aufgetreten.</p>
</ng-template>

<ng-template #showResult>
    <ion-searchbar debounce="250" placeholder="Suche" [(ngModel)]="query" (ionChange)="onFilterList($event)">
    </ion-searchbar>

    <p class="empty-result ion-text-left" *ngIf="items.length === 0">
        Es wurden keine Ergebnisse für <strong>"{{query}}"</strong> gefunden.
    </p>

    <div *ngFor="let item of items; trackBy: trackByFn">
        <app-headline *ngIf="item.displaySection">
            {{item.item.section}}
        </app-headline>

        <app-faq-card>
            <app-faq-question>
                {{item.item.question}}
            </app-faq-question>
            <app-faq-answer-long>
                <div class="answer" [innerHTML]="item.item.answer"></div>
            </app-faq-answer-long>
        </app-faq-card>
    </div>

    <ion-infinite-scroll threshold="100px" [disabled]="!hasNext" (ionInfinite)="onFetchMore($event)">
        <ion-infinite-scroll-content
                loadingText="Weitere FAQs werden geladen...">
        </ion-infinite-scroll-content>
    </ion-infinite-scroll>
</ng-template>
