import { Inject, Injectable } from '@angular/core';
import { AppApiHttpClient } from '@app/core/core.module';
import { SerializingHttpClient } from '@app/core/http/serializing-http-client';
import { EventType } from '@app/shared/models/event-type.model';
import { Cache, FetchMode } from '@app/shared/utils/cache';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class EventTypeService {

    private cache = new Cache<EventType>((eventType) => eventType.id);

    constructor(@Inject(AppApiHttpClient) private http: SerializingHttpClient) {
    }

    getEventTypes(fetchMode: FetchMode = 'cache-first'): Observable<EventType[]> {
        return this.cache.getAll(
            fetchMode,
            () => this.http.get(EventType, '/event-types?activeOnly=1'),
        );
    }

    getEventType(id: string, fetchMode: FetchMode = 'cache-first'): Observable<EventType> {
        return this.cache.get(
            id,
            fetchMode,
            () => this.http.get(EventType, `/event-types/${id}`),
        );
    }

}
