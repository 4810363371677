import { Component, Input } from '@angular/core';
import { EventService } from '@app/core/services/event/event.service';
import { ShareService } from '@app/core/services/share/share.service';
import { SHARE_EVENT_MESSAGE } from '@app/events/event-list/event-list.page';
import { DETAIL_ZOOM_LEVEL } from '@app/shared/components/abstract-map/abstract-map.component';
import { ShareOptionsComponent } from '@app/shared/components/share-options/share-options.component';
import { EventImageSize } from '@app/shared/models/event-image.model';
import { Event as EventModel } from '@app/shared/models/event.model';
import { Profile } from '@app/shared/models/profile.model';
import { NavController, Platform, PopoverController } from '@ionic/angular';

@Component({
    selector: 'app-event-card',
    templateUrl: './event-card.component.html',
    styleUrls: ['./event-card.component.scss'],
})
export class EventCardComponent {

    imageRatio: EventImageSize = window.devicePixelRatio > 1 ? '1000x750' : '500x375';

    @Input('event') event: EventModel;

    @Input('profile') profile: Profile;

    @Input('margin') useMargin = true;

    constructor(private eventService: EventService,
                private platform: Platform,
                private navController: NavController,
                private popoverController: PopoverController,
                private shareService: ShareService) {
    }

    onSelect(event: EventModel): void {
        // Early feedback
        this.eventService.setSelectedEvent(event.id);

        const urlComponents = ['/events/detail', event.id];
        const queryParams = {
            ll: event.place.latitude + ',' + event.place.longitude,
            z: DETAIL_ZOOM_LEVEL,
        };

        if (this.platform.is('desktop')) {
            // On Desktop, use navigateRoot() so that the stack is completely
            // replaced. Otherwise navigating between events on the map
            // confuses Ionic's stack. A proper solution would be to replace
            // the current page on the stack, but there's currently no
            // nicely working solution for that
            this.navController.navigateRoot(urlComponents, {
                queryParams,
                state: { canGoBack: true },
            });
        } else {
            urlComponents.push('mobile');

            this.navController.navigateForward(urlComponents, { queryParams });
        }
    }

    async onShareViaPopover(eventModel: EventModel, event: Event) {
        event.preventDefault();
        event.stopPropagation();

        const popover = await this.popoverController.create({
            component: ShareOptionsComponent,
            componentProps: {
                url: this.eventService.generateEventUrl(eventModel),
                message: SHARE_EVENT_MESSAGE,
            },
            cssClass: 'auto-width',
            event,
            translucent: true,
        });

        popover.present();
    }

    onShareViaNativeDialog(eventModel: EventModel, event: Event): void {
        event.preventDefault();
        event.stopPropagation();

        this.shareService.shareViaNativeDialog(
            this.eventService.generateEventUrl(eventModel),
            SHARE_EVENT_MESSAGE,
        );
    }
}
