import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, forwardRef, Input, ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-image-input',
    templateUrl: './image-input.component.html',
    styleUrls: ['./image-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ImageInputComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageInputComponent implements ControlValueAccessor {

    @Input() disabled = false;

    @ViewChild('fileInput', { static: true }) fileInput: ElementRef;

    value: string | File;

    originalValue: string;

    fileSelected = false;

    serverUrl: string | null;

    previewUrl: string | null;

    loading = false;

    private initialized = false;

    private propagateChange = (value) => {};

    private propagateTouch = () => {};

    constructor(private changeDetector: ChangeDetectorRef) {
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.propagateTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(value: any): void {
        if (undefined !== value) {
            // Ignore the initial NULL sent by Angular to initialize the input
            if (undefined === this.originalValue && this.initialized) {
                this.originalValue = value;
            }

            this.initialized = true;
            this.value = value;
            this.serverUrl = value || null;
            this.changeDetector.markForCheck();
        }
    }

    onBrowse(): void {
        this.fileInput.nativeElement.click();
    }

    onChange(event: Event): void {
        const element = event.target as HTMLInputElement;
        const file = element.files && element.files[0] ? element.files[0] : null;

        if (this.value === file) {
            return;
        }

        if (!file) {
            return;
        }

        this.value = file;
        this.fileSelected = true;
        this.loading = true;

        this.loadPreview(file, (dataUrl) => {
            this.previewUrl = dataUrl;
            this.loading = false;

            this.changeDetector.markForCheck();
        });

        this.propagateChange(this.value);

        this.changeDetector.markForCheck();
    }

    onTouch(): void {
        this.propagateTouch();
    }

    onReset(): void {
        if (this.value === this.originalValue) {
            return;
        }

        this.value = this.originalValue;
        this.fileSelected = false;
        this.previewUrl = null;
        this.serverUrl = this.originalValue || null;
        this.fileInput.nativeElement.params = '';

        this.propagateChange(this.value);

        this.changeDetector.markForCheck();
    }

    private loadPreview(file: File, callback: (dataUrl: string) => void) {
        const reader = new FileReader();

        reader.onload = (event: any) => callback(event.target.result);

        reader.readAsDataURL(file);
    }

}
