import { Directive, forwardRef, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
    selector: 'ion-input[type="number"][max]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => MaxDirective),
            multi: true,
        },
    ],
})
export class MaxDirective implements Validator {

    @Input('max') limit: number;

    validate(control: AbstractControl): ValidationErrors | null {
        if (null !== control.value && undefined !== control.value && control.value > this.limit) {
            return { tooHigh: true };
        }

        return null;
    }

}
