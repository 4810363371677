import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { wrapError } from '@app/core/services/error-handler/error-wrapper';
import {
    HasTargetUrl, MessagingPlatform, NotificationPermission,
} from '@app/core/services/messaging/messaging-platform';
import { isString } from '@app/shared/utils/typeGuards';
import { PermissionState } from '@capacitor/core';
import {
    ActionPerformed, PushNotifications, PushNotificationSchema, Token,
} from '@capacitor/push-notifications';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';

function mapPermissionStateToNotificationPermission(state: PermissionState): NotificationPermission {
    return 'granted' === state ? 'granted' : 'denied';
}

type ActionPerformedWithTargetUrl = HasTargetUrl&ActionPerformed;

function addTargetUrlPropertyIfSet(action: ActionPerformed): ActionPerformed|ActionPerformedWithTargetUrl {
    const targetUrl = action.notification.data?.targetUrl;

    if (!isString(targetUrl)) {
        return action;
    }

    const trimmedTargetUrl = targetUrl.trim();

    return '' === trimmedTargetUrl
        ? action
        : { ...action, targetUrl: trimmedTargetUrl };
}

@Injectable({
    providedIn: 'root',
})
export class CapacitorFirebaseMessaging implements MessagingPlatform {

    private currentMessageSubject = new BehaviorSubject<PushNotificationSchema>(null);

    private notificationClickSubject = new Subject<ActionPerformed|ActionPerformedWithTargetUrl>();

    private tokenSubject = new BehaviorSubject<string | null>(null);

    private isRegisteredSubject = new BehaviorSubject<boolean>(false);

    constructor(
        private ngZone: NgZone,
        private errorHandler: ErrorHandler,
    ) {
    }

    getCurrentMessage(): Observable<PushNotificationSchema> {
        return from(this.currentMessageSubject);
    }

    getNotificationClick(): Observable<HasTargetUrl|unknown> {
        return from(this.notificationClickSubject);
    }

    async requestPermission(): Promise<NotificationPermission> {
        try {
            // Request permission to use push notifications.
            // iOS will prompt user and return if they granted permission or not.
            // Android will just grant without prompting.
            let permissionStatus = await PushNotifications.checkPermissions();

            if ('prompt' === permissionStatus.receive) {
                permissionStatus = await PushNotifications.requestPermissions();
            }

            return mapPermissionStateToNotificationPermission(permissionStatus.receive);
        } catch (error) {
            return 'denied';
        }
    }

    getToken(): Observable<string|null> {
        return this.tokenSubject.asObservable();
    }

    async register(): Promise<boolean> {
        await this.registerListeners();

        try {
            await PushNotifications.register();
            this.isRegisteredSubject.next(true);

            return true;
        } catch (error) {
            await this.handleRegistrationFailure(error);

            return false;
        }
    }

    async unregister(): Promise<boolean> {
        try {
            await Promise.all([
                PushNotifications.unregister(),
                PushNotifications.removeAllListeners(),
            ]);
        } catch (error) {
            this.handleUnregisterFailure(error);
        }

        this.isRegisteredSubject.next(false);

        // The token itself is not deleted in the App/SDK cache neither invalidated immediately to Firebase
        // (it has a certain lifetime before being invalidated).
        // However, in terms of app usage, it equates for us to no longer have a token, e.g. we do not
        // want to send push notifications to the device if the firebase platform is not enabled (it will
        // not necessarily hurt, but it will help to have a more accurate number of delivered notifications).
        this.tokenSubject.next(null);

        return true;
    }

    private registerListeners(): Promise<unknown> {
        return Promise.all([
            PushNotifications.addListener(
                'pushNotificationReceived',
                (notification: PushNotificationSchema) => this.ngZone.run(() => this.currentMessageSubject.next(notification)),
            ),
            PushNotifications.addListener(
                'pushNotificationActionPerformed',
                (action: ActionPerformed) => this.ngZone.run(() => this.notificationClickSubject.next(
                    addTargetUrlPropertyIfSet(action),
                )),
            ),
            PushNotifications.addListener(
                'registration',
                (token: Token) => this.tokenSubject.next(token.value),
            ),
            PushNotifications.addListener(
                'registrationError',
                (error) => {
                    this.tokenSubject.next(null);
                    this.errorHandler.handleError(
                        wrapError(
                            'Could not register the application for push notifications.',
                            'CapacitorFirebaseRegistrationFailure',
                            error.error,
                        ),
                    );
                },
            ),
        ]);
    }

    private async handleRegistrationFailure(error: unknown): Promise<void> {
        this.errorHandler.handleError(
            wrapError(
                'PushNotificationSetup',
                'Could not register the push notification listeners or register the app for push notifications.',
                error,
            ),
        );

        // Clean up the listeners as otherwise we might end up with listeners registered twice.
        await PushNotifications.removeAllListeners();
        this.isRegisteredSubject.next(false);
    }

    private handleUnregisterFailure(error: unknown): void {
        this.errorHandler.handleError(
            wrapError(
                'PushNotificationTearDown',
                'Could not remove the push notification listeners or unregister the app for push notifications.',
                error,
            ),
        );
    }
}
