import { Injectable } from '@angular/core';
import { Logger } from '@app/core/services/logger/logger';

@Injectable()
export class ConsoleLogger implements Logger {

    log(message: string, ...args): void {
        console.log(message, ...args);
    }

}
