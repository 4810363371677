import { Component, ErrorHandler } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core/services/authentication/authentication.service';
import { MessagingService } from '@app/core/services/messaging/messaging.service';
import { SentryScopeSetter } from '@app/core/services/sentry-scope-setter.service';
import { SettingsService } from '@app/core/services/settings/settings.service';
import { environment } from '@app/environments/environment';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular-ivy';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { wrapError } from "@app/core/services/error-handler/error-wrapper";
import { hasTargetUrl } from "@app/core/services/messaging/messaging-platform";
import { URLOpenListenerEvent } from '@capacitor/app';
import { AppLifecycleService } from "@app/core/services/app-lifecycle/app-lifecycle.service";
import { EMPTY, from, Observable, OperatorFunction } from 'rxjs';

function mapDeepLinkUrlToPath(): OperatorFunction<URLOpenListenerEvent, string> {
    return map((event) => {
        // Example url: https://beerswift.app/tabs/tab2?foo=bar
        const url = new URL(event.url);

        // returned value = /tabs/tab2?foo=bar
        return url.href.substring(url.origin.length);
    });
}

function logDeepLinkClick(path: string): void {
    Sentry.addBreadcrumb({
        message: 'Deep link clicked',
        data: { link: path },
    });
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {

    constructor(
        private platform: Platform,
        private authenticationService: AuthenticationService,
        private settingsService: SettingsService,
        private messagingService: MessagingService,
        private router: Router,
        private translateService: TranslateService,
        private sentryScopeSetter: SentryScopeSetter,
        private errorHandler: ErrorHandler,
        private appLifecycleService: AppLifecycleService,
    ) {
        this.initializeApp();
    }

    initializeApp() {
        this.platform.ready().then(() => {
            if (this.platform.is('capacitor')) {
                if (this.platform.is('android')) {
                    // Do not display content under status bar (currently supports Android only)
                    StatusBar.setOverlaysWebView({ overlay: false });
                    StatusBar.setStyle({ style: Style.Light });
                } else {
                    StatusBar.setStyle({ style: Style.Default });
                }

                SplashScreen.hide();
            }

            this.handleDeepLinks();
            this.handleNotificationClicks();
            this.addHtmlClasses();

            // set default translation
            this.translateService.use(environment.language);
        });

        this.appLifecycleService.initialize();
        this.authenticationService.initialize();
        this.settingsService.initialize();
        this.sentryScopeSetter.initialize();
    }

    handleDeepLinks(): void {
        this.appLifecycleService.urlOpen
            .pipe(
                mapDeepLinkUrlToPath(),
                tap(logDeepLinkClick),
                switchMap((path) => from(this.router.navigateByUrl(path))
                    .pipe(
                        map((navigationSucceeded: boolean|null) => ({
                            path,
                            navigationSucceeded,
                        })),
                    )),
                switchMap(({ navigationSucceeded, path }) => false === navigationSucceeded
                    ? this.handleDeepLinkNavigationFailure(path)
                    : EMPTY),
            )
            .subscribe();
    }

    handleNotificationClicks(): void {
        if (!environment.notificationsEnabled) {
            return;
        }

        this.messagingService
            .getNotificationClick()
            .subscribe(
                (payload) => {
                    if (hasTargetUrl(payload)) {
                        // The targetUrl parameter is defined in SyncApi in the backend
                        const targetUrl = new URL(payload.targetUrl);
                        this.router.navigateByUrl(targetUrl.pathname);
                    } else {
                        // Do nothing: the default behaviour will be to open back the app
                        // to the last page visited.
                    }
                },
                (error) => this.errorHandler.handleError(
                    wrapError(
                        'InvalidNotificationClick',
                        'Could not process the notification click.',
                        error,
                    ),
                ),
            );
    }

    private addHtmlClasses(): void {
        // We use a webmozarts prefix for our custom platform classes
        const customHtmlClass = this.platform.is('mobile') ? 'wm-plt-mobile' : 'wm-plt-desktop';

        document.documentElement.classList.add(customHtmlClass);
    }

    private handleDeepLinkNavigationFailure(path: string): Observable<unknown> {
        return this.authenticationService
            .isAuthenticatedAndEmailVerified()
            .pipe(
                first(),
                tap((isAuthenticated) => {
                    // Log only if authenticated. Otherwise the navigation most probably failed
                    // because the given route is not accessible.
                    if (isAuthenticated) {
                        this.errorHandler.handleError(
                            wrapError(
                                'MissingDeepLink',
                                `Could not handle deep link "${path}".`,
                            ),
                        );
                    }
                }),
            );
    }
}
