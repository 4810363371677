// TODO: refactor to an enum like for the Avatar
export type EventImageSize = '380x285' | '500x375' | '760x570' | '1000x750';

export class EventImage {

    readonly '380x285': string;

    readonly '500x375': string;

    readonly '760x570': string;

    readonly '1000x750': string;

}
