import { Injectable, Optional } from '@angular/core';
import { ActivityFiltersService } from '@app/core/services/activity-filters/activity-filters.service';
import { FilterChangeSource } from '@app/core/services/filters/filters.service';
import { InfoService } from '@app/core/services/info/info.service';
import { Logger } from '@app/core/services/logger/logger';
import { CordovaGeolocation } from '@app/core/services/user-location/geolocation/cordova-geolocation';
import { NavigatorGeolocation } from '@app/core/services/user-location/geolocation/navigator-geolocation';
import { Focus } from '@app/shared/models/geo-center.model';
import { Platform } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class UserLocationService {

    readonly userLocation: Observable<GeolocationPosition>;

    private followSubscription: Subscription | null = null;

    constructor(private activityFiltersService: ActivityFiltersService,
                private infoService: InfoService,
                private logger: Logger,
                platform: Platform,
                navigatorGeolocation: NavigatorGeolocation,
                @Optional() cordovaGeolocation: CordovaGeolocation) {
        this.userLocation = platform.is('capacitor') && cordovaGeolocation
            ? cordovaGeolocation.watchPosition()
            : navigatorGeolocation.watchPosition();
    }

    moveToUserLocation() {
        if (this.followSubscription) {
            return;
        }

        this.logger.log('START FOLLOWING USER LOCATION');

        this.followSubscription = this.userLocation
            .pipe(tap((position) => this.logger.log('GOT POSITION', position)))
            .subscribe(
                (position) => this.activityFiltersService.changeFilters({
                    filters: {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                        focus: Focus.USER_LOCATION,
                    },
                    source: FilterChangeSource.LOCATION_CHANGE,
                }),
                () => this.infoService.error('Bitte akzeptiere den Zugriff auf deine Position, um dieses Feature zu nutzen'),
            );
    }

    stopFollowingUserLocation() {
        if (!this.followSubscription) {
            return;
        }

        this.logger.log('STOP FOLLOWING USER LOCATION');

        this.followSubscription.unsubscribe();

        this.logger.log('STOPPED');

        this.followSubscription = null;
    }

}
