import { Location } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppApiHttpClient } from '@app/core/core.module';
import { SerializingHttpClient } from '@app/core/http/serializing-http-client';
import { IdentityMap } from '@app/core/services/identity-map';
import { environment } from '@app/environments/environment';
import { EventFilters } from '@app/shared/models/event-filters.model';
import { EventRegistration } from '@app/shared/models/event-registration.model';
import { Event } from '@app/shared/models/event.model';
import { getPeriodEnd, getPeriodStart } from '@app/shared/models/filter-period.model';
import { GeoPoint } from '@app/shared/models/geo-point.model';
import { Page } from '@app/shared/models/page.model';
import * as moment from 'moment';
import { from, Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class EventService {

    private selectedEventSource = new ReplaySubject<string>(1);

    private selectedEvent = from(this.selectedEventSource).pipe(distinctUntilChanged());

    private identityMap = new IdentityMap<Event>();

    private fields = [
        'id',
        'title',
        'image',
        'place',
        'eventType',
        'startTime',
        'endTime',
        'numberOfAttendees',
        'maximumNumberOfAttendees',
        'entity',
        'registration',
        'registrationOpen',
        'distanceInKm',
        'featured',
    ];

    private fieldsIncludingNestedFields = [
        ...this.fields,
        // Fields of non-resources need to be selected explicitly
        // Thanks API platform :(
        // Entity
        'name',
        // EventRegistration
        'numberOfGuests',
        'notes',
        'source',
    ];

    constructor(@Inject(AppApiHttpClient) private http: SerializingHttpClient,
                private router: Router,
                private location: Location) {
    }

    getEvents(filters: EventFilters, radiusProperty: string, page = 1): Observable<Page<Event>> {
        let path = '/events';

        path = this.addFiltersToPath(filters, path, radiusProperty);

        path += `&page=${page}&fields=${this.fieldsIncludingNestedFields.join(',')}`;

        return this.http
            .get(Page, path, {
                headers: {
                    Accept: 'application/ld+json',
                },
            })
            .pipe(
                tap((result: Page<Event>) => result.items = result.items.map(
                    (event) => this.identityMap.merge(event, this.fields),
                )),
            );
    }

    getNextRegisteredEvent(): Observable<Event> {
        let path = '/events';
        path += `?onlyRegistered=1&pageSize=1&fields${this.fieldsIncludingNestedFields.join(',')}`;

        return this.http
            .get(Page, path, {
                headers: {
                    Accept: 'application/ld+json',
                },
            })
            .pipe(
                tap((result: Page<Event>) => result.items = result.items.map(
                    (event) => this.identityMap.merge(event, this.fields),
                )),
                map((result: Page<Event>) => result.items[0] || null),
            );
    }

    getEvent(id: string): Observable<Event> {
        return this.http.get(Event, `/events/${id}`)
            .pipe(map((event: Event) => this.identityMap.merge(event)));
    }

    getEventGeoPoints(filters: EventFilters, radiusProperty: string): Observable<GeoPoint[]> {
        let path = '/events/geo-points';

        path = this.addFiltersToPath(filters, path, radiusProperty);

        return this.http.get(GeoPoint, path);
    }

    registerForEvent(id: string, registration: EventRegistration): Observable<EventRegistration> {
        const input = {
            numberOfGuests: registration.numberOfGuests,
            notes: registration.notes,
        };

        return this.http.post(EventRegistration, `/events/${id}/registration`, input)
            .pipe(tap((updatedRegistration: EventRegistration) => {
                if (this.identityMap.contains(id)) {
                    this.identityMap.get(id).registration = updatedRegistration;
                }
            }));
    }

    changeEventRegistration(id: string, registration: EventRegistration): Observable<EventRegistration> {
        const input = {
            numberOfGuests: registration.numberOfGuests,
            notes: registration.notes,
        };

        return this.http.put(EventRegistration, `/events/${id}/registration`, input)
            .pipe(tap((updatedRegistration: EventRegistration) => {
                if (this.identityMap.contains(id)) {
                    this.identityMap.get(id).registration = updatedRegistration;
                }
            }));
    }

    unregisterFromEvent(id: string): Observable<void> {
        return this.http.delete(EventRegistration, `/events/${id}/registration`);
    }

    setSelectedEvent(id: string): void {
        this.selectedEventSource.next(id);
    }

    getSelectedEvent(): Observable<string> {
        return this.selectedEvent;
    }

    generateEventUrl(event: Event): string {
        const path = this.router.createUrlTree(['/events/detail', event.id]);

        return environment.appUrl + this.location.prepareExternalUrl(path.toString());
    }

    private addFiltersToPath(filters: EventFilters, path: string, radiusProperty: string) {
        path += `?longitude=${filters.longitude}&latitude=${filters.latitude}&maxDistanceInKm=${filters[radiusProperty]}`;

        if (filters.onlyRegistered) {
            path += '&onlyRegistered=1';
        }

        if (null !== filters.period) {
            const startDate = moment(getPeriodStart(filters.period)).format('YYYY-MM-DD');
            const endDate = moment(getPeriodEnd(filters.period)).format('YYYY-MM-DD');

            path += `&from=${startDate}&until=${endDate}`;
        }

        if (null !== filters.eventTypeId) {
            path += `&eventTypeId=${filters.eventTypeId}`;
        }

        if (null !== filters.entityId) {
            path += `&entityId=${filters.entityId}`;
        }

        if (null !== filters.region) {
            path += `&regionId=${filters.region}`;
        }

        if (null !== filters.postalCode) {
            path += `&postalCode=${filters.postalCode}`;
        }

        return path;
    }
}
