import { Injectable, OnDestroy } from '@angular/core';
import { AuthenticationService } from '@app/core/services/authentication/authentication.service';
import { FilterStorageService } from '@app/core/services/filters/filter-storage.service';
import { Logger } from '@app/core/services/logger/logger';
import { ProfileService } from '@app/core/services/profile/profile.service';
import { environment } from '@app/environments/environment';
import { Filters, PartialFilters } from '@app/shared/models/filters.model';
import { Focus } from '@app/shared/models/geo-center.model';
import { filterObject } from '@app/shared/utils/filterObject';
import {
    from, MonoTypeOperatorFunction, Observable, OperatorFunction, ReplaySubject, Subject,
} from 'rxjs';
import {
    distinctUntilChanged, filter, first, map, scan, startWith, switchMap, take, takeUntil, tap,
} from 'rxjs/operators';

export enum FilterChangeSource {
    MAP_UPDATE,
    FILTER_CHANGE,
    LOCATION_CHANGE
}

export interface PartialFilterChange<P extends PartialFilters> {
    filters: P;

    source: FilterChangeSource;
}

export interface FilterChange<F extends Filters> extends PartialFilterChange<F> {
    filters: F;

    source: FilterChangeSource;
}

@Injectable()
export abstract class FiltersService<P extends PartialFilters, F extends Filters> implements OnDestroy {

    public static readonly HOME_ZOOM_LEVEL = 11;

    public static readonly INITIAL_ZOOM_LEVEL = environment.defaultZoomLevel;

    private filterChangeSource = new ReplaySubject<PartialFilterChange<P>>(1);

    private filterChanges = new ReplaySubject<FilterChange<F>>(1);

    private ngUnsubscribe = new Subject<void>();

    private initialFilterChange = new ReplaySubject<FilterChange<F>>(1);

    protected constructor(
        authenticationService: AuthenticationService,
        protected profileService: ProfileService,
        logger: Logger,
        protected filterStorageService: FilterStorageService,
        private filterKey: string,
    ) {
        this.createInitialFilterChange()
            .pipe(
                this.mergeWithIncomingFilterChanges(),
                this.mergePreviousAndNextFilterChange(),
                tap((...args) => logger.log('FILTER CHANGE START', ...args)),
                distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
                tap((...args) => logger.log('FILTER CHANGE', ...args)),
                this.saveFilters(),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe((filterChange) => this.filterChanges.next(filterChange));

        authenticationService.isAuthenticated()
            .pipe(
                filter((isAuthenticated) => false === isAuthenticated),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe(() => this.resetFilters());
    }

    private createInitialFilterChange(): Observable<FilterChange<F>> {
        return this.createInitialFilters()
            .pipe(
                this.includeProfilePositionInInitialFilterChange(),
                // Don't reset to the saved filters, but to the "empty" ones including
                // the position from the profile
                tap((filterChange) => this.initialFilterChange.next(filterChange)),
                this.includeSavedFiltersInInitialFilterChange(),
            );
    }

    private mergeWithIncomingFilterChanges(): OperatorFunction<FilterChange<F>, FilterChange<F> | PartialFilterChange<P>> {
        return switchMap((initialFilterChange) =>
            this.filterChangeSource
                .pipe(startWith(initialFilterChange)));
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    getFilters(): Observable<F> {
        return from(this.filterChanges).pipe(map((filterChange) => filterChange.filters));
    }

    getFilterChanges(): Observable<FilterChange<F>> {
        return from(this.filterChanges);
    }

    changeFilters(filterChange: PartialFilterChange<P>): void {
        this.filterChangeSource.next(filterChange);
    }

    resetFilters(): void {
        this.initialFilterChange
            .pipe(take(1),
                map((filterChange) => {
                    const initialFilters = filterChange.filters;
                    const filteredFilters = this.removeFiltersThatTriggerRouteNavigationEvents(initialFilters);

                    return { filters: filteredFilters, source: filterChange.source };
                }))
            .subscribe((filterChange) => this.filterChangeSource.next(filterChange));
    }

    private removeFiltersThatTriggerRouteNavigationEvents(initialFilters): P {
        const filterCallback = (value, key) =>
            key !== 'longitude' && key !== 'latitude' && key !== 'focus';

        return filterObject(initialFilters, filterCallback) as P;
    }

    abstract getNumberOfActiveFilters(filters: F): number;

    protected abstract createInitialFilters(): Observable<F>;

    private mergePreviousAndNextFilterChange(): OperatorFunction<PartialFilterChange<P> | FilterChange<F>, FilterChange<F>> {
        return scan(
            (previous, next) => ({
                filters: { ...previous.filters, ...next.filters },
                source: next.source,
            }),
            {
                filters: {},
                source: FilterChangeSource.FILTER_CHANGE,
            } as FilterChange<F>,
        );
    }

    private includeProfilePositionInInitialFilterChange(): OperatorFunction<F, FilterChange<F>> {
        return switchMap(
            (initialFilters) => this.profileService.getProfile('cache-only')
                .pipe(
                    first(),
                    map(
                        (profile) => ({
                            filters: {
                                ...initialFilters,
                                latitude: profile.latitude,
                                longitude: profile.longitude,
                                focus: Focus.HOME,
                            },
                            source: FilterChangeSource.FILTER_CHANGE,
                        } as FilterChange<F>),
                    ),
                ),
        );
    }

    private saveFilters(): MonoTypeOperatorFunction<FilterChange<F>> {
        return switchMap((filterChange) =>
            this.filterStorageService.saveFilters(this.filterKey, filterChange.filters)
                .pipe(map(() => filterChange)));
    }

    private includeSavedFiltersInInitialFilterChange(): MonoTypeOperatorFunction<FilterChange<F>> {
        return switchMap((initialFilters) =>
            this.filterStorageService.getFilters(this.filterKey)
                .pipe(
                    map((savedFilters) => {
                        return {
                            filters: { ...initialFilters.filters, ...savedFilters },
                            source: initialFilters.source,
                        } as FilterChange<F>;
                    }),
                ));
    }
}
