export function queryShadowChild(shadowRoot: ShadowRoot, selector: string): Promise<Element> {
    return new Promise((resolve, reject) => {
        if (!shadowRoot) {
            // PhantomJS does not create a shadow root element (?)
            return;
        }

        const element = shadowRoot.querySelector(selector);

        if (!element) {
            // If the tab is not currently visible on the screen, wait for
            // it to become visible
            if (document.hidden) {
                const listener = () => {
                    if (!document.hidden) {
                        document.removeEventListener('visibilitychange', listener);

                        queryShadowChild(shadowRoot, selector)
                            .then((el) => resolve(el))
                            .catch((error) => reject(error));
                    }
                };

                // As long as the browser window is not visible on the screen,
                // the shadow root is not actually rendered
                document.addEventListener('visibilitychange', listener);

                return;
            }

            // Periodically try to find the element
            setTimeout(
                () => queryShadowChild(shadowRoot, selector)
                    .then((el) => resolve(el))
                    .catch((error) => reject(error)),
                500,
            );

            return;
        }

        // We want to display the left sidebar without covering the map,
        // i.e. height based on its content.
        // We can do this mainly through CSS, but there's no way to access
        // the .inner-scroll in the <ion-content>, hence we need this
        // little hack
        resolve(element);
    });
}
