<ion-card (click)="onToggleCollapsed($event)" class="ion-activatable">
    <ion-ripple-effect></ion-ripple-effect>
    <ng-content></ng-content>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-button color="medium" fill="clear" class="expand" *ngIf="collapsed">
                <ion-icon name="caret-forward" slot="start"></ion-icon>
                Antwort anzeigen
            </ion-button>
            <ion-button color="dark" fill="clear" *ngIf="!collapsed">
                <ion-icon name="caret-up" slot="start"></ion-icon>
                Antwort verbergen
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-card>
