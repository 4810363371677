import { TransformFnParams } from 'class-transformer';

export function enumToString(type) {
    return ({ value }: TransformFnParams) => {
        if (null === value || undefined === value) {
            return value;
        }

        return type[value];
    };
}
