import { Directive, forwardRef, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
    selector: 'ion-textarea[maxlength]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => MaxLengthDirective),
            multi: true,
        },
    ],
})
export class MaxLengthDirective implements Validator {

    @Input('maxlength') limit: number;

    validate(control: AbstractControl): ValidationErrors | null {
        if ('string' === typeof control.value && control.value.length > this.limit) {
            return { tooLong: true };
        }

        return null;
    }

}
