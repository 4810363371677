import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Platform } from "@ionic/angular";
import { App, URLOpenListenerEvent } from '@capacitor/app';

/**
 * Bridge between the CapacitorApp.
 */
@Injectable({
    providedIn: 'root',
})
export class AppLifecycleService {
    private readonly urlOpenSubject = new Subject<URLOpenListenerEvent>();

    private readonly resumeSubject = new Subject<void>();

    readonly urlOpen = this.urlOpenSubject.asObservable();

    readonly resume = this.resumeSubject.asObservable();

    private initialized = false;

    constructor(
        private platform: Platform,
    ) {
    }

    initialize(): void {
        if (this.initialized) {
            return;
        }

        if (!this.platform.is('capacitor')) {
            return;
        }

        App.addListener(
            'appUrlOpen',
            (event) => this.urlOpenSubject.next(event),
        );
        App.addListener(
            'resume',
            () => this.resumeSubject.next(),
        );

        this.initialized = true;
    }
}
