import { hasOwnProperty } from '@app/shared/utils/has-own-property';
import { isObject } from '@app/shared/utils/typeGuards';

function hasMessage(value: unknown): value is { message: string } {
    return isObject(value)
        && hasOwnProperty(value, 'message')
        && 'string' === typeof value.message;
}

function hasStack(value: unknown): value is { stack: string } {
    return isObject(value)
        && hasOwnProperty(value, 'stack')
        && 'string' === typeof value.stack;
}

function prefixMessage(prefix: string, message: string): string {
    return '' === message ? `${prefix}.` : `${prefix}: ${message}`;
}

export function wrapError(
    name: string,
    message: string,
    error?: unknown,
): Error {
    const syntheticError = new Error();
    syntheticError.name = name;

    if (hasMessage(error)) {
        syntheticError.message = prefixMessage(message, error.message);
    } else if ('string' === typeof error) {
        syntheticError.message = prefixMessage(message, error);
    } else {
        syntheticError.message = prefixMessage(message, '');
    }

    if (hasStack(error)) {
        syntheticError.stack = error.stack;
    }

    return syntheticError;
}
