<app-spinner centered *ngIf="0 === newsItems.length; else loaded"></app-spinner>

<ng-template #loaded>
    <ion-refresher slot="fixed" (ionRefresh)="onRefresh($event)" *platform="'mobile'">
        <ion-refresher-content refreshingSpinner="crescent"></ion-refresher-content>
    </ion-refresher>

    <app-news-card *ngFor="let newsItem of newsItems" [newsItem]="newsItem"></app-news-card>

    <ion-infinite-scroll threshold="10px" [disabled]="!hasNext" (ionInfinite)="onFetchMore($event)">
        <ion-infinite-scroll-content loadingText="Weitere Beiträge werden geladen…"></ion-infinite-scroll-content>
    </ion-infinite-scroll>
</ng-template>
