import { Component, Input } from '@angular/core';
import { ShareService } from '@app/core/services/share/share.service';
import { environment } from '@app/environments/environment';
import { PopoverController } from '@ionic/angular';

@Component({
    selector: 'app-share-options',
    templateUrl: './share-options.component.html',
    styleUrls: ['./share-options.component.scss'],
})
export class ShareOptionsComponent {

    readonly isTwitterIntegrationEnabled = environment.twitterIntegrationEnabled;
    readonly isFacebookIntegrationEnabled = environment.facebookIntegrationEnabled;

    @Input() url: string;

    @Input() message: string;

    constructor(
        private shareService: ShareService,
        private popoverController: PopoverController,
    ) {
    }

    onShareOnFacebook(): void {
        this.shareService.shareOnFacebook(this.url);
        this.popoverController.dismiss();
    }

    onShareOnTwitter(): void {
        this.shareService.shareOnTwitter(this.url, this.message);
        this.popoverController.dismiss();
    }

    onCopyUrlToClipboard(): void {
        this.shareService.copyUrlToClipboard(this.url);
        this.popoverController.dismiss();
    }

}
