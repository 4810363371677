import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessagingService } from '@app/core/services/messaging/messaging.service';
import { NotificationService } from '@app/core/services/notification/notification.service';
import { Notification } from '@app/shared/models/notification.model';
import { NavController, PopoverController } from '@ionic/angular';
import { Subject, timer } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-notifications',
    templateUrl: './notification-popover.component.html',
    styleUrls: ['./notification-popover.component.scss'],
})
export class NotificationPopoverComponent implements OnInit, OnDestroy {

    loading = true;

    pageSize = 3;

    notifications: Notification[] = [];

    private ngUnsubscribe = new Subject<void>();

    constructor(
        private popoverController: PopoverController,
        private notificationService: NotificationService,
        private navController: NavController,
        private messagingService: MessagingService,
    ) {
    }

    ngOnInit() {
        this.messagingService.getCurrentMessage()
            .pipe(
                filter((currentMessage) => !!currentMessage),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe(() => this.loadNotifications());

        this.notificationService.getUnreadCount()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (unreadCount) => {
                    if (unreadCount > 0) {
                        this.loadNotifications();
                    }
                },
            );

        this.loadNotifications();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onShowAll() {
        this.navController.navigateForward('/notifications');
        this.popoverController.dismiss();
    }

    onDismiss() {
        this.popoverController.dismiss();
    }

    private loadNotifications() {
        this.loading = true;

        this.notificationService.getNotifications(1, this.pageSize)
            .subscribe((page) => {
                this.notifications = page.items;
                this.loading = false;

                if (1 === page.number && page.totalCount > 0) {
                    timer(2000)
                        .pipe(takeUntil(this.ngUnsubscribe))
                        .subscribe(() => this.notificationService.markReadUntil(page.items[0].id));
                }
            });
    }

}
