import { deg2Rad } from '@app/shared/utils/geo/deg2Rad';
import { distanceInKm } from '@app/shared/utils/geo/distanceInKm';
import { rad2Deg } from '@app/shared/utils/geo/rad2Deg';
import { wrapLng360 } from '@app/shared/utils/geo/wrapLng360';

export function calculateRadiusB(bounds: google.maps.LatLngBoundsLiteral): number {
    const latNERad = deg2Rad(wrapLng360(bounds.north));
    const lngNERad = deg2Rad(wrapLng360(bounds.east));
    const dLat = (deg2Rad(wrapLng360(bounds.north - bounds.south))) / 2;
    const dLng = (deg2Rad(wrapLng360(bounds.east - bounds.west))) / 2;
    const latCRad = latNERad - dLat;
    const lngCRad = lngNERad - dLng;
    const center = {
        lat: wrapLng360(rad2Deg(latCRad)),
        lng: wrapLng360(rad2Deg(lngCRad)),
    };
    const ne = {
        lat: wrapLng360(bounds.north),
        lng: wrapLng360(bounds.east),
    };

    return Math.max(1, Math.round(distanceInKm(center, ne)));
}
