import { EARTH_RADIUS } from '@app/shared/utils/geo/constants';
import { deg2Rad } from '@app/shared/utils/geo/deg2Rad';
import { rad2Deg } from '@app/shared/utils/geo/rad2Deg';
import { wrapLat } from '@app/shared/utils/geo/wrapLat';
import { wrapLng180 } from '@app/shared/utils/geo/wrapLng180';

export function calculateBoundsR(center: google.maps.LatLngLiteral, radius: number, mapHeight: number, mapWidth: number): google.maps.LatLngBoundsLiteral {
    const latCRad = deg2Rad(center.lat);
    const lngCRad = deg2Rad(center.lng);
    const angleRad = deg2Rad(90) - Math.atan2(mapHeight / 2, mapWidth / 2);
    const radiusFraction = radius / EARTH_RADIUS;
    const latNERad = Math.asin(
        Math.sin(latCRad) * Math.cos(radiusFraction) +
        Math.cos(latCRad) * Math.sin(radiusFraction) * Math.cos(angleRad),
    );
    const lngNERad = lngCRad + Math.atan2(
        Math.sin(angleRad) * Math.sin(radiusFraction) * Math.cos(latCRad),
        Math.cos(radiusFraction) - Math.sin(latCRad) * Math.sin(latNERad),
    );
    const dLat = latNERad - latCRad;
    const dLng = lngNERad - lngCRad;

    return {
        south: wrapLat(rad2Deg(latCRad - dLat)),
        west: wrapLng180(rad2Deg(lngCRad - dLng)),
        north: wrapLat(rad2Deg(latCRad + dLat)),
        east: wrapLng180(rad2Deg(lngCRad + dLng)),
    };
}
