import { Component } from '@angular/core';
import { environment } from '@app/environments/environment';

@Component({
    selector: 'app-legal-bar',
    templateUrl: './legal-bar.component.html',
    styleUrls: ['./legal-bar.component.scss'],
})
export class LegalBarComponent {

    privacyTermsUrl: string = environment.privacyTermsUrl;

}
