import { Component, Input, OnInit } from '@angular/core';
import { NewsItemService } from '@app/core/services/news-item/news-item.service';
import { NewsItem } from '@app/shared/models/news-item.model';
import { Refresher } from '@app/shared/utils/refresher/refresher';
import { Platform } from '@ionic/angular';
import { Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-news-item',
  templateUrl: './news-item.component.html',
  styleUrls: ['./news-item.component.scss'],
})
export class NewsItemComponent implements OnInit {

    loading = true;

    newsItems: NewsItem[] = [];

    totalCount: number;

    pageNumber: number;

    pageSize: number;

    hasNext: boolean;

    @Input('source') source: string;

    @Input('order') order: 'asc'|'desc' = 'desc';

    private ngUnsubscribe = new Subject<void>();

    private refresher = new Refresher();

    constructor(private newsItemService: NewsItemService,
                private platform: Platform) {
    }

    ngOnInit() {
        this.pageSize = this.platform.is('mobile') ? 5 : 10;

        this.refresher
            .refreshes
            .pipe(
                takeUntil(this.ngUnsubscribe),
                tap(() => this.loading = true),
                switchMap(() => this.getNewsItems(1)),
            )
            .subscribe((page) => {
                this.newsItems = page.items;
                this.pageNumber = page.number;
                this.totalCount = page.totalCount;
                this.hasNext = page.hasNext;
                this.loading = false;

                this.refresher.markComplete();
            });

        this.refresher.refresh();
    }

    onRefresh(event) {
        this.refresher.refresh(() => event.target.complete());
    }

    onFetchMore(event) {
        this.getNewsItems(this.pageNumber + 1).subscribe(
            (page) => {
                this.newsItems = [ ...this.newsItems, ...page.items ];
                this.pageNumber = page.number;
                this.totalCount = page.totalCount;
                this.hasNext = page.hasNext;

                event.target.complete();
            },
        );
    }

    private getNewsItems(page: number) {
        return this.newsItemService.getNewsItems(page, this.pageSize, this.source, this.order);
    }
}
