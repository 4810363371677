import { Directive, forwardRef, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
    selector: 'ion-input[type="number"][min]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => MinDirective),
            multi: true,
        },
    ],
})
export class MinDirective implements Validator {

    @Input('min') limit: number;

    validate(control: AbstractControl): ValidationErrors | null {
        if (null !== control.value && undefined !== control.value && control.value < this.limit) {
            return { tooLow: true };
        }

        return null;
    }

}
