import { Injectable } from '@angular/core';
import { CanActivate, CanLoad } from '@angular/router';
import { AuthenticationService } from '@app/core/services/authentication/authentication.service';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IsNotAuthenticatedGuard implements CanActivate, CanLoad {

    constructor(private authenticationService: AuthenticationService,
                private navController: NavController) {
    }

    canActivate(): Observable<boolean> {
        return this.isNotAuthenticated();
    }

    canLoad(): Observable<boolean> {
        return this.isNotAuthenticated();
    }

    private isNotAuthenticated(): Observable<boolean> {
        return this.authenticationService.isAuthenticated().pipe(
            tap((authenticated) => {
                // Ugly programming with side effects
                // Seems to be the recommended way to solve this
                if (authenticated) {
                    this.navController.navigateRoot(['/']);
                }
            }),
            map((authenticated) => !authenticated),
            // Guards ignore anything but the first emitted value
            // and require the observable to complete
            first(),
        );
    }

}
