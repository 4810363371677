import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Platforms } from '@ionic/core';

@Directive({
    selector: '[platform]',
})
export class PlatformDirective {

    private hasView = false;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private _platform: Platform,
    ) {
    }

    @Input()
    set platform(platforms: Platforms | Platforms[]) {
        if (!(platforms instanceof Array)) {
            platforms = [platforms];
        }

        const isPlatform = platforms.some((platform) => this._platform.is(platform));

        if (isPlatform && !this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
        } else if (!isPlatform && this.hasView) {
            this.viewContainer.clear();
            this.hasView = false;
        }
    }

}
