import { Directive, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
    AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors,
} from '@angular/forms';
import { AddressDestinationService } from '@app/core/services/address-destination/address-destination.service';
import { EnvironmentService } from '@app/core/services/environment-service';
import { PostalCodePatternByCountryCode } from '@app/shared/models/address-destination.model';
import { eq } from 'lodash-es';
import { from, Observable, of, OperatorFunction, ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map, switchMap } from 'rxjs/operators';

interface Params {
    countryCode: string;

    postalCode: string;
}

const invalidValidationError: ValidationErrors = { invalid: true };

@Directive({
    selector: '[validPostalCode]',
    providers: [
        {
            provide: NG_ASYNC_VALIDATORS,
            useExisting: forwardRef(() => ValidPostalCodeDirective),
            multi: true,
        },
    ],
})
export class ValidPostalCodeDirective implements OnChanges, AsyncValidator {

    @Input() countryCode: string;

    private params = new ReplaySubject<Params>(1);

    private validity = from(this.params).pipe(
        distinctUntilChanged((a, b) => eq(a, b)),
        debounceTime(500),
        this.mapCountryCodeValidation(),
        this.validatePostalCode(),
    );

    private readonly validatedCountryCode: string;

    constructor(
        private addressDestinationService: AddressDestinationService,
        environmentService: EnvironmentService,
    ) {
        this.validatedCountryCode = environmentService.environment.validatedCountryCode;
    }

    private onValidatorChange = () => {};

    ngOnChanges(changes: SimpleChanges): void {
        this.onValidatorChange();
    }

    registerOnValidatorChange(fn: () => void): void {
        this.onValidatorChange = fn;
    }

    validate(control: AbstractControl): Observable<ValidationErrors | null> {
        this.params.next({
            postalCode: control.value.trim(),
            countryCode: this.countryCode.trim(),
        });

        return this.validity.pipe(first());
    }

    private mapCountryCodeValidation(): OperatorFunction<Params, Params | null> {
        return map((params) => {
            if (this.validatedCountryCode !== params.countryCode) {
                return null;
            }

            return params;
        });
    }

    private validatePostalCode(): OperatorFunction<Params | null, ValidationErrors | null> {
        return switchMap((params) => {
            if (!params) {
                return of(null);
            }

            const { countryCode, postalCode } = params;

            const postalCodeRegex = new RegExp(PostalCodePatternByCountryCode[countryCode]);

            if (!postalCode || !postalCode.match(postalCodeRegex)) {
                return of(invalidValidationError);
            }

            return this.validateDestinations(countryCode, postalCode);
        });
    }

    private validateDestinations(
        countryCode: string,
        postalCode: string,
    ): Observable<ValidationErrors | null> {
        return this.addressDestinationService.existsByCountryCodeAndPostalCode(countryCode, postalCode)
            .pipe(
                map((exists) => exists ? null : invalidValidationError),
            );
    }
}
