import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'translated' })
export class TranslatedPipe implements PipeTransform  {

    constructor(private translateService: TranslateService) {}

    transform(key: string): boolean {
        const translation = this.translateService.instant(key);

        return key !== translation;
    }

}
