import { Injectable } from '@angular/core';
import { StorageService } from '@app/core/services/storage/storage.service';
import { Platform } from '@ionic/angular';
import { from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export const LAST_VIEWED_HIGHLIGHTED_FEATURE_VERSION_KEY = 'last_read_news_version';

/**
 * Increase the current version number when adding a new collection of slides
 */
export const CURRENT_HIGHLIGHTED_FEATURES_VERSION = 2;

@Injectable({
    providedIn: 'root',
})
export class HighlightedFeaturesService {

    constructor(
        private storageService: StorageService,
        private platform: Platform,
    ) {
    }

    shouldShowHighlightedFeatures(): Observable<boolean> {
        if (!this.isSupportedPlatform()) {
            return of(false);
        }

        return this.getLastReadVersion()
            .pipe(map((lastReadVersion) => isNaN(lastReadVersion)
                || lastReadVersion < CURRENT_HIGHLIGHTED_FEATURES_VERSION));
    }

    markAllAsRead(): Observable<unknown> {
        if (!this.isSupportedPlatform()) {
            return of(true);
        }

        return from(
            this.storageService.set(LAST_VIEWED_HIGHLIGHTED_FEATURE_VERSION_KEY, CURRENT_HIGHLIGHTED_FEATURES_VERSION),
        );
    }

    private getLastReadVersion(): Observable<number> {
        return from(
            this.storageService.get(LAST_VIEWED_HIGHLIGHTED_FEATURE_VERSION_KEY),
        );
    }

    private isSupportedPlatform(): boolean {
        return this.platform.is('mobile');
    }
}
