import { EARTH_RADIUS } from '@app/shared/utils/geo/constants';
import { deg2Rad } from '@app/shared/utils/geo/deg2Rad';

export function distanceInKm(point1: google.maps.LatLngLiteral, point2: google.maps.LatLngLiteral): number {
    const lat1Rad = deg2Rad(point1.lat);
    const lng1Rad = deg2Rad(point1.lng);
    const lat2Rad = deg2Rad(point2.lat);
    const lng2Rad = deg2Rad(point2.lng);

    return EARTH_RADIUS * Math.acos(
        Math.sin(lat1Rad) * Math.sin(lat2Rad) +
        Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.cos(lng2Rad - lng1Rad),
    );
}
