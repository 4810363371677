import { IsDefined, IsString } from 'class-validator';

// Keep in sync with the sizes in "AvatarFactory.php"
export enum AvatarSize {
    SMALL = '40x40',
    MEDIUM = '80x80',
    LARGE = '200x200',
    EXTRA_LARGE = '400x400',
}

const hasHighDisplayResolution = () => window.devicePixelRatio > 1;

export class Avatar implements Record<AvatarSize, string> {

    @IsDefined()
    @IsString()
    readonly [AvatarSize.SMALL]!: string;

    @IsDefined()
    @IsString()
    readonly [AvatarSize.MEDIUM]!: string;

    @IsDefined()
    @IsString()
    readonly [AvatarSize.LARGE]!: string;

    @IsDefined()
    @IsString()
    readonly [AvatarSize.EXTRA_LARGE]!: string;

    get(highResolutionDisplaySize: AvatarSize, normalResolutionDisplaySize?: AvatarSize): string {
        if (undefined === normalResolutionDisplaySize) {
            return this[highResolutionDisplaySize];
        }

        const displaySize = hasHighDisplayResolution() ? highResolutionDisplaySize : normalResolutionDisplaySize;

        return this[displaySize];
    }
}
