export class AddressDestination {

    readonly id: string;

    readonly postalCode: string;

    readonly destinationName: string;

    readonly provinceName: string;

}

export const PostalCodePatternByCountryCode: Record<string, string> = {
    AT: '^[1-9][0-9]{3}$',
    DE: '^[0-9]{5}$',
};
