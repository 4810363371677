import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AbstractFormComponent } from '@app/shared/components/abstract-form/abstract-form.component';
import { ModalController } from '@ionic/angular';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-reauthenticate-form',
    templateUrl: './reauthenticate-form.component.html',
    styleUrls: ['./reauthenticate-form.component.scss'],
})
export class ReauthenticateFormComponent extends AbstractFormComponent<any> implements OnInit {

    @ViewChild(NgForm, { read: ElementRef }) formEl: ElementRef;

    password: string;

    constructor(private modalController: ModalController) {
        super();
    }

    ngOnInit(): void {
        this.formSubmit
            .pipe(
                takeUntil(this.ngUnsubscribe),
                filter((event) => !event.pending && event.valid),
            )
            .subscribe(() => this.modalController.dismiss(true));
    }

    onChangePassword(password: string) {
        this.password = password;
    }

    onCancel() {
        this.modalController.dismiss(false);
    }

}
