import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

// Copied from NAT

// 2 MB
export const DEFAULT_MAX_FILE_SIZE = 2 * 1024 * 1024;

function assertValidSize(size: number): void {
    if (isNaN(size) || !isFinite(size)) {
        throw new Error(`The number "${size}" is not a valid size`);
    }
}

/**
 * @param maxSize Max file size in bytes
 */
export default function maxFileSizeValidator(maxSize: number): ValidatorFn {
    assertValidSize(maxSize);

    return (control: AbstractControl): ValidationErrors | null => {
        const file = control.value;

        if (!(file instanceof File)) {
            return null;
        }

        if (file.size > maxSize) {
            return { maxFileSize: true };
        }

        return null;
    };
}
