import {
    AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostBinding,
    OnDestroy, OnInit, ViewChild,
} from '@angular/core';
import { ActivityFiltersService } from '@app/core/services/activity-filters/activity-filters.service';
import { ActivityTypeService } from '@app/core/services/activity-type/activity-type.service';
import { ActivityService } from '@app/core/services/activity/activity.service';
import { EntityService } from '@app/core/services/entity/entity.service';
import { MenuService } from '@app/core/services/menu/menu.service';
import { ProfileService } from '@app/core/services/profile/profile.service';
import { TitleService } from '@app/core/services/title/title.service';
import { ActivityFilters } from '@app/shared/models/activity-filters.model';
import { ActivityImageSize } from '@app/shared/models/activity-image.model';
import { ActivityType } from '@app/shared/models/activity-type.model';
import { Activity } from '@app/shared/models/activity.model';
import { Entity } from '@app/shared/models/entity.model';
import { FILTER_PERIOD_LABELS } from '@app/shared/models/filter-period.model';
import { Focus } from '@app/shared/models/geo-center.model';
import { Profile } from '@app/shared/models/profile.model';
import { REGION_LABELS } from '@app/shared/models/region.model';
import { Refresher } from '@app/shared/utils/refresher/refresher';
import { queryShadowChild } from '@app/shared/utils/view/queryShadowChild';
import { IonContent, ViewDidEnter } from '@ionic/angular';
import { Observable, of, Subject, zip } from 'rxjs';
import {
    distinctUntilChanged, switchMap, takeUntil, tap,
} from 'rxjs/operators';

export const SHARE_ACTIVITY_MESSAGE = 'Mir gefällt diese Aktion!';

@Component({
    selector: 'app-activity-list',
    templateUrl: './activity-list.page.html',
    styleUrls: ['./activity-list.page.scss'],
})
export class ActivityListPage implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked, ViewDidEnter {

    @ViewChild(IonContent, { read: ElementRef, static: true }) ionContent: ElementRef;

    @HostBinding('class.scrollable') scrollable = false;

    public get loading() {
        return !this.profile || this.loadingActivities;
    }

    refreshing = false;

    activities: Activity[] = [];

    totalCount: number;

    pageNumber: number;

    hasNext: boolean;

    filters: ActivityFilters;

    geoCenter = Focus;

    filterPeriodLabels = FILTER_PERIOD_LABELS;

    regionLabels = REGION_LABELS;

    filterEntity: Entity;

    filterActivityType: ActivityType;

    imageRatio: ActivityImageSize = window.devicePixelRatio > 1 ? '760x570' : '380x285';

    profile?: Profile;

    private ngUnsubscribe = new Subject<void>();

    private refresher = new Refresher();

    private innerScroll: HTMLDivElement;

    private loadingActivities = true;

    constructor(
        private activityService: ActivityService,
        private activityFiltersService: ActivityFiltersService,
        private entityService: EntityService,
        private activityTypeService: ActivityTypeService,
        private titleService: TitleService,
        private menuService: MenuService,
        private changeDetectorRef: ChangeDetectorRef,
        private profileService: ProfileService,
    ) {
    }

    ngOnInit() {
        this.refresher
            .refreshes
            .pipe(
                takeUntil(this.ngUnsubscribe),
                switchMap(() => this.activityService.getActivities(this.filters, 'listRadius', 1)),
            )
            .subscribe((page) => {
                this.activities = page.items;
                this.pageNumber = page.number;
                this.totalCount = page.totalCount;
                this.hasNext = page.hasNext;
                this.loadingActivities = false;
                this.refreshing = false;

                this.refresher.markComplete();
            });

        this.activityFiltersService.getFilters()
            .pipe(
                distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
                tap(() => this.refreshing = true),
                tap((filters: ActivityFilters) => this.filters = filters),
                tap(() => this.refresher.refresh()),
                switchMap((filters: ActivityFilters) => {
                    const observables = [];

                    observables.push(filters.entityId
                        ? this.entityService.getEntity(filters.entityId)
                        : of(null));

                    observables.push(filters.activityTypeId
                        ? this.activityTypeService.getActivityType(filters.activityTypeId)
                        : of(null));

                    return zip(...observables) as Observable<[Entity, ActivityType]>;
                }),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe(
                ([entity, activityType]) => {
                    this.filterEntity = entity;
                    this.filterActivityType = activityType;
                },
            );

        this.profileService.getProfile('cache-first')
            .pipe(
                tap((profile) => this.profile = profile),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe();
    }

    ionViewDidEnter(): void {
        this.titleService.setGlobalMenuTitle('Aktionen in meiner Nähe');
        this.titleService.setHtmlTitle('Aktionen');
        this.menuService.enableMenu();
    }

    ngAfterViewInit(): void {
        setTimeout(
            () => queryShadowChild(this.ionContent.nativeElement.shadowRoot, '.inner-scroll')
                .then((child: HTMLDivElement) => {
                    this.innerScroll = child;

                    this.updateScrollable();
                }),
            0,
        );
    }

    ngAfterViewChecked(): void {
        this.updateScrollable();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onRefresh(event) {
        this.refresher.refresh(() => event.target.complete());
    }

    onFetchMore(event) {
        this.activityService.getActivities(this.filters, 'listRadius', this.pageNumber + 1).subscribe(
            (page) => {
                this.activities = [...this.activities, ...page.items];
                this.pageNumber = page.number;
                this.totalCount = page.totalCount;
                this.hasNext = page.hasNext;

                event.target.complete();
            },
        );
    }

    trackByFn(index: number, activity: Activity): string {
        // Prevent flimmering of the cards during updates
        return activity.id;
    }

    round(number: number): number {
        return Math.round(number * 1e6) / 1e6;
    }

    private updateScrollable() {
        // This code is called by ngAfterViewChecked(), so make sure it is
        // *very* efficient
        if (!this.innerScroll) {
            return;
        }

        const scrollable = this.innerScroll.scrollHeight > this.innerScroll.offsetHeight;

        if (scrollable === this.scrollable) {
            return;
        }

        setTimeout(() => {
            this.scrollable = scrollable;

            if (!this.changeDetectorRef['destroyed']) {
                this.changeDetectorRef.detectChanges();
            }
        }, 0);
    }

}
