import { calculateBoundsR } from '@app/shared/utils/geo/calculateBoundsR';
import { calculateZoomLevelB } from '@app/shared/utils/geo/calculateZoomLevelB';

export function calculateZoomLevelR(center: google.maps.LatLngLiteral, radius: number, mapHeight: number, mapWidth: number) {
    const calculatedBounds = calculateBoundsR(
        center,
        radius,
        mapHeight,
        mapWidth,
    );

    return calculateZoomLevelB(
        calculatedBounds,
        mapHeight,
        mapWidth,
    );
}
