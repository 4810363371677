import { dateToString } from '@app/shared/transformers/dateToString';
import { Transform } from 'class-transformer';

export class Notification {

    id: string;

    message: string;

    url: string;

    @Transform(dateToString)
    sentAt: Date;

    @Transform(dateToString)
    readAt: Date;

    constructor(props = {}) {
        Object.assign(this, props);
    }
}
