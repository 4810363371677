import { Directive, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { environment } from '@app/environments/environment';
import libphonenumber from 'google-libphonenumber';

const PhoneNumberUtil = libphonenumber.PhoneNumberUtil.getInstance();

@Directive({
    selector: '[phoneNumber][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => PhoneNumberDirective),
            multi: true,
        },
    ],
})
export class PhoneNumberDirective implements Validator, OnChanges {

    @Input()
    public countryCode: string;

    private onValidatorChange = () => {};

    ngOnChanges(changes: SimpleChanges): void {
        if ('countryCode' in changes) {
            if (!this.countryCode) {
                this.countryCode = environment.validatedCountryCode;
            }

            this.onValidatorChange();
        }
    }

    validate(control: AbstractControl): ValidationErrors {
        const dirtyValue = control.value || '';

        if ('' === dirtyValue) {
            return null;
        }

        let cleanValue;

        try {
            cleanValue = PhoneNumberUtil.parse(dirtyValue, this.countryCode);
        } catch (error) {
            return { phoneNumber: true };
        }

        return PhoneNumberUtil.isValidNumber(cleanValue)
            ? null
            : { phoneNumber: true };
    }

    registerOnValidatorChange(fn: () => void): void {
        this.onValidatorChange = fn;
    }

}
