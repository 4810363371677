import { Directive, HostListener } from '@angular/core';
import { NavController } from '@ionic/angular';

@Directive({
    selector: '[backLink]',
})
export class BackLinkDirective {

    constructor(private navController: NavController) {
    }

    @HostListener('click')
    onClick() {
        this.navController.pop();
    }

}
