import { Injectable } from '@angular/core';
import { Logger } from '@app/core/services/logger/logger';
import {
    HasTargetUrl, MessagingPlatform, NotificationPermission,
} from '@app/core/services/messaging/messaging-platform';
import { deleteToken, getMessaging, getToken, isSupported, Messaging, onMessage } from 'firebase/messaging';
import {
    BehaviorSubject, combineLatest, EMPTY, firstValueFrom, from, Observable, ReplaySubject,
} from 'rxjs';
import { FirebaseError } from '@firebase/util';

function mapToNotificationPermission(permission: globalThis.NotificationPermission): NotificationPermission {
    return 'granted' === permission ? 'granted' : 'denied';
}

function isTokenUnsubscribeFailedError(error: unknown): boolean {
    return error instanceof FirebaseError
        && 'messaging/token-unsubscribe-failed' === error.code;
}

@Injectable({
    providedIn: 'root',
})
export class WebMessaging implements MessagingPlatform {

    private messagingSubject = new ReplaySubject<Messaging>(1);

    private currentMessageSubject = new BehaviorSubject<unknown>(null);

    private isRegisteredSubject = new BehaviorSubject<boolean>(false);

    private tokenSubject = new BehaviorSubject<string|null>(null);

    private _unsubscribe = () => {};

    constructor(
        private logger: Logger,
    ) {
        this.setupMessaging();
        this.setupMessagingRegistration();
    }

    getCurrentMessage(): Observable<unknown> {
        return from(this.currentMessageSubject);
    }

    getNotificationClick(): Observable<HasTargetUrl|unknown> {
        // In the web environment, the link is handled by the browser due
        // to the "link" property in "fcm_options". Hence, we don't need to
        // manually handle clicks.
        return EMPTY;
    }

    async requestPermission(): Promise<NotificationPermission> {
        try {
            await firstValueFrom(this.messagingSubject);

            const permission = await Notification.requestPermission();

            return mapToNotificationPermission(permission);
        } catch (error) {
            return 'denied';
        }
    }

    getToken(): Observable<string|null> {
        return this.tokenSubject;
    }

    async register(): Promise<boolean> {
        const messaging = await firstValueFrom(this.messagingSubject);

        const token = await getToken(messaging);

        this.tokenSubject.next(token);

        this.isRegisteredSubject.next(true);

        return true;
    }

    async unregister(): Promise<boolean> {
       const messaging = await firstValueFrom(this.messagingSubject);

       let didUnregister: boolean;

       try {
           didUnregister = await deleteToken(messaging);
       } catch (error) {
           if (!isTokenUnsubscribeFailedError(error)) {
               throw error;
           }

           didUnregister = true;
       }

       this.tokenSubject.next(null);

       this.isRegisteredSubject.next(false === didUnregister);

       return didUnregister;
    }

    private setupMessaging(): void {
        isSupported().then((isSupported) => {
            if (isSupported) {
                this.logger.log('Web Firebase Messaging is supported.');
                this.messagingSubject.next(getMessaging());
            } else {
                this.logger.log('Web Firebase Messaging is not supported.');
            }
        });
    }

    private setupMessagingRegistration(): void {
        combineLatest([
            this.messagingSubject,
            this.isRegisteredSubject,
        ])
            .subscribe(([messaging, isRegistered]) => {
                // Either unsubscribe the current messaging (if isRegistered=false) or unsubscribe the previous
                // messaging instance in favour of subscribing the new one (if isRegister=true).
                // Either way, the currently known unsubscribe needs to be called.
                this._unsubscribe();

                if (isRegistered) {
                    this._unsubscribe = onMessage(
                        messaging,
                        (payload) => this.currentMessageSubject.next(payload),
                    );
                    this.logger.log('Subscribed to the Web Firebase Messaging platform.');
                } else {
                    this.logger.log('Unsubscribed from the Web Firebase Messaging platform.');
                }
            });
    }
}
