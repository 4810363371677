import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core/services/authentication/authentication.service';
import { environment } from '@app/environments/environment';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';

const publicEndpoints = [
    '/countries',
    '/address-destinations',
];

function shouldAuthenticate(request: HttpRequest<any>): boolean {
    return isAppApiRequest(request)
        && !isPublic(request);
}

function isAppApiRequest(request: HttpRequest<any>): boolean {
    return request.url.startsWith(environment.appApiUrl);
}

function isPublic(request: HttpRequest<any>): boolean {
    return publicEndpoints.some((path) => request.url.startsWith(environment.appApiUrl + path))
        || isCreatingUser(request);
}

function isCreatingUser(request: HttpRequest<any>): boolean {
    return request.url === `${environment.appApiUrl}/viewer`
        && 'POST' === request.method;
}

function setBearerAuthorizationHeader<T>(request: HttpRequest<T>, token: string): HttpRequest<T> {
    return request.clone({
        setHeaders: {
            Authorization: 'Bearer ' + token,
        },
    });
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private authenticationService: AuthenticationService,
                private router: Router) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // A WORD OF CAUTION
        // Be very careful not to break the control flow of the unsubscription
        // from next.handle() to the returned observable!
        // Otherwise cancellation of requests will not work anymore upon
        // unsubscription.

        if (shouldAuthenticate(request)) {
            return this.authenticationService.getIdToken().pipe(
                first(),
                switchMap((idToken) => {
                    if (!idToken) {
                        this.router.navigate(['/login']);

                        return EMPTY;
                    }

                    request = setBearerAuthorizationHeader(request, idToken);

                    return next.handle(request);
                }),
                catchError((response: HttpErrorResponse) => {
                    // Is this actually needed/working?
                    // if (401 === response.status) {
                    //     this.authenticationService.signOut();
                    //
                    //     // Don't break the observable
                    //     return EMPTY;
                    // }

                    if (403 === response.status) {
                        return this.authenticationService.refreshIdToken()
                            .pipe(
                                map((refreshedIdToken) => setBearerAuthorizationHeader(request, refreshedIdToken)),
                                switchMap((refreshedRequest) => next.handle(refreshedRequest)),
                            );
                    }

                    return throwError(response);
                }),
            );
        }

        // Public endpoint
        return next.handle(request);
    }
}
