import { Component } from '@angular/core';
import { AuthenticationService } from '@app/core/services/authentication/authentication.service';
import { environment } from '@app/environments/environment';
import { PopoverController } from '@ionic/angular';

@Component({
    selector: 'app-user-menu-options',
    templateUrl: './user-menu-options.component.html',
})
export class UserMenuOptionsComponent {

    readonly isMembershipPageEnabled = 'neos' === environment.client;

    readonly notificationsEnabled = environment.notificationsEnabled;

    constructor(
        private authenticationService: AuthenticationService,
        private popoverController: PopoverController,
    ) {
    }

    onLogout() {
        this.authenticationService.signOut();

        this.popoverController.dismiss();
    }

    onDismiss() {
        this.popoverController.dismiss();
    }

}
