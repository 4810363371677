import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { AppComponent } from '@app/app.component';

@Injectable()
export class FixedIonicRouteStrategy implements RouteReuseStrategy {

    shouldDetach(_route: ActivatedRouteSnapshot): boolean {
        return false;
    }

    shouldAttach(_route: ActivatedRouteSnapshot): boolean {
        return false;
    }

    store(_route: ActivatedRouteSnapshot, _detachedTree: DetachedRouteHandle): void {
        return;
    }

    retrieve(_route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        return null;
    }

    shouldReuseRoute(
        future: ActivatedRouteSnapshot,
        curr: ActivatedRouteSnapshot,
    ): boolean {
        if (future.routeConfig !== curr.routeConfig) {
            return false;
        }

        if (future.component !== curr.component) {
            // Bugfix: Special case: Due to Angulars internals, when calling
            // this method for the very first time with the root component,
            // both snapshots return null as component. Only after the
            // snapshots are created, the AppComponent is injected into the
            // angular router via resetRootComponent(). Consequently, on the
            // next navigation curr.component = null and
            // future.component = AppComponent. Prevent reloads of the entire
            // app by checking for this case.

            // The bug was visible when
            // 1. loading the activity map/list as very first page (F5)
            // 2. moving to an activity detail page
            // The map flashed and all markers were reloaded due to Angular
            // reinitializing the GlobalMenuLayoutComponent.

            // This fix ensures the same GlobalMenuLayoutComponent (and hence
            // its children) are reused in that case.
            if (null === curr.component && AppComponent === future.component) {
                return true;
            }

            return false;
        }

        // checking router params
        const futureParams = future.params;
        const currentParams = curr.params;
        const keysA = Object.keys(futureParams);
        const keysB = Object.keys(currentParams);

        if (keysA.length !== keysB.length) {
            return false;
        }

        // Test for A's keys different from B.
        for (const key of keysA) {
            if (currentParams[key] !== futureParams[key]) {
                return false;
            }
        }

        return true;
    }

}
