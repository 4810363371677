import { Injectable } from '@angular/core';
import { environment } from '@app/environments/environment';

export type Environment = typeof environment;

@Injectable({
    providedIn: 'root',
})
export class EnvironmentService {
    get environment(): Environment {
        return environment;
    }
}
