import {
    AfterViewChecked, AfterViewInit, ContentChild, Directive, HostBinding, OnDestroy,
} from '@angular/core';
import { NgModel } from '@angular/forms';
import { of, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

/* eslint-disable @angular-eslint/directive-selector */

@Directive({
    selector: 'ion-item',
})
export class NgModelIonicDirective implements AfterViewInit, AfterViewChecked, OnDestroy {

    @ContentChild(NgModel) model: NgModel;

    @HostBinding('class.app-invalid') invalid = false;

    private refresh = new Subject<NgModel>();

    private ngUnsubscribe = new Subject<void>();

    private previousModel: NgModel;

    ngAfterViewInit(): void {
        this.refresh
            .pipe(
                takeUntil(this.ngUnsubscribe),
                tap((model) => this.previousModel = model),
                switchMap((model) => model ? model.statusChanges : of('VALID')),
            )
            .subscribe((status) => this.invalid = 'INVALID' === status);

        this.refresh.next(this.model);
    }

    ngAfterViewChecked(): void {
        if (this.model !== this.previousModel) {
            this.refresh.next(this.model);
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

}
