import { Location } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppApiHttpClient } from '@app/core/core.module';
import { SerializingHttpClient } from '@app/core/http/serializing-http-client';
import { IdentityMap } from '@app/core/services/identity-map';
import { environment } from '@app/environments/environment';
import { ActivityFilters } from '@app/shared/models/activity-filters.model';
import { Activity } from '@app/shared/models/activity.model';
import { getPeriodEnd, getPeriodStart } from '@app/shared/models/filter-period.model';
import { GeoPoint } from '@app/shared/models/geo-point.model';
import { Page } from '@app/shared/models/page.model';
import * as moment from 'moment';
import { from, Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ActivityService {

    private selectedActivitySource = new ReplaySubject<string>(1);

    private selectedActivity = from(this.selectedActivitySource).pipe(distinctUntilChanged());

    private identityMap = new IdentityMap<Activity>();

    private fields = [
        'id',
        'title',
        'image',
        'place',
        'activityType',
        'startTime',
        'endTime',
        'numberOfVolunteers',
        'minimumNumberOfVolunteers',
        'numberOfRegistrations',
        'distanceInKm',
        'featured',
        'personInCharge',
    ];

    private fieldsIncludingNestedFields = [
        ...this.fields,
        // Fields of non-resources need to be selected explicitly
        // Thanks API platform :(
        // User
        'firebaseUserId',
    ];

    constructor(@Inject(AppApiHttpClient) private http: SerializingHttpClient,
                private router: Router,
                private location: Location) {
    }

    getActivities(filters: ActivityFilters, radiusProperty: string,
                  page = 1): Observable<Page<Activity>> {
        let path = '/activities';

        path = this.addFiltersToPath(filters, path, radiusProperty);

        path += `&page=${page}&fields=${this.fieldsIncludingNestedFields.join(',')}`;

        return this.http
            .get(Page, path, {
                headers: {
                    Accept: 'application/ld+json',
                },
            })
            .pipe(
                tap((result: Page<Activity>) => result.items = result.items.map(
                    (activity) => this.identityMap.merge(activity, this.fields),
                )),
            );
    }

    getNextParticipatedActivity(): Observable<Activity> {
        let path = '/activities';
        path += `?onlyRegistered=1&pageSize=1&fields${this.fieldsIncludingNestedFields.join(',')}`;

        return this.http
            .get(Page, path, {
                headers: {
                    Accept: 'application/ld+json',
                },
            })
            .pipe(
                tap((result: Page<Activity>) => result.items = result.items.map(
                    (activity) => this.identityMap.merge(activity, this.fields),
                )),
                map((result: Page<Activity>) => result.items[0] || null),
            );
    }

    getActivity(id: string): Observable<Activity> {
        return this.http.get(Activity, `/activities/${id}`)
            .pipe(map((activity: Activity) => this.identityMap.merge(activity)));
    }

    getActivityGeoPoints(filters: ActivityFilters, radiusProperty: string): Observable<GeoPoint[]> {
        let path = '/activities/geo-points';

        path = this.addFiltersToPath(filters, path, radiusProperty);

        return this.http.get(GeoPoint, path);
    }

    setSelectedActivity(id: string): void {
        this.selectedActivitySource.next(id);
    }

    getSelectedActivity(): Observable<string> {
        return this.selectedActivity;
    }

    generateActivityUrl(activity: Activity): string {
        const path = this.router.createUrlTree(['/activities/detail', activity.id]);

        return environment.appUrl + this.location.prepareExternalUrl(path.toString());
    }

    private addFiltersToPath(filters: ActivityFilters, path: string, radiusProperty: string) {
        path += `?longitude=${filters.longitude}&latitude=${filters.latitude}&maxDistanceInKm=${filters[radiusProperty]}`;

        if (filters.inNeedOfVolunteers) {
            path += '&inNeedOfVolunteers=1';
        }

        if (filters.onlyRegistered) {
            path += '&onlyRegistered=1';
        }

        if (null !== filters.period) {
            const startDate = moment(getPeriodStart(filters.period)).format('YYYY-MM-DD');
            const endDate = moment(getPeriodEnd(filters.period)).format('YYYY-MM-DD');

            path += `&from=${startDate}&until=${endDate}`;
        }

        if (null !== filters.activityTypeId) {
            path += `&activityTypeId=${filters.activityTypeId}`;
        }

        if (null !== filters.entityId) {
            path += `&entityId=${filters.entityId}`;
        }

        if (null !== filters.region) {
            path += `&regionId=${filters.region}`;
        }

        if (null !== filters.postalCode) {
            path += `&postalCode=${filters.postalCode}`;
        }

        return path;
    }

}
