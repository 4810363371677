import { Directive, forwardRef } from '@angular/core';
import {
    AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors,
} from '@angular/forms';
import {
    AuthenticationService, LoginResult,
} from '@app/core/services/authentication/authentication.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Directive({
    selector: '[correctPassword]',
    providers: [
        {
            provide: NG_ASYNC_VALIDATORS,
            useExisting: forwardRef(() => CorrectPasswordDirective),
            multi: true,
        },
    ],
})
export class CorrectPasswordDirective implements AsyncValidator {

    constructor(private authenticationService: AuthenticationService) {
    }

    validate(control: AbstractControl): Observable<ValidationErrors | null> {
        if (!control.value) {
            // Case validated by the required constraint
            return of(null);
        }

        return this.authenticationService.reauthenticate(control.value).pipe(
            map((loginResult) => LoginResult.SUCCESS === loginResult
                ? null
                : { invalid: true }),
        );
    }

}
