// Keep in sync with "UserRole.php"
export enum UserRole {
    ADMIN = 'ROLE_ADMIN',
    APP = 'ROLE_APP',
    CAMBUILDR = 'ROLE_CAMBUILDR',
    CAMPAIGN_MONITOR = 'ROLE_CAMPAIGN_MONITOR',
    COLLECTOR = 'ROLE_COLLECTOR',
    COMMUNICATION_MANAGER = 'ROLE_COMMUNICATION_MANAGER',
    COMMUNITY_MANAGER = 'ROLE_COMMUNITY_MANAGER',
    CONTACT_MANAGER = 'ROLE_CONTACT_MANAGER',
    EVENT_FRONTEND = 'ROLE_EVENT_FRONTEND',
    FIELD_MANAGER = 'ROLE_FIELD_MANAGER',
    FIREBASE_USER = 'ROLE_FIREBASE_USER',
    HUBSPOT = 'ROLE_HUBSPOT',
    ORGANIZATION_ADMIN = 'ROLE_ORGANIZATION_ADMIN',
    PREVIOUS_ADMIN = 'ROLE_PREVIOUS_ADMIN',
    SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
    SYSTEM = 'ROLE_SYSTEM',
    TRANSIENT_FIREBASE_USER = 'ROLE_TRANSIENT_FIREBASE_USER',
    USER = 'ROLE_USER',
    VIP_ADMIN = 'ROLE_VIP_ADMIN',
}
