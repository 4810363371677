export class Address {

    street: string;

    street2: string;

    city: string;

    province: string;

    postalCode: string;

    countryCode: string;

}
