import { Injectable, Optional } from '@angular/core';
import { InfoService } from '@app/core/services/info/info.service';
import { environment } from '@app/environments/environment';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { Clipboard } from '@capacitor/clipboard';
import { Platform } from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class ShareService {

    private readonly isTwitterIntegrationEnabled = environment.twitterIntegrationEnabled;
    private readonly twitterUsername = environment.twitterUsername;
    private readonly isFacebookIntegrationEnabled = environment.facebookIntegrationEnabled;
    private readonly facebookAppId = environment.facebookAppId;

    constructor(
        private infoService: InfoService,
        @Optional() private socialSharing: SocialSharing,
        private platform: Platform,
    ) {
    }

    shareOnFacebook(url: string) {
        if (!this.isFacebookIntegrationEnabled) {
            throw Error(`Facebook integration is disabled. Cannot share ${url}`);
        }

        ShareService.openPopup(
            `https://www.facebook.com/dialog/share?app_id=${this.facebookAppId}&display=popup&href=${url}`,
            555,
            745,
        );
    }

    shareOnTwitter(url: string, message: string) {
        if (!this.isTwitterIntegrationEnabled) {
            throw Error(`Twitter integration is disabled. Cannot share ${url}`);
        }

        const via = this.twitterUsername;

        ShareService.openPopup(
            `https://twitter.com/intent/tweet?text=${message}&url=${url}&via=${via}`,
            500,
            300,
        );
    }

    copyUrlToClipboard(url: string) {
        this.copyToClipboard(url)
            .then(
                () => this.infoService.success('Die URL wurde in deine Zwischenablage kopiert'),
                (reason) => this.infoService.error('Die URL konnte nicht kopiert werden', reason),
            );
    }

    shareViaNativeDialog(url: string, message: string) {
        this.socialSharing
            .share(
                message,
                null,
                null,
                url,
            )
            .catch((e) => this.infoService.error('Teilen nicht möglich', e));
    }

    private static openPopup(url: string, width: number, height: number) {
        const left = (window.innerWidth / 2) - (width / 2);
        const top = (window.innerHeight / 2) - (height / 2);
        const options = [
            'menubar=no',
            'toolbar=no',
            'resizable=no',
            'scrollbars=no',
            'width=' + width,
            'height=' + height,
            'top=' + top,
            'left=' + left,
        ];

        window.open(url, null, options.join(','));
    }

    private copyToClipboard(text: string): Promise<unknown> {
        return this.platform.is('capacitor')
            ? Clipboard.write({ string: text })
            : navigator.clipboard.writeText(text);
    }
}
