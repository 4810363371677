export function filterObject(obj, callback) {
    if (null === obj || obj === undefined) {
        throw Error('Cannot convert null or undefined to an object');
    }

    if (typeof callback !== 'function') {
        throw Error('Expected callback to be a function');
    }

    obj = Object(obj);

    return Object.keys(obj).reduce(
        (filtered, key) => {
            if (
                Object.prototype.hasOwnProperty.call(obj, key) &&
                callback(obj[key], key, obj)
            ) {
                filtered[key] = obj[key];
            }

            return filtered;
        },
        {},
    );
}
