import { Component } from '@angular/core';
import { environment } from '@app/environments/environment';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {

    readonly privacyTermsUrl: string = environment.privacyTermsUrl;
    readonly version = environment.releaseVersion;

    get currentYear(): number {
        return new Date().getFullYear();
    }

    constructor() {
    }
}
