import { ErrorHandler } from '@angular/core';

const CHUNK_FAILED_MESSAGE_REGEX = /Loading chunk [\d]+ failed/;

function hasMessage(error: unknown): error is { message: string } {
    return error
        && undefined !== error['message']
        && 'string' === typeof error['message'];
}

export class FailedChunkErrorHandler implements ErrorHandler {
    constructor(private readonly errorHandler: ErrorHandler) {
    }

    handleError(error: unknown): void {
        if (hasMessage(error) && CHUNK_FAILED_MESSAGE_REGEX.test(error.message)) {
            window.location.reload();
        } else {
            this.errorHandler.handleError(error);
        }
    }
}
