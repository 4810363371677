import { Injectable } from '@angular/core';
import { InfoService } from '@app/core/services/info/info.service';
import { Logger } from '@app/core/services/logger/logger';
import { Geolocation } from '@app/core/services/user-location/geolocation/geolocation';
import { EMPTY, Observable, ReplaySubject, Subject, Subscriber } from 'rxjs';
import { filter, multicast, refCount } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class NavigatorGeolocation implements Geolocation {

    // Tried to use this with shareReplay(), but couldn't get the teardown
    // logic to be executed properly
    // Should be fine
    readonly userLocation = !navigator.geolocation
        ? EMPTY
        : Observable
            .create((subscriber: Subscriber<GeolocationPosition>) => {
                const subject = new Subject<GeolocationPosition>();
                const subscription = subject.subscribe(subscriber);

                this.logger.log('WATCH POSITION');

                const watchId = navigator.geolocation.watchPosition(
                    (position) => subject.next(position),
                    (error) => this.deniedGeoLocation(error),
                );

                return () => {
                    this.logger.log('UNSUBSCRIBE POSITION WATCH');

                    if (watchId) {
                        this.logger.log('CLEAR WATCH', watchId);

                        navigator.geolocation.clearWatch(watchId);
                    }

                    subscription.unsubscribe();
                };
            })
            .pipe(
                filter((position) => !!position),
                // Neither with publicReplay() nor with shareReplay() the
                // cleanup logic gets executed... huh?
                // This works.
                // see https://github.com/ReactiveX/rxjs/issues/3336
                multicast(() => new ReplaySubject(1)),
                refCount(),
            );

    constructor(private infoService: InfoService, private logger: Logger) {
    }

    watchPosition(): Observable<GeolocationPosition> {
        if (!navigator.geolocation) {
            this.infoService.error('Dein Browser unterstützt leider keine Positionsabfrage');
        }

        return this.userLocation;
    }

    deniedGeoLocation(e): void {
        this.logger.log('USER DENIED GEO LOCATION', e);

        this.infoService.error('Deine Position konnte nicht ermittelt werden');
    }

}
