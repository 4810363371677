import { Component, EnvironmentInjector, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core/services/authentication/authentication.service';
import {
    HighlightedFeaturesService,
} from '@app/core/services/hightlighted-features/highlighted-features.service';
import { ProfileService } from '@app/core/services/profile/profile.service';
import { TitleService } from '@app/core/services/title/title.service';
import { randomSlogan } from '@app/shared/utils/view/randomSlogan';
import { combineLatest, from, Observable, of, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-global-menu-layout',
    templateUrl: './global-menu-layout.component.html',
    styleUrls: ['./global-menu-layout.component.scss'],
})
export class GlobalMenuLayoutComponent implements OnInit, OnDestroy {

    slogan = randomSlogan();

    title = '';

    loading = true;

    private ngUnsubscribe = new Subject<void>();

    constructor(public environmentInjector: EnvironmentInjector,
                private titleService: TitleService,
                private authenticationService: AuthenticationService,
                private highlightedFeaturesService: HighlightedFeaturesService,
                private router: Router,
                private profileService: ProfileService) {
    }

    ngOnInit() {
        const profileFetcher = this.authenticationService.getIdToken()
            .pipe(
                switchMap((idToken) => idToken
                    // Load the profile and only continue once we have it
                    // Important: Use 'cache-first' instead of 'network-only'
                    // This component is recreated from time to time, hence the
                    // loading spinner would be shown during the network request
                    // This could also be fixed using a custom route reuse strategy,
                    // but that's currently not supported by Ionic
                    // https://github.com/ionic-team/ionic/issues/17673
                    ? this.profileService.getProfile('cache-first')
                        .pipe(map(() => true))
                    // Not logged in
                    : of(false)),
            );

        const highlightedFeaturesStream = this.highlightedFeaturesService.shouldShowHighlightedFeatures()
            .pipe(
                take(1),
                switchMap((shouldShow) => shouldShow
                    ? this.showHighlightedFeatures()
                    : of(shouldShow)),
            );

        combineLatest([
            profileFetcher,
            highlightedFeaturesStream,
        ])
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => this.loading = false);

        this.titleService.getGlobalMenuTitle()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((title) => this.title = title);
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    private showHighlightedFeatures(): Observable<unknown> {
        return from(this.navigateToHighlightedFeatures());
    }

    private navigateToHighlightedFeatures(): Promise<boolean> {
        return this.router.navigateByUrl(
            '/highlighted-features',
            {
                replaceUrl: false,
                skipLocationChange: true,
            },
        );
    }
}
