import { Directive, forwardRef, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
    selector: '[passwordStrength]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => PasswordStrengthDirective),
            multi: true,
        },
    ],
})
export class PasswordStrengthDirective implements Validator {

    @Input() passwordStrength;

    validate(control: AbstractControl): ValidationErrors | null {
        if (!control.value) {
            return null;
        }

        if (control.value.length < 6) {
            return { tooShort: true };
        }

        const suggestions: string[] = [];
        let strength = 0;

        if (control.value.match(/[a-z]/)) {
            ++strength;
        } else {
            suggestions.push('Kleinbuchstaben');
        }

        if (control.value.match(/[A-Z]/)) {
            ++strength;
        } else {
            suggestions.push('Großbuchstaben');
        }

        if (control.value.match(/\d/)) {
            ++strength;
        } else {
            suggestions.push('Zahlen');
        }

        if (control.value.match(/[^a-zA-Z\d\s]/)) {
            ++strength;
        } else {
            suggestions.push('Sonderzeichen');
        }

        if (strength >= this.passwordStrength) {
            return null;
        }

        return { tooSimple: true, suggestions };
    }

}
