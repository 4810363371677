import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, InjectionToken, NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthInterceptor } from '@app/core/http/interceptors/auth.interceptor';
import { SerializingHttpClient } from '@app/core/http/serializing-http-client';
import { HIERARCHY, HIERARCHY_TOKEN } from '@app/core/services/authorization/hierarchy';
import {
    FailedChunkErrorHandler,
} from '@app/core/services/error-handler/failed-chunk-error-handler.service';
import { ConsoleLogger } from '@app/core/services/logger/console-logger';
import { Logger } from '@app/core/services/logger/logger';
import { NoopLogger } from '@app/core/services/logger/noop-logger';
import { environment } from '@app/environments/environment';
import { IonicModule } from '@ionic/angular';
import * as Sentry from "@sentry/angular-ivy";

export const httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];

export const AppApiHttpClient = new InjectionToken('AppApiHttpClient');
export const PublicApiHttpClient = new InjectionToken('PublicApiHttpClient');

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        HttpClientModule,
        IonicModule,
    ],
    providers: [
        httpInterceptorProviders,
        {
            provide: ErrorHandler,
            useValue: new FailedChunkErrorHandler(
                Sentry.createErrorHandler({
                    showDialog: environment.sentryShowErrorDialog,
                }),
            ),
        },
        {
            provide: AppApiHttpClient,
            useFactory: (http: HttpClient) => new SerializingHttpClient(http, environment.appApiUrl),
            deps: [[HttpClient]],
        },
        {
            provide: PublicApiHttpClient,
            useFactory: (http: HttpClient) => new SerializingHttpClient(http, environment.publicApiUrl),
            deps: [[HttpClient]],
        },
        {
            provide: HIERARCHY_TOKEN,
            useValue: HIERARCHY,
        },
        {
            provide: Logger,
            useClass: environment.production ? NoopLogger : ConsoleLogger,
        },
    ],
})
export class CoreModule {

    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import it in the AppModule only');
        }
    }

}
