import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    private storage: Promise<Storage>;

    constructor(storage: Storage) {
        this.storage = storage.create();
    }

    public set(key: string, value: any): Promise<any> {
        return this.storage.then((storage) => storage.set(key, value));
    }

    public get(key: string): Promise<any> {
        return this.storage.then((storage) => storage.get(key));
    }

    public remove(key: string): Promise<any> {
        return this.storage.then((storage) => storage.remove(key));
    }
}
