<ion-list>
    <div class="top-container">
        <div class="logo-container">
            <img src="/assets/logo/logo.svg" alt="Logo"/>
        </div>
        <ng-container *ngIf="'sliding' === mode; else staticMenu">
            <ion-menu-toggle>
                <ng-container *ngFor="let page of menu"
                              [ngTemplateOutlet]="menuItem"
                              [ngTemplateOutletContext]="{ $implicit: page }"></ng-container>

                <ion-item [routerLink]="infoLink.url" routerDirection="forward" *platform="'mobile'">
                    <ion-icon [name]="infoLink.icon" slot="start"></ion-icon>
                    <ion-label>{{infoLink.title}}</ion-label>
                </ion-item>
            </ion-menu-toggle>
        </ng-container>

        <ng-template #staticMenu>
            <ng-container *ngFor="let page of menu"
                          [ngTemplateOutlet]="menuItem"
                          [ngTemplateOutletContext]="{ $implicit: page }"></ng-container>
        </ng-template>
    </div>

    <app-footer *platform="'mobile'"></app-footer>
</ion-list>

<ng-template #menuItem let-page>
    <ng-container *ngIf="isInternalLink(page.url); else externalLink">
        <ion-item [routerLink]="page.url"
                  routerDirection="root">
            <ng-container [ngTemplateOutlet]="linkContent"></ng-container>
        </ion-item>
    </ng-container>

    <ng-template #externalLink>
        <ion-item *ngIf="!shouldHideEntry(page.hideOnMobile)"
                  href="{{page.url}}"
                  rel="noopener" [target]="page.target ?? '_blank'">
            <ng-container [ngTemplateOutlet]="linkContent"></ng-container>
        </ion-item>
    </ng-template>

    <ng-template #linkContent>
        <ion-icon [ngStyle]="{'color': page.iconColor ?? null}"
                  [name]="page.icon"
                  slot="start">
        </ion-icon>
        <ion-label>{{page.title}}</ion-label>
    </ng-template>
</ng-template>
