import { Component, ContentChild } from '@angular/core';
import { FaqAnswerLongComponent } from '@app/shared/components/faq-card-component/faq-answer-long/faq-answer-long.component';

@Component({
    selector: 'app-faq-card',
    templateUrl: './faq-card.component.html',
    styleUrls: ['./faq-card.component.scss'],
})
export class FaqCardComponent {

    @ContentChild(FaqAnswerLongComponent) longAnswer: FaqAnswerLongComponent;

    collapsed = true;

    onToggleCollapsed(event: MouseEvent): void {
        if (event.target instanceof HTMLAnchorElement) {
            return;
        }

        this.collapsed = !this.collapsed;
        this.longAnswer.setCollapsed(this.collapsed);
    }

}
