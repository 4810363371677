import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { from, Observable, ReplaySubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TitleService {

    private globalMenuTitleSource = new ReplaySubject<string>(1);

    private titleApp?: string;

    constructor(
        private htmlTitle: Title,
        private translateService: TranslateService,
    ) {

        this.translateService.get('app_name').subscribe(
            (result: string) => this.titleApp = result,
        );
    }

    setTitles(title: string): void {
        this.setGlobalMenuTitle(title);
        this.setHtmlTitle(title);
    }

    setGlobalMenuTitle(globalMenuTitle: string): void {
        this.globalMenuTitleSource.next(globalMenuTitle);
    }

    setHtmlTitle(htmlTitle: string): void {
        this.htmlTitle.setTitle(htmlTitle ? `${htmlTitle} - ${this.titleApp}` : this.titleApp);
    }

    getGlobalMenuTitle(): Observable<string> {
        return from(this.globalMenuTitleSource);
    }

}
